import const_global from "@/constants/global"
import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as  AUTHENTICATION_STATE } from "@/constants/vuex/authentication"
import { STATE, ACTIONS, MUTATIONS, GETTERS } from "@/constants/vuex/processes"
import { storageMessage } from "@/util/utils"

const default_process_info = {
  [const_global.PROCESS_CODE]: "",
  [const_global.PROCESS_NAME]: "",
  [const_global.PROCESS_DESCRIPTION]: "",
  [const_global.PROCESS_IMAGE]: "",
  [const_global.PROCESS_RECIPIENTS]: [],
  [const_global.PROCESS_FIELDS]: null,
  [const_global.PROCESS_METADATA]: null,
  [const_global.PROCESS_RECIPIENTS]: []
}

const state = {
  [STATE.S_AVAILABLE_PROCESSES]: null,
  [STATE.S_CODE]: "",
  [STATE.S_NAME]: "",
  [STATE.S_DESCRIPTION]: "",
  [STATE.S_IMAGE]: "",
  [STATE.S_FIELDS]: null,
  [STATE.S_METADATA]: null,  
  [STATE.S_TRANSACTION_STATUS]: const_global.TRANSACTION_PENDING,
  [STATE.S_TRANSACTION_RESULT]: null,
  [STATE.S_DATA_PREVIEW]: false,
  [STATE.S_FILE_LOADING]: false,
  [STATE.S_COST_CENTER]: null,
  [STATE.S_PROCESS_TYPE]: '',
  [STATE.S_CHECKING_FIELD]: false,
  [STATE.S_PROCESS_NOTIFICATIONS]: false,
  
  [STATE.S_PENDING_TRANSACTION]: false,
  [STATE.S_CURRENT_TRANSACTION_PROCESS_CODE]: null,
  [STATE.S_CURRENT_TRANSACTION_RECIPIENTS]: null,
  [STATE.S_CURRENT_TRANSACTION_DATA]: null,
  [STATE.S_CURRENT_RECIPIENTS_VALID]: false,
  [STATE.S_CURRENT_DATA_VALID]: false,
  [STATE.S_POSTAL_PROCESS_USER_CUSTOM_VALUES]: false,

  [STATE.S_BATCH_MODE]: false,

}
const mutations = {
  [MUTATIONS.M_SET_AVAILABLE_PROCESSES](state, processes) {
    state[STATE.S_AVAILABLE_PROCESSES] = processes
  },
  [MUTATIONS.M_SET_CODE](state, code) {
    state[STATE.S_CODE] = code
  },
  [MUTATIONS.M_SET_NAME](state, name) {
    state[STATE.S_NAME] = name
  },
  [MUTATIONS.M_SET_DESCRIPTION](state, description) {
    state[STATE.S_DESCRIPTION] = description
  },
  [MUTATIONS.M_SET_IMAGE](state, image) {
    state[STATE.S_IMAGE] = image
  },
  [MUTATIONS.M_SET_STEPS](state, steps) {
    state[STATE.S_STEPS] = steps
  },
  [MUTATIONS.M_SET_FIELDS](state, fields) {
    state[STATE.S_FIELDS] = fields
  },
  [MUTATIONS.M_SET_METADATA](state, metadata) {
    state[STATE.S_METADATA] = metadata
  },
  [MUTATIONS.M_SET_RECIPIENTS](state, recipients) {
    if (recipients.length > 0) {
      state[STATE.S_PENDING_TRANSACTION] = true
    }
    state[STATE.S_RECIPIENTS] = recipients
  },
  [MUTATIONS.M_SET_PENDING_TRANSACTION](state, payload) {
    state[STATE.S_PENDING_TRANSACTION] = payload
  },
  [MUTATIONS.M_SET_TRANSACTION_STATUS](state, payload) {
    state[STATE.S_TRANSACTION_STATUS] = payload
  },
  [MUTATIONS.M_SET_TRANSACTION_RESULT](state, payload) {
    state[STATE.S_TRANSACTION_RESULT] = payload
  },
  [MUTATIONS.M_SET_DATA_PREVIEW](state, payload) {
    state[STATE.S_DATA_PREVIEW] = payload
  },
  [MUTATIONS.M_SET_FILE_LOADING](state, payload) {
    state[STATE.S_FILE_LOADING] = payload
  },
  [MUTATIONS.M_SET_COST_CENTER](state, payload) {
    state[STATE.S_COST_CENTER] = payload
  },
  [MUTATIONS.M_SET_PROCESS_TYPE](state, payload) {
    state[STATE.S_PROCESS_TYPE] = payload
  },

  [MUTATIONS.M_SET_CHECKING_FIELD](state, payload) {
    state[STATE.S_CHECKING_FIELD] = payload
  },

  [MUTATIONS.M_SET_PROCESS_NOTIFICATIONS](state, payload) {
    state[STATE.S_PROCESS_NOTIFICATIONS] = payload
  },

  [MUTATIONS.M_SET_PROCESS_NOTIFICATIONS](state, payload) {
    state[STATE.S_PROCESS_NOTIFICATIONS] = payload
  },



  [MUTATIONS.M_SET_CURRENT_TRANSACTION_RECIPIENTS](state, payload) {
    state[STATE.S_CURRENT_TRANSACTION_RECIPIENTS] = payload
  },

  [MUTATIONS.M_SET_CURRENT_TRANSACTION_DATA](state, payload) {
    state[STATE.S_CURRENT_TRANSACTION_DATA] = payload
  },

  [MUTATIONS.M_CURRENT_TRANSACTION_PROCESS_CODE](state, payload) {
    state[STATE.S_CURRENT_TRANSACTION_PROCESS_CODE] = payload
  },

  [MUTATIONS.M_CURRENT_RECIPIENTS_VALID](state, payload) {
    state[STATE.S_CURRENT_RECIPIENTS_VALID] = payload
  },

  [MUTATIONS.M_CURRENT_DATA_VALID](state, payload) {
    state[STATE.S_CURRENT_DATA_VALID] = payload
  },

  [MUTATIONS.M_POSTAL_PROCESS_USER_CUSTOM_VALUES](state, payload) {
    state[STATE.S_POSTAL_PROCESS_USER_CUSTOM_VALUES] = payload
  },


  [MUTATIONS.M_BATCH_MODE](state, name) {
    state[STATE.S_BATCH_MODE] = name
  },
}

const actions = {
  [ACTIONS.A_SET_AVAILABLE_PROCESSES]({ commit }, available_processes) {
    commit(MUTATIONS.M_SET_AVAILABLE_PROCESSES, available_processes)
  },
  [ACTIONS.A_SET_PROCESS_TRANSACTION]({ commit }, process_info) {
    if (!process_info) {
      commit(MUTATIONS.M_SET_PENDING_TRANSACTION, false)
      process_info = default_process_info
    }

    commit(MUTATIONS.M_SET_CODE, process_info[const_global.PROCESS_CODE])
    commit(MUTATIONS.M_SET_NAME, process_info[const_global.PROCESS_NAME])
    commit(MUTATIONS.M_SET_DESCRIPTION, process_info[const_global.PROCESS_DESCRIPTION])
    commit(MUTATIONS.M_SET_IMAGE, process_info[const_global.PROCESS_IMAGE])
    commit(MUTATIONS.M_SET_RECIPIENTS, process_info[const_global.PROCESS_RECIPIENTS])
    commit(MUTATIONS.M_SET_FIELDS, process_info[const_global.PROCESS_FIELDS])
    commit(MUTATIONS.M_BATCH_MODE, process_info.batchMode)

    let metadata = process_info[const_global.PROCESS_METADATA]
    let parsedMetadata = (metadata && metadata.behaviorLayer != "")  ? JSON.parse(metadata.behaviorLayer) : null

    commit(MUTATIONS.M_SET_METADATA, metadata)

    if( metadata != null && parsedMetadata != null ) {
      commit(MUTATIONS.M_SET_STEPS,parsedMetadata.steps )
    }

  },
  // [ACTIONS.A_SET_PROCESS_RECIPIENTS]({ commit }, recipients) {
  //   commit(MUTATIONS.M_SET_RECIPIENTS, recipients)
  // },
  [ACTIONS.A_SET_PROCESS_METADATA_VALUE]({ commit }, metadata) {
    commit(MUTATIONS.M_SET_METADATA, metadata)
  },
  [ACTIONS.A_SET_TRANSACTION_STATUS]({ commit }, status) {
    commit(MUTATIONS.M_SET_TRANSACTION_STATUS, status)
  },
  [ACTIONS.A_SET_PROCESS_FIELD_VALUE]({ commit, state }, payload) {
    storageMessage("[VP] [" + ACTIONS.A_SET_PROCESS_FIELD_VALUE + "] key: " + payload[const_global.FIELD_KEY] + " - val: " + payload[const_global.FIELD_VALUE], false)
    let actual_fields = {}
    let field_to_edit = {}
    Object.assign(actual_fields, state[STATE.S_FIELDS])
    Object.assign(field_to_edit, actual_fields[payload[const_global.FIELD_KEY]])
    field_to_edit[const_global.FIELD_VALUE] = payload[const_global.FIELD_VALUE]
    field_to_edit[const_global.FIELD_VALID] = payload[const_global.FIELD_VALID]

    actual_fields[payload[const_global.FIELD_KEY]] = field_to_edit
    commit(MUTATIONS.M_SET_FIELDS, actual_fields)
  },
  [ACTIONS.A_RESET_STATE]({ commit , dispatch}) {
    dispatch(ACTIONS.A_SET_PROCESS_TRANSACTION, null)
    commit(MUTATIONS.M_SET_AVAILABLE_PROCESSES, null)

  },
  [ACTIONS.A_SET_DATA_PREVIEW]({ commit }, value) {
    commit(MUTATIONS.M_SET_DATA_PREVIEW, value)

  },
  [ACTIONS.A_SET_TRANSACTION_RESULT]({ commit }, value) {
    commit(MUTATIONS.M_SET_TRANSACTION_RESULT, value)

  },
  [ACTIONS.A_SET_FILE_LOADING]({ commit }, value) {
    commit(MUTATIONS.M_SET_FILE_LOADING, value)

  },
  [ACTIONS.A_SET_CHECKING_FIELD]({ commit }, value) {
    commit(MUTATIONS.M_SET_CHECKING_FIELD, value)
  },
  [ACTIONS.A_SET_PROCESS_NOTIFICATIONS]({ commit }, value) {
    commit(MUTATIONS.M_SET_PROCESS_NOTIFICATIONS, value)
  },
  [ACTIONS.A_SET_COST_CENTER]({ commit }, value) {
    commit(MUTATIONS.M_SET_COST_CENTER, this.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_AVAILABLE_COST_CENTERS].find(acc => acc.code == value))
  },
  [ACTIONS.A_SET_PROCESS_TYPE]({ commit }, value) {
    commit(MUTATIONS.M_SET_PROCESS_TYPE, value)
  },


  [ACTIONS.A_CURRENT_TRANSACTION_PROCESS_CODE]({ commit }, value) {
    commit(MUTATIONS.M_CURRENT_TRANSACTION_PROCESS_CODE, value)
  },

  [ACTIONS.A_SET_CURRENT_TRANSACTION_RECIPIENTS]({ commit }, value) {
    commit(MUTATIONS.M_SET_CURRENT_TRANSACTION_RECIPIENTS, value)
  },

  [ACTIONS.A_SET_CURRENT_TRANSACTION_DATA]({ commit }, value) {
    commit(MUTATIONS.M_SET_CURRENT_TRANSACTION_DATA, value)
  },

  [ACTIONS.A_SET_PENDING_TRANSACTION]({ commit }, value) {
    commit(MUTATIONS.M_SET_PENDING_TRANSACTION, value)
  },

  [ACTIONS.A_SET_PROCESS_STEPS]({ commit }, value) {
    commit(MUTATIONS.M_SET_STEPS, value)
  },

  [ACTIONS.A_SET_CURRENT_RECIPIENTS_VALID]({ commit }, value) {
    commit(MUTATIONS.M_CURRENT_RECIPIENTS_VALID, value)
  },

  [ACTIONS.A_SET_CURRENT_DATA_VALID]({ commit }, value) {
    commit(MUTATIONS.M_CURRENT_DATA_VALID, value)
  },

  [ACTIONS.A_SET_POSTAL_PROCESS_USER_CUSTOM_VALUES]({ commit }, value) {
    commit(MUTATIONS.M_POSTAL_PROCESS_USER_CUSTOM_VALUES, value)
  },


  [ACTIONS.A_SET_BATCH_MODE]({ commit }, value) {
    commit(MUTATIONS.M_BATCH_MODE, value)
  },
}

const getters = {
  [GETTERS.G_AVAILABLE_PROCESSES](state) {
    return state[STATE.S_AVAILABLE_PROCESSES]
  },
  [GETTERS.G_AVAILABLE_PROCESSES_BY_CATEGORIES](state) {

    let categories = null
    let processes = state[STATE.S_AVAILABLE_PROCESSES]
    if (processes != null) {

      categories = []
      processes.forEach(process => {

        if (categories.find(cat => cat.name == process.category) == null) {
          categories.push({
            [const_global.NAME]: process.category,
            [const_global.PROCESSES]: []
          })
        }
        categories.find(cat => cat.name == process.category)[const_global.PROCESSES].push(process)

      })
    }
    return categories
  },
  [GETTERS.G_PROCESS_TRANSACTION](state) {
    return {
      [const_global.PROCESS_CODE]: state[STATE.S_CODE],
      [const_global.PROCESS_NAME]: state[STATE.S_NAME],
      [const_global.PROCESS_DESCRIPTION]: state[STATE.S_DESCRIPTION],
      [const_global.PROCESS_RECIPIENTS]: state[STATE.S_RECIPIENTS],
      [const_global.PROCESS_FIELDS]: state[STATE.S_FIELDS],
      [const_global.PROCESS_IMAGE]: state[STATE.S_IMAGE],
      [const_global.PROCESS_STEPS]: state[STATE.S_STEPS],
      [const_global.PROCESS_METADATA]: state[STATE.S_METADATA],
      batchMode: state[STATE.S_BATCH_MODE],
    }
  },
  [GETTERS.G_PROCESS_NOTIFICATIONS](state) {
    return state[STATE.S_PROCESS_NOTIFICATIONS]
  },


  [GETTERS.G_CURRENT_TRANSACTION_RECIPIENTS](state) {
    return state[STATE.S_CURRENT_TRANSACTION_RECIPIENTS]
  },

  [GETTERS.G_CURRENT_TRANSACTION_DATA](state) {
    return state[STATE.S_CURRENT_TRANSACTION_DATA]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
