<template>
  <v-app
    v-if="theme"
    class="test"
  >
    <v-row
      v-if="isLoading"
      class="loading ma-0 pa-0"
    >
      <loading-container/>
    </v-row>
    <v-row
      v-else
      class="ma-0 pa-0"
    >
      <title>{{theme | capitalize}} - CertySign</title>
      <v-row
        class="ma-0 pa-0"
        dense
      >
        <v-col
          v-if="!$route.meta.requiresAuth"
          :class="'cts-login-background-color' && $route.path === '/rememberPassword' ? 'cts-grid-recovery' : ''"
          class="cts-grid-wrapper ma-0 pa-0"
          cols="12"
          lg="12"
          md="12"
          sm="12"
          xl="12"
        >
          <v-row
            class="ma-0 pa-0 cts-login-topbar cts-grid-header text-center justify-center elevation-1"
            dense
          >
            <v-col
              cols="12"
              lg="3"
              md="3"
              sm="3"
              xl="3"
            >
              <a @click="goRoot()">
                <img
                  :alt="'Logo ' + theme"
                  :src="`https://r.mailcomms.io/b2b/logos/${theme}_login.png`"
                  class="cts-logo-login mx-auto d-block my-1"
                />
              </a>
            </v-col>
            <lang-selector
              v-if="action != NEW_PASSWORD.NAME"
              :compact_mode="true"
              class="cts-lang--selector-login"
            />
          </v-row>

          <v-row
            class="ma-0 pa-0 justify-center cts-grid-content"
            dense
          >
            <v-col
              class="cts-row-login-box"
              cols="12"
              lg="4"
              md="6"
              sm="6"
              xl="4"
            >
              <Login :theme="theme"/>
            </v-col>
          </v-row>
          <v-row
            class="ma-0 pa-0 cts-w-100 cts-footer cts-bgcolor-user-menu-background cts-grid-footer"
            dense
          >
            <v-col
              class="ma-0 pa-0"
              cols="12"
            >
              <TheFooter :theme="theme"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-else
          class="cts-h-100 ma-0 pa-0"
          cols="12"
          lg="12"
          md="12"
          sm="12"
          xl="12"
        >
          <TheHeader @language:change="handleLanguageChange"/>
          <v-main class="cts-main-container col-12">
            <v-row
              class="ma-0 pa-0 cts-w-100 pt-lg-4"
              dense
            >
              <v-col
                class="ma-0 pa-0"
                cols="12"
              >
                <router-view
                  :key="$route.fullPath"
                  style="height: fit-content"
                />
              </v-col>
            </v-row>
            <v-row
              class="ma-0 pa-0 cts-w-100 cts-footer cts-bgcolor-user-menu-background"
              dense
            >
              <v-col
                class="ma-0 pa-0"
                cols="12"
              >
                <TheFooter :theme="theme"/>
              </v-col>
            </v-row>
          </v-main>
        </v-col>
        <v-btn
          id="backToTopID"
          class="hidden-md-and-up cts-color-primary cts-btn-to-top elevation-0"
          fab
          style="visibility: hidden"
          @click.native="scrollToTop"
        >
          <v-icon
            aria-hidden="false"
            class="elevation-4"
          >
            arrow_circle_up
          </v-icon>
        </v-btn>
        <alert-message-popup/>
      </v-row>
    </v-row>

    <AlertExpirationDialog
      v-if="isSessionAboutToExpire"
      @closedExpirationModal="closedExpirationModal"
    />
  </v-app>
</template>

<script>
import Login from './views/UserManagement'
import TheHeader from './components/TheHeader.vue'
import { mapGetters } from 'vuex'
import { redirect } from '@/util/router_utils'
import AlertMessagePopup from './components/structures/alertMessagePopup'
import TheFooter from '@/components/TheFooter.vue'
import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE, ACTIONS as AUTHENTICATION_ACTIONS } from '@/constants/vuex/authentication'
import { NAMESPACE as GLOBAL_NAMESPACE, ACTIONS as GLOBAL_ACTIONS } from '@/constants/vuex/global'
import loadingContainer from './components/structures/loadingContainer'
import LangSelector from './components/buttons/LangSelector'
import pkg from '../package.json'
import { ROOT, NEW_PASSWORD, LOGIN } from '@/constants/router/routes'
import { getSubdomainConfig } from '@/services/staticResourcesServices'
import { capitalize, createInlineWorker } from '@/util/utils'
import locations from '@/constants/locations'
import AlertExpirationDialog from '@/components/structures/AlertExpirationDialog.vue'
import { showToast } from "@/util/utils"
import { userLogout } from "@/services/userServices"
import { ServerBus } from "@/main"

export default {
  name: 'App',
  components: {
    loadingContainer,
    TheFooter,
    AlertMessagePopup,
    Login,
    TheHeader,
    LangSelector,
    AlertExpirationDialog
  },
  data: () => ({
    theme: null,
    isLoading: false,
    NEW_PASSWORD,
    isSessionAboutToExpire: false,
    sessionExpirationTimeout: null,
    remindIsActive: true,
    worker: null,
    continueSession: false
  }),
  computed: {
    ...mapGetters({
      appearance: 'appearance/getAppearance',
    }),

    action() {
      return this.$route.name
    },

    isLogged() {
      return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USER_LOGGED]
    },

    sessionDuration() {
      return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_TOKEN_EXPIRATION]
    },

    expirationTimestamp() {
      return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_TOKEN_EXPIRATION_TIMESTAMP]
    }
  },
  created() {
    if(this.isLogged) {
      ServerBus.$on("resetSessionTimestamp", () => {
        if (this.worker) {
          this.worker.terminate()
          this.worker.onmessage = null

          const expirationTimestamp = Date.now() + (this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_TOKEN_EXPIRATION] * 1000)
          this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_TOKEN_EXPIRATION_TIMESTAMP}`, expirationTimestamp)
          this.initTimer()
        }
      })

      ServerBus.$on("endExpirationTimestamp", () => {
        if (this.worker) {
          this.worker.terminate()
          this.worker.onmessage = null
        }
      })

      if (!this.expirationTimestamp && !this.worker) {
        const expirationTimestamp = Date.now() + (this.sessionDuration * 1000)
        this.setExpirationTimestamp(expirationTimestamp)
      }

      this.initTimer()
    }

    let host = window.location.host.split('.')[0].toLowerCase()
    let htmlElement = document.documentElement
    this.$store.dispatch('appearance/changeTheme', host)
    localStorage.setItem('theme', host)
    htmlElement.setAttribute('theme', host)
    this.theme = host

    let newLink = document.createElement('link')
    newLink.rel = 'stylesheet'
    newLink.type = 'text/css'
    newLink.href = `https://r.mailcomms.io/b2b/${host}.css`

    document.getElementsByTagName('head')[0].appendChild(newLink)

    this.fetchSubdomainConfig()
  },
  mounted() {
    window.onscroll = function () {
      if (window.pageYOffset >= 150) {
        document.getElementById('backToTopID').style.visibility = 'visible'
      } else {
        document.getElementById('backToTopID').style.visibility = 'hidden'
      }
    }

    let version = pkg.version

    if (!localStorage.getItem('app_version')) {
      localStorage.setItem('app_version', version)
    }

    if (localStorage.getItem('app_version') !== version) {
      localStorage.removeItem('app_version')
      localStorage.removeItem('vuex')
      localStorage.removeItem('_secure__ls__metadata')
      localStorage.removeItem('theme')
      window.location.reload(true)
    }
  },
  methods: {
    setExpirationTimestamp(value) {
      this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_TOKEN_EXPIRATION_TIMESTAMP}`, value)
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },

    goRoot() {
      redirect(ROOT)
    },

    handleLanguageChange(userDetails) {
      if (!userDetails) {
        this.isLoading = true

        setTimeout(() => {
          this.isLoading = false
        }, 800)
      }
    },

    async fetchSubdomainConfig() {
      const subdomain = window.location.host.split('.')[0].toLowerCase()
      const defaultConfig = {
        show_email_support: true,
        show_telephone_support: true,
        show_hours_email_support: true,
        show_hours_telephone_support: true,
        show_captcha_support: true,
        pdf_manual: true,
        pdf_guide: true,
        available_languages: locations.map(x => x.code)
      }

      const subdomainConfigResponse = await getSubdomainConfig(subdomain)
      if (subdomainConfigResponse && subdomainConfigResponse.data) {
        this.$store.dispatch(`${GLOBAL_NAMESPACE}/${GLOBAL_ACTIONS.SET_SUBDOMAIN_CONFIG}`, subdomainConfigResponse.data)

      } else {
        this.$store.dispatch(`${GLOBAL_NAMESPACE}/${GLOBAL_ACTIONS.SET_SUBDOMAIN_CONFIG}`, defaultConfig)
      }

    },
    initTimer() {
      const now = Date.now()

      if (this.expirationTimestamp < now) {
        this.closeSession()
      }

      const workerFn = (milisecondsTillExpiration) => {
        const milisecsToShowDialog = milisecondsTillExpiration - 60000

        setTimeout(() => {
          postMessage("showDialog")
        }, milisecsToShowDialog - 10000)

        setTimeout(() => {
          postMessage("closeSession")
        }, milisecondsTillExpiration - 10000)
      }

      const milisecondsTillExpiration = this.expirationTimestamp - now
      this.worker = createInlineWorker(workerFn, [milisecondsTillExpiration])
      this.worker.onmessage = (event) => {
        if (event.data === "showDialog") {
          this.continueSession = false
          this.isSessionAboutToExpire = true
        }

        if (event.data === "closeSession" && !this.continueSession) {
          this.setExpirationTimestamp(null)
          this.closeSession()
          this.closedExpirationModal()
        }
      }
    },

    async closeSession() {
      if (this.worker) {
        await userLogout()
        this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LOGOUT_DATA}`)
  
        showToast(true, 401)
        redirect(LOGIN)
      }
    },

    clearTimer() {
      this.worker = null
    },

    closedExpirationModal(message) {
      this.isSessionAboutToExpire = false
      this.clearTimer()

      if (message === "continue") {
        this.continueSession = true
        const expirationTimestamp = Date.now() + (this.sessionDuration * 1000)
        this.setExpirationTimestamp(expirationTimestamp)
        this.initTimer()
      }
    }
  },

  watch: {
    isLogged(newValue) {
      if (!newValue) {
        this.clearTimer()
        this.continueSession = false
      }
    }
  },

  filters: {
    capitalize
  },
}
</script>