import { render, staticRenderFns } from "./processExpandable.vue?vue&type=template&id=04b0f18e"
import script from "./processExpandable.vue?vue&type=script&lang=js"
export * from "./processExpandable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAvatar,VCol,VExpansionPanel,VExpansionPanelHeader,VIcon,VRow,VSheet,VSwitch})
