<template>

  <tr id="transactions-table-transaction_row_desktop" class=" hidden-md-and-down cts-transaction-row"
      @click="openTransaction(item.id)">
    <td class="text-start cts-transaction-datatable-process">
      <v-row dense class="ma-0 justify-space-between">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <h4 class="cts-color-tertiary text-uppercase" v-bind="attrs" v-on="on">
              <v-avatar id="transactions-table-transaction_row_icon" tile class="cts-process-card-icon ma-1" size="35">
                <icon-base
                  :icon-name="item.process_name" :src="item.process_image"
                  icon-color="var(--primary-alternative)"
                  width="35"
                  height="35"
                />
              </v-avatar>
            </h4>
          </template>
          <span>{{ item.process_name }}</span>
        </v-tooltip>

        <v-col class="cts-transaction-process-info my-auto cts-break-word">
          <v-row dense class="ma-0">
            <v-col id="transactions-table-transaction_col_title" class="cts-process-title cts-break-word ma-0 pa-0">
              <v-tooltip v-if="item.process_name.length > 40" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span id="transactions-table-transaction_row_title_compressed" class="cts-process-title cts-break-word" v-bind="attrs" v-on="on">{{ item.process_name }}</span>
                </template>
                <span id="transactions-table-transaction_row_title_tooltip">{{ item.process_name }}</span>
              </v-tooltip>
              <span id="transactions-table-transaction_row_title" v-else class="cts-process-title">{{ item.process_name }}</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0">
            <v-col id="transactions-table-transaction_col_subtitle" class="cts-process-subtitle cts-break-word ma-0 pa-0" v-if="item.id.indexOf('INVALID') === -1">
              <span id="transactions-table-transaction_row_subtitle" class="cts-color-tertiary">{{ clearTransactionId(item.id) }}</span>
            </v-col>

            <v-col id="transactions-table-transaction_col_subtitle_invalid" class="cts-process-subtitle cts-break-word" v-else>
              <span id="transactions-table-transaction_row_subtitle_invalid" class="cts-color-tertiary">{{ item.id }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </td>
    <td class="text-start cts-transaction-client-code" v-if="item.id != null">
      <v-tooltip v-if="item.contact_id.length > 15" bottom>
        <template v-slot:activator="{ on, attrs }">
          <h4 class="cts-color-tertiary cts-break-word" v-bind="attrs" v-on="on">{{ item.contact_id }}</h4>
        </template>
        <span>{{ item.contact_id }}</span>
      </v-tooltip>
      <h4 id="transactions-table-transaction_client_name" v-else class="cts-color-tertiary">{{ item.contact_id }}</h4>
    </td>
    <td class="text-start cts-transaction-datatable-client-name">
      <v-tooltip v-if="item.contact_complete_name.length > 15" bottom>
        <template v-slot:activator="{ on, attrs }">
          <h4 class="cts-color-tertiary cts-break-word" v-bind="attrs" v-on="on">{{ item.contact_complete_name }}</h4>
        </template>
        <span>{{ item.contact_complete_name }}</span>
      </v-tooltip>
      <h4 id="transactions-table-transaction_fullname" v-else class="cts-color-tertiary cts-break-word">
        {{ item.contact_complete_name }}</h4>
    </td>
    <td class="text-start cts-transaction-datatable-date">
      <h4 id="transactions-table-transaction_created_at" class="cts-color-tertiary">
        {{ dayjs.unix(item.created_at).format(const_global.DATE_TIME_FORMAT_A) }}
      </h4>
    </td>

    <td class="text-start cts-transaction-datatable-state">
      <v-tooltip v-if="item.state.length > 15" bottom>
        <template v-slot:activator="{ on, attrs }">
          <h4 class="cts-color-tertiary cts-break-word" v-bind="attrs" v-on="on">{{ item.state }}</h4>
        </template>
        <span>{{ item.state }}</span>
      </v-tooltip>
      <h4 v-else class="cts-color-tertiary cts-break-word" id="transactions-table-transaction_state">{{
          item.state
        }}</h4>
    </td>
    <td class="text-center cts-transaction-datatable-certificate">
      <download-certificate
          id="transactions-table-transaction_download_certificate"
          v-if="item.certification != '0' && checkPermission(const_permissions.TRANSACTION_DOWNLOAD_CERTIFICATE)"
          class="d-block mx-auto" :buttonDisabled="item.certification != '0'"
          :hash="item.certification"
          mode="icon"/>

      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on, attrs }">
          <span>
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="d-block mx-auto"
              style="cursor: default"
              color="var(--input-icons)"
            >
              {{ const_icons.DOWNLOAD_DOCUMENT }}
            </v-icon>
          </span>
        </template>
        <span>{{ $vuetify.lang.t('$vuetify.withoutCertificated') }}</span>
      </v-tooltip>
    </td>
  </tr>
</template>

<script>
import { checkPermission } from "@/util/store_utils"
import const_global from "@/constants/global"
import IconBase from "@/assets/images/IconBase"
import const_icons from "@/constants/icons"
import const_permissions from "@/constants/permissions"
import DownloadCertificate from "@/components/structures/downloadCertificate"


export default {
  name: "transactionListRowDesktop",
  components: { DownloadCertificate, IconBase },
  props: {
    item: Object
  },
  data() {
    return {
      checkPermission,
      const_global,
      const_icons,
      const_permissions
    }
  },

  methods: {
    openTransaction(id) {
      this.$emit('clicked', id)
    },

    clearTransactionId(id) {
      return id.split(".").pop()
    }
  }
}
</script>


