<template>
    <div>
        <v-col class="pa-0">
            <RecipientsSection
              v-if="step === 'fill_fields' && currentTransactionRecipients"
              :custom-validations="['postalRecipientLength']"
              :email-required="emailRequired"
              :phone-required="phoneRequired"
            />

            <v-row v-if="step === 'fill_fields'" class="pb-8" dense>
                <v-col cols="12">
                    <section-title
                      :text="$vuetify.lang.t('$vuetify.shipmentData')"
                      icon="reorder"
                      idName="data-fields"
                    />

                    <!-- DESCRIPTION -->
                    <v-row class="ma-0 py-2 px-4" dense>
                        <p v-if="additionalFields"
                           id="data_fields-description_additionals"
                           class="cts-font-size-4 mb-0 pl-1"
                        >
                            {{$vuetify.lang.t('$vuetify.sectionDataFieldsDescription')}}
                        </p>

                        <p v-else
                           id="data_fields-description_not_additionals"
                           class="cts-font-size-4 mb-0 pl-1"
                        >
                            {{$vuetify.lang.t('$vuetify.sectionDataFieldsNotAditionalFields')}}
                        </p>
                    </v-row>

                    <v-row class="ma-0 b2b-process-fields-container px-4" dense>
                        <v-col
                          v-for="(field, fieldName, index) in formFields"
                          :key="index"
                          :class="{'d-none': field.hidden}"
                          cols="12"
                          lg="3"
                          md="6"
                        >

                            <v-row class="ma-0" dense>
                                <span class="cts-font-size-4">{{field.pretty_print}}</span>
                                <span v-if="field.validations.allow_empty"
                                      class="cts-font-size-4 cts-color-subtitle-a cts-font-size-5 ml-1">
                 ({{$vuetify.lang.t('$vuetify.optional')}})
                </span>
                                <span v-else
                                      class="cts-font-size-4 cts-color-primary-alternative font-weight-medium  ml-1">*</span>
                            </v-row>

                            <v-row class="ma-0 px-0" dense>
                                <v-col class="px-0" style="position:relative">
                                    <v-row class="ma-0" dense>
                                        <v-col class="pa-0">
                                            <list-data-field
                                              v-if="field.type === 'list'"
                                              v-model="processData[fieldName]"
                                              :customid="`data_fields-standard-${field.pretty_print}`"
                                              :field-name="field.pretty_print"
                                              :items="field.validations.allowed_values"
                                            />

                                            <file-data-field
                                              v-else-if="field.type === 'file' || field.type === 'array_file'"
                                              v-model="processData[fieldName]"
                                              :customid="`data_fields-standard-${field.pretty_print.replace(/\s/g, '_').toLowerCase()}`"
                                              :field-name="field.pretty_print"
                                              :fileTypesAccepted="acceptedTypesForField(field)"
                                              :single-file="field.type === 'file'"
                                              :validations="fieldValidations(field)"
                                              class="px-1"
                                              @clear="handleFileClear(fieldName, field.type)"
                                              @input="onFileInput"
                                            />

                                            <text-data-field
                                              v-else
                                              v-model="processData[fieldName]"
                                              :field-name="field.pretty_print"
                                              :idName="fieldName"
                                              :max-length="field.validations.max_length"
                                              :suggested-fields="processContactFieldsSuggested"
                                              :validations="field.validations"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <SectionDataPreview
              v-else-if="step === 'preview'"
              :process-fields="processFields"
              :process-payload="mergedPayload()"
              :process-recipients="currentTransactionRecipients"
              :processData="mergedPayload()"
            />

            <SectionTransactionLaunched
              v-else
            />

        </v-col>
        <v-col align-self="end" cols="12">
            <ProcessStepControlBar
              v-if="step !== 'launch'"
              :backButtonObject="controlBar.back"
              :continueButtonObject="controlBar.continue"
              :infoObject="controlBar.info"
              class="mt-6"
              @back="handleBackButton"
              @next="handleContinueButton"
            />
        </v-col>
    </div>
</template>

<script>
import ProcessStepControlBar from "@/components/sections/processLaunch/models/commonComponents/ProcessStepControlBar"
import RecipientsSection from "@/components/sections/processLaunch/recipients/RecipientsSection"
import processDataMixin from "@/mixins/processData-mixin"
import SectionTitle from "@/components/sections/processes/processView/sectionTitle"
import const_global from "@/constants/global"
import const_icons from "@/constants/icons"
import TextDataField from "@/components/sections/processLaunch/models/commonComponents/dataFields/textDataField"
// import TextInputWithVariables from "@/components/sections/processLaunch/models/commonComponents/dataFields/textInputWithVariables"
import FileDataField from "@/components/sections/processLaunch/models/commonComponents/dataFields/fileDataField"
import ListDataField from "@/components/sections/processLaunch/models/commonComponents/dataFields/listDataField"
import SectionDataPreview from "@/components/sections/processes/processView/sectionDataPreview"
import SectionTransactionLaunched from '@/components/sections/processes/processView/sectionTransactionLaunched'
import {ServerBus} from "@/main"
import Mime from "@/plugins/mime"
import {convertBytesToMegabytes} from "@/util/utils"
import {NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE} from "@/constants/vuex/authentication";

export default {
    name: "standardModel",
    components: {
        RecipientsSection,
        SectionTitle,
        TextDataField,
        // TextInputWithVariables,
        FileDataField,
        ListDataField,
        ProcessStepControlBar,
        SectionDataPreview,
        SectionTransactionLaunched
    },
    mixins: [processDataMixin],
    props: {
        processFields: Object,
        behaviorLayer: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            const_global,
            processContactFields: [
                const_global.FIELD_NAME,
                const_global.FIELD_SURNAME,
                const_global.FIELD_CONTACT_ID,
                const_global.FIELD_MOBILE_PHONE,
                const_global.FIELD_EMAIL,
                const_global.FIELD_COUNTRY_CODE
            ],

            fileFields: [],

            formFields: {},
            controlBar: {
                info: {text: this.$vuetify.lang.t('$vuetify.launchDataKo'), icon: "error", color: "red"},
                back: {
                    text: this.$vuetify.lang.t('$vuetify.back'),
                    icon: const_icons.BACK,
                    disabled: false,
                    visible: false
                },
                continue: {text: this.$vuetify.lang.t('$vuetify.continue'), icon: const_icons.NEXT, disabled: true}
            },
            totalSize: 0
        }
    },

    computed: {

        additionalFields() {
            let additionalFields = false

            Object.entries(this.processFields).forEach((key) => {
                if (!this.processContactFields.includes(key[0])) {
                    additionalFields = true
                }
            })

            return additionalFields
        },

    },


    created() {
        if (this.behaviorLayer && JSON.parse(this.behaviorLayer) && JSON.parse(this.behaviorLayer).autofillVariables) {
            const autoFillVariables = JSON.parse(this.behaviorLayer).autofillVariables

            Object.keys(autoFillVariables).forEach(key => {
                this.processData[key] = this.getAutofillValue(autoFillVariables[key])
                this.processFields[key].hidden = true
            })
        }
        for (const key in this.processFields) {
            if (!this.processContactFields.includes(key)) {
                if (["file", "array_file"].includes(this.processFields[key].type)) {
                    this.fileFields.push(key)
                    this.processData[key] = this.processFields[key].type === "array_file" ? [] : null
                }
                this.formFields[key] = this.processFields[key]
            }
        }
        this.dataIsValid = this.validateAllFields()
    },

    methods: {
        fieldValidations(field) {
            const validations = {...{}, ...field.validations}
            if (!validations.allowed_extensions || validations.allowed_extensions.length === 0) {
                validations["allowed_extensions"] = ["pdf", "html"]
            }

            return validations
        },

        acceptedTypesForField(field) {
            const allowedExtensions = field.validations.allowed_extensions
            if (allowedExtensions) {
                return allowedExtensions.map(x => Mime.getType(x))
            }

            return [Mime.getType("pdf"), Mime.getType("html")]
        },
        handleBackButton() {
            for (const key in this.processFields) {
                switch (this.processFields[key].type) {
                    case "list":
                        this.processData[key] = this.processFields[key].validations.allowed_values ? this.processFields[key].validations.allowed_values[0] : null
                        break

                    case "array_file":
                        this.processData[key] = []
                        break

                    case "file":
                        this.processData[key] = null
                }
            }

            this.onFileInput()
            this.changeStep('fill_fields')
        },

        handleContinueButton() {
            this.step === "fill_fields" ? this.changeStep('preview') : this.changeStep('launch')
        },

        changeStep(stepName) {
            this.step = stepName

            if (stepName === 'launch') {
                ServerBus.$emit('launchTransaction')
            }
        },

        handleFileClear(fieldName, fieldType) {
            this.processData[fieldName] = fieldType === "array_file" ? [] : null
        },

        onFileInput() {
            this.validateSize()
        },

        validateSize() {
            this.totalSize = 0

            for (const key of this.fileFields) {
                if (Array.isArray(this.processData[key])) {
                    this.totalSize += this.processData[key].reduce((acc, cur) => acc + cur.size, 0)
                } else {
                    this.totalSize += this.processData[key] ? this.processData[key].size : 0
                }
            }

            this.dataIsValid = this.totalSize < const_global.BYTES_LIMIT_10MB && this.validateAllFields()
            if (this.allValid) {
                this.controlBar.info = {
                    icon: const_icons.CHECK_CIRCLE,
                    text: this.$vuetify.lang.t("$vuetify.launchDataOk"),
                    color: "green"
                }
                this.controlBar.continue.disabled = false
            } else {
                const text = this.totalSize >= const_global.BYTES_LIMIT_10MB ?
                  this.$vuetify.lang.t("$vuetify.rulesFilesTotalSize").replace("{totalSizeLimit}", convertBytesToMegabytes(const_global.BYTES_LIMIT_10MB)) :
                  this.$vuetify.lang.t("$vuetify.launchDataKo")

                this.controlBar.continue.disabled = true
                this.controlBar.info = {icon: const_icons.ERROR, text, color: "red"}
            }
        },
        getAutofillValue(variable) {
            switch (variable) {
                case "group_name":
                    return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_NAME]
                case "user_name":
                    return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_NAME]
                case "user_email":
                    return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USERNAME]
                default:
                    return variable
            }
        }
    },

    watch: {
        allValid() {
            if (this.allValid) {
                this.controlBar.info = {
                    icon: const_icons.CHECK_CIRCLE,
                    text: this.$vuetify.lang.t("$vuetify.launchDataOk"),
                    color: "green"
                }
                this.controlBar.continue.disabled = false
            } else {
                this.controlBar.continue.disabled = true
                this.controlBar.info = {
                    icon: const_icons.ERROR,
                    text: this.$vuetify.lang.t("$vuetify.launchDataKo"),
                    color: "red"
                }

            }
        },

        step() {
            this.controlBar.back.visible = this.step === "preview"
            this.controlBar.continue.text = this.step === "preview" ? this.$vuetify.lang.t('$vuetify.send') : this.$vuetify.lang.t('$vuetify.continue')
        },

        dataIsValid(_, oldValue) {
            if (this.dataIsValid || oldValue) {
                this.validateSize()
            }
        }
    }

}
</script>
