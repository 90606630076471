export const USER_ROUTES = { // Routes under /user
    ROUTE_LOGIN: process.env.VUE_APP_ENDPOINT + "/login", //POST
    ROUTE_LOGOUT: process.env.VUE_APP_ENDPOINT + "/logout", //POST
    ROUTE_REFRESH_SESSION: process.env.VUE_APP_ENDPOINT + "/refreshSession",
    ROUTE_REMEMBER_PASSWORD: `${process.env.VUE_APP_ENDPOINT}/user/$email$/rememberPassword`,
    ROUTE_CHANGE_PASSWORD: `${process.env.VUE_APP_ENDPOINT}/user/$email$/changePasswordInternal`,
    ROUTE_CHANGE_PASSWORD_WITHOUT_TOKEN: `${process.env.VUE_APP_ENDPOINT}/user/$email$/changePassword`,
    ROUTE_CHANGE_DEFAULT_COST_CENTER: `${process.env.VUE_APP_ENDPOINT}/user/$email$/changeDefaultCostCenter"`,
    ROUTE_CHECK_HASH: `${process.env.VUE_APP_ENDPOINT}/user/checkHash/$code$/$type$`,
    ROUTE_USERS: `${process.env.VUE_APP_ENDPOINT}/users/$master_account_code$`,
    ROUTE_USER_CREATE: `${process.env.VUE_APP_ENDPOINT}/user/`,
    ROUTE_USER_UPDATE: `${process.env.VUE_APP_ENDPOINT}/user/$user_id$`,
    ROUTE_USER_DETAILS_NOTIFICATIONS: `${process.env.VUE_APP_ENDPOINT}/user/details/notifications/get`,
    ROUTE_USER_DETAILS_NOTIFICATIONS_UPDATE: `${process.env.VUE_APP_ENDPOINT}/user/details/notifications/update`,
}

export const GROUP_ROUTES = { // Routes under /group
    ROUTE_PROCESSES: `${process.env.VUE_APP_ENDPOINT}/group/$group_id$/processes/$subaccount_id$`, //GET
    ROUTE_GROUPS: `${process.env.VUE_APP_ENDPOINT}/groups/$master_account_code$`,
    ROUTE_CREATE_GROUP: `${process.env.VUE_APP_ENDPOINT}/group`,
    ROUTE_GET_GROUP_DETAILS: `${process.env.VUE_APP_ENDPOINT}/group/$group_id$`,
    ROUTE_SAVE_GROUP: `${process.env.VUE_APP_ENDPOINT}/group/$group_id$`,
}

export const PROCESS_ROUTES = {
    ROUTE_PROCESS_FIELDS: `${process.env.VUE_APP_ENDPOINT}/process/$process_code$/fields`,
    ROUTE_PROCESS_LAUNCH: `${process.env.VUE_APP_ENDPOINT}/process/launch`
}

export const TRANSACTION_ROUTES = {
    ROUTE_TRANSACTIONS: `${process.env.VUE_APP_ENDPOINT}/transactions/get`,
    ROUTE_TRANSACTIONS_FILTERS: `${process.env.VUE_APP_ENDPOINT}/transactions/getSearchFilters`,
    ROUTE_TRANSACTION: `${process.env.VUE_APP_ENDPOINT}/transaction/$transaction_id$`,
    ROUTE_TRANSACTION_CERTIFICATE: `${process.env.VUE_APP_ENDPOINT}/certificate/$certificate_id$`,
    ROUTE_TRANSACTIONS_PEE: `${process.env.VUE_APP_ENDPOINT}/transaction/$transaction_id$/pee/$postamail_id$`,
}

export const SUPPORT_ROUTES = {
    ROUTE_SUPPORT_HELP: `${process.env.VUE_APP_ENDPOINT}/support/help`,
    ROUTE_SUPPORT_RECOVER_PASSWORD_EMAIL: `${process.env.VUE_APP_ENDPOINT}/support/recover-password/email`,
    ROUTE_SUPPORT_RECOVER_PASSWORD_PHONE: `${process.env.VUE_APP_ENDPOINT}/support/recover-password/phone`,
}

export const STATISTICS_ROUTES = {
    ROUTE_STATISTICS: `${process.env.VUE_APP_ENDPOINT}/statistics/search`,
    ROUTE_STATISTICS_FILTERS: `${process.env.VUE_APP_ENDPOINT}/statistics/getSearchFilters`,
}

export const OTHER_ROUTES = {
    ROUTE_USER_MANUAL: `${process.env.VUE_APP_ENDPOINT}/manual/$subdomain$`,
    ROUTE_DOCUMENT_VIEW: `${process.env.VUE_APP_ENDPOINT}/api_document`,
    ROUTE_USER_GUIDE: `${process.env.VUE_APP_ENDPOINT}/quick_guide/$subdomain$`,
    ROUTE_PDF_IS_A4: `${process.env.VUE_APP_ENDPOINT}/check_pdf_format`,
}

export const STATIC_RESOURCES_ROUTES = {
    ROUTE_SUBDOMAIN_CONFIG: "https://r.mailcomms.io/b2b/config/$subdomain$.json",
    ROUTE_LOCATIONS: "http://static.local.certy-sign.com/static/locations.json",
    ROUTE_LOCALE_FILE: "http://static.local.certy-sign.com/static/languages/$code$.json",
    ROUTE_COUNTRY_CODES: "http://static.local.certy-sign.com/static/countryCodes.json",
    ROUTE_TEMPLATES: `https://r.mailcomms.io/b2b/tiny/templates/$processCode$/$templateName$`,
}

export const BATCH_UPLOAD = {
    ROUTE_GET_BATCH_ID: `${process.env.VUE_APP_ENDPOINT}/process/$process_code$/batch-upload`,
    ROUTE_UPLOAD_DESCRIPTOR: `${process.env.VUE_APP_ENDPOINT}/process/$process_code$/batch-upload/$batch_id$/descriptor`,
    ROUTE_UPLOAD_RESOURCE: `${process.env.VUE_APP_ENDPOINT}/process/$process_code$/batch-upload/resource`,
    ROUTE_CLOSE_BATCH_UPLOAD: `${process.env.VUE_APP_ENDPOINT}/process/$process_code$/batch-upload/$batch_id$/close`,
}