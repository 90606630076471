import 'dayjs/locale/es'
import 'dayjs/locale/eu'
import 'dayjs/locale/ca'
import 'dayjs/locale/gl'
import 'dayjs/locale/en'
import 'dayjs/locale/pt'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/nl'
const isSameOrBeforePlugin = require('dayjs/plugin/isSameOrBefore')

const dayjs = require("dayjs")
dayjs.extend(isSameOrBeforePlugin)
export default dayjs
