export default Object.freeze({
  SEARCH: "search",
  PROCESSES: "computer",
  ACCOUNT: "person",
  CONTACTS: "people",
  STATISTICS: "trending_up",
  TRANSACTIONS:"alt_route",
  ADMINISTRATION:"apartment",
  GROUPS:"vpn_key",
  SUBACCOUNT: "subtitles",
  USERS: "how_to_reg",
  CLEAR: "clear_all",
  CONTACT_ADD: "person_add",
  ATTACH_FILE: "attach_file",
  NEXT: "east",
  WATCH_FILE: "visibility",
  REMOVE_FILE: "delete",
  ALERT: "report_problem",
  TRANSACTION: "insights",
  CHANGE_PASS: "https",
  CANCEL: "clear",
  USER: "person",
  PASSWORD: "vpn_key",
  CECO: "assignment",
  FORBIDDEN: "not_interested",
  NEW_USER: "person_add_alt_1",
  SAVE: "save_alt",
  SEND: "send",
  CLOSE: "close",
  VIEW: "visibility",
  EDIT: "edit",
  ALTERNATIVEMAIL: "alternate_email",
  TELEPHONE: "phone",
  BUSINESS: "business",
  GROUP_INFO: "assignment",
  LOGOUT: "power_settings_new",
  TAB_ARROW: "keyboard_arrow_right",
  CHECK: "check",
  CHECK_CIRCLE: "check_circle",
  ERROR: "error",
  BACK: "arrow_back",
  BACK_CIRCLE: "arrow_circle_left",
  PREVISUALIZATION: "visibility",
  HIDE_PREVISUALIZATION: "visibility_off",
  HELP: 'help_outline',
  EVENT_DEFAULT: 'send',
  DOWNLOAD_DOCUMENT: 'description',
  SIGNATURE:"edit",
  EMAIL: "email",
  SMS: "sms",
  CERT_EMAIL:"email",
  LANDING: "important_devices",
  BIOMETRIC_SIGNATURE: "face",
  POSTAL: "description",
  CONTACT_PHONE: "contact_phone",
  CONTACT_MAIL: "contact_mail",
  FORWARD_TO_INBOX: "forward_to_inbox",
  LINKED: "link",
  MANUAL: "menu_book",
  SUPPORT: "help_outline",
  NOTIFICATIONS: "notifications",
  EXPANDARROW: "expand_more",
  ADD: "add",
  ADD_CIRCLE: "add_circle",
  UP_FILE: "upload_file",
  CLOSE_CIRCLE: "cancel",
  WARNING: "warning"
})
