<template>
  <v-container id="document-viewer" class="cts-container-pdf-viewer fluid grid-list-md text-xs-center pa-0 ma-0">
    <v-row id="document-viewer-html-content" class="cts-row-pdf-viewer pa-0 ma-0 justify-center">
      <v-col class="pa-0 ma-0 d-flex justify-center flex-column">
        <v-row v-if="iframeContent">
          <v-col>
            <iframe title="html-viewer" id="cts-preview-html-container" class="iframe-preview" :srcdoc="iframeContent">
            </iframe>
          </v-col>
        </v-row>

        <v-row v-if="imageContent" justify="center">
          <img height="720" :src="imageContent" class="pb-6" :alt="$vuetify.lang.t('$vuetify.resourcespreview')">
        </v-row>

        <v-row id="document-viewer-pdf-content" v-if="loadingTask">
          <v-col class="ma-0 pa-0 d-flex justify-center" cols="12">
            <v-row class="ma-0 pa-0 d-flex justify-center">
              <v-col cols="12" class="d-flex justify-center">
                <v-row dense class="ma-0 pa-0 cts-pdf-manual-container d-flex justify-center">
                  <v-col class="ma-0 pa-0 d-flex justify-center" v-if="numPages">
                    <v-carousel class="cts-carousel" v-model="carousel" hide-delimiters>
                      <v-carousel-item
                          class="cts-carousel-item"
                          v-for="(page, i) in numPages"
                          :key="page"
                      >
                        <v-sheet
                          color="white"
                          height="100%"
                          width="100%"
                          tile
                        >
                          <v-row
                              class="fill-height"
                              align="center"
                              justify="center"
                          >
                              <div class="cts-displayed-pdf" >
                                <pdf
                                  :src="loadingTask"
                                  :page="i+1"
                                  class="row pa-0 ma-0"
                                  style="height: 100%"
                                >
                                </pdf>
                              </div>
                          </v-row>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- button downdload -->
        <hr class="html-separator" v-if='base64'>
        <v-row class="mt-7 mb-5 btn-download--document">
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-btn
                  class="cts-button-standard mx-auto"
                  block
                  dark
                  @click="downloadManual()"
                >
                  <v-icon dense class="float-right">download</v-icon>
                  <span>{{$vuetify.lang.t('$vuetify.download')}}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!--  -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import pdf from 'vue-pdf'
  import { fileToBase64String, generatePdfDownload } from "@/util/utils"

  export default {
    name: "documentsViewer",
    components: { pdf },
    props: {
      file: File,
      fileName: String,
      documentType: String,
      fileContent: String

    },

    data() {
      return {
        filePDF: null,
        fileToShow: null,
        numPages: null,
        loadingTask: null,
        overlay: false,
        carousel: 0,
        base64: null,
        iframeContent: null,
        imageContent: null
      }
    },

    async mounted() {
      if (this.file || this.fileContent) {
        this.base64 = this.file ? await fileToBase64String(this.file) : this.fileContent
  
        switch (this.documentType.toLowerCase()) {
          case "application/pdf":
          case "pdf":
            this.processPdf()
            break
          
          case "text/html":
          case "html":
            this.processText()
            break

          case "image":
            this.processImage()
            break

          default:
            this.processText()
            break
        }

      }
    },

    methods: {
      loadPdf(base64String) {
        this.loadingTask = pdf.createLoadingTask(base64String);
        this.loadingTask.promise.then(pdf => {
          this.numPages = pdf.numPages
        })
      },

      downloadManual() {
        const fileContent = this.base64.split(",")[1]
        generatePdfDownload(fileContent, this.fileName)
      },

      async processPdf() {
        this.loadPdf(this.base64)
      },

      processImage() {
        const fileContent = this.base64.split(",")[1]
        this.imageContent = `data:image/jpg;base64,${fileContent}`
      },

      processText() {
        const fileContent = this.base64.split(",")[1]

        this.iframeContent = decodeURIComponent(Array.prototype.map.call(atob(fileContent), (c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
      }
    },

    watch: {
      file() {
        this.processPdf()
      }
    }
  }
</script>

<style scoped>
  .iframe-preview {
    width: 100%;
    height: 100%;
    border: none;
  }

  .html-separator {
    margin: 0 -20px !important;
    border-top: 1px solid #767676
  }
</style>
