<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container class="cts-main-container cts-main-view col-12 pa-0">
    <v-row dense class="ma-0">
      <v-col cols="10" xl="10" lg="10" md="10" sm="10" class="col-lg-offset-1 mx-auto cts-view-header">
        <v-row dense>
          <v-col cols="12" xl="10" lg="10" md="10" sm="10">
            <view-title
              :icon="const_icons.ADMINISTRATION"
              :title="$vuetify.lang.t('$vuetify.administrationTitle')"
              :subtitle="$vuetify.lang.t('$vuetify.administrationDescription')"
              idName="administration"
            />
          </v-col>
        </v-row>
        <!--    Aquí para cambiar el theme se llama a ese componente  -->

        <!--    <v-row dense class="ma-0 mt-2">-->
        <!--      <div class="col-10 offset-1">-->
        <!--        <div class="col-3">-->
        <!--          <SwichTheme></SwichTheme>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </v-row>-->

      </v-col>
    </v-row>
    <v-row dense class="ma-0 mt-2">
      <div class="col-10 offset-1">
          <v-fade-transition v-for="(section ,index) in sections" :key="index">
            <v-col cols="12" sm="6" md="6" lg="4" class="py-2 d-block float-left" v-if="section.allowed">
              <items-card :item="section"/>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" class="py-2 d-block float-left" v-else>
              <items-card :item="section" disabled="true" />
            </v-col>
          </v-fade-transition>
        </div>
    </v-row>
  </v-container>
</template>

<script>
  import const_icons from '@/constants/icons'
  import ViewTitle from "../components/structures/viewTitle"
  // import SwichTheme from "../components/SwichTheme"
  import ItemsCard from "../components/sections/administration/administrationView/itemsCard"
  import const_global from "@/constants/global"
  import { checkPermission } from "@/util/store_utils"
  import { GROUPS, USERS } from "@/constants/router/routes"

  export default {
    name: "Contacts",
    components: {ItemsCard, ViewTitle},
    data: function () {
      return {
        const_icons,
        sections:[
          {
            code: 'groups',
            allowed: checkPermission(`${const_global.SECTION}_${GROUPS.NAME}`),
            name: this.$vuetify.lang.t('$vuetify.groupsTitle'),
            description: this.$vuetify.lang.t('$vuetify.groupsDescription')
          },
          {
            code: 'users',
            allowed: checkPermission(`${const_global.SECTION}_${USERS.NAME}`),
            name: this.$vuetify.lang.t('$vuetify.usersTitle'),
            description: this.$vuetify.lang.t('$vuetify.usersDescription')
          },
        ]
      }
    }
  }
</script>
