<template>
  <v-col cols="11" md="10" class="cts-transaction-launched-content">
    <v-row dense class="my-2">
      <h1 id="processes-transaction_launched-text-title_processing" class="cts-color-darken" v-if="transaction_status == const_global.TRANSACTION_PROCESSING">
        <v-icon id="processes-transactions_launched-text-icon_loading" color="orange" class="mr-2 cts-icon-animated">cached</v-icon>
        {{ $vuetify.lang.t('$vuetify.transactionProcessing') }}
      </h1>
      <h1 id="processes-transaction_launched-title_ko" class="cts-color-darken" v-if="transaction_status == const_global.TRANSACTION_ERROR">
        <v-icon id="processes-transactions_launched-text-icon_ko" color="orange" class="mr-2">{{const_icons.ERROR}}</v-icon>
        <span v-if="transaction_result">
          <span id="processes-transactions_launched-text-title_ko" v-if="transaction_result.extra.length == 1">
              {{ $vuetify.lang.t('$vuetify.transactionError') }}
          </span>
          <span id="processes-transaction_launched-text-title_ko" v-else>
            {{ $vuetify.lang.t('$vuetify.transactionsError') }}
          </span>
        </span>
      </h1>
      <h1 id="processes-transaction_launched-text-ok" class="cts-color-darken" v-if="transaction_status == const_global.TRANSACTION_OK">
        <v-icon id="processes-transactions_launched-text-icon_ok" color="green" class="mr-2">{{const_icons.CHECK}}</v-icon>
        <span v-if="transaction_result">
          <span id="processes-transactions_launched-text-title_ok" v-if="transaction_result.extra.length == 1">
           {{ $vuetify.lang.t('$vuetify.transactionProcessed') }}
          </span>
          <span id="processes-transaction_launched-text-title_ok" v-else>
            {{ $vuetify.lang.t('$vuetify.transactionsProcessed') }}
          </span>
        </span>
      </h1>
    </v-row>
    <v-row dense class=" ma-0 mt-6">
      <v-col cols="12">
        <v-row dense class="ma-0 mb-4">
          <v-col md="12" class="pa-0 px-2">
            <p v-if="transaction_status == const_global.TRANSACTION_OK" class="ma-0 text-justify">
              <span v-if="transaction_result">
                <span id="processes-transaction_launched_ok-text-description" v-if="transaction_result.extra.length == 1">
              {{$vuetify.lang.t('$vuetify.transactionLaunchedMessage') }}
                </span>
                <span id="processes-transactions_launched_ok-text-description" v-else>
              {{$vuetify.lang.t('$vuetify.transactionsLaunchedMessage') }}
                </span>
                 <a id="processes-transaction_launched-link-description"
                    v-if="checkPermission(const_permissions.SECTION_TRANSACTIONS)"
                    @click="goToTransactionsView" class="cts-link text-lowercase"> {{
                $vuetify.lang.t('$vuetify.transactions') }}</a><span v-else class="text-lowercase"> {{ $vuetify.lang.t('$vuetify.transactions') }}</span>.
              </span>
            </p>
            <p id="processes-transaction_launched-text-processing_message" v-if="transaction_status == const_global.TRANSACTION_PROCESSING" class="ma-0 text-justify">{{
              $vuetify.lang.t('$vuetify.transactionProcessingMessage') }}</p>
            <p v-if="transaction_status == const_global.TRANSACTION_ERROR" class="ma-0 text-justify">
               <span v-if="transaction_result">
                <span id="processes-transaction_launched-text-error_message" v-if="transaction_result.extra.length == 1">
              {{$vuetify.lang.t('$vuetify.transactionErrorMessage') }}:
                </span>
                <span id="processes-transaction_launched-text-errors_messages" v-else>
              {{$vuetify.lang.t('$vuetify.transactionsErrorMessage') }}:
                </span>
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 " v-if="transaction_result">
          <v-col md="12" class="pa-0">
            <v-data-table id="processes-transaction_launched-table"
                    dense
                    :items-per-page="5"
                    sort-by="errors"
                    sort-desc
                    :items="transaction_result.extra"
                    class="elevation-0 col-12 cts-transactions-datatable cts-processed-transactions-datatable px-0 px-lg-2 pt-0"
            >
              <template v-slot:item="{item}">
                <v-card :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}`" class="mx-auto my-2 cts-card-transaction cts-w-100" outlined>
                  <v-list-item class="cts-list-item-card px-2">
                    <v-list-item-content class="py-2">
                      <v-list-item-title>
                        <v-row dense class="ma-0 justify-space-between">
                          <v-col class="pa-0 ma-0 py-1" cols="10">
                            <v-icon :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-error_icon`" v-if="item.errors && item.errors.length != 0" small color="red">
                              {{const_icons.ERROR}}
                            </v-icon>
                            <v-icon :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-ok_icon`" v-else small color="green">{{const_icons.CHECK}}</v-icon>
                            <span :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-contact_id`" class="cts-font-size-5 font-weight-bold cts-white-space ml-4">{{ item.recipient.contact_id }} </span>
                            <span :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-fullname`">{{ " - " + item.recipient.contact_name + " " + item.recipient.contact_surname}}</span>
                          </v-col>
                          <v-col class="pa-0 ma-0 text-right py-1" cols="2">
                            <span :id="`processes-transaction_launched-table-transaction_${item.recipient.contact_name}_${item.recipient.contact_surname}-error`" class="red--text" v-if="item.errors && item.errors.length != 0">
                              {{$vuetify.lang.t('$vuetify.invalidTransaction') }}
                            </span>
                          </v-col>

                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 mt-8" v-if="transaction_status != const_global.TRANSACTION_PROCESSING">
          <v-col cols="12" class="pa-0">
            <v-row dense class="ma-0 pa-0 justify-end hidden-md-and-down">
              <v-btn id="processes-transaction_launched-button-go_to_transactions" v-if="checkPermission(const_permissions.SECTION_TRANSACTIONS)"
                     class="cts-button-tertiary mr-4"
                     small @click="goToTransactionsView">
                {{ $vuetify.lang.t('$vuetify.transactionRedirectToTransactions') }}
                <v-icon small class="float-right">{{const_icons.TRANSACTIONS}}</v-icon>
              </v-btn>
              <v-btn id="processes-transaction_launched-button-go_to_processes" class="cts-button-tertiary"
                     small  @click="goToProcessesView">
                {{ $vuetify.lang.t('$vuetify.transactionRedirectToProcesses') }}
                <v-icon small class="float-right">{{const_icons.PROCESSES}}</v-icon>
              </v-btn>
            </v-row>
            <v-row dense class="ma-0 pa-0 justify-end hidden-lg-and-up">
              <v-btn id="processes-transaction_launched-button-go_to_transactions2" v-if="checkPermission(const_permissions.SECTION_TRANSACTIONS)"
                     class="cts-button-tertiary-block col-12 my-2"
                     small @click="goToTransactionsView">
                {{ $vuetify.lang.t('$vuetify.transactionRedirectToTransactions') }}
                <v-icon small class="float-right">{{const_icons.TRANSACTIONS}}</v-icon>
              </v-btn>
              <v-btn id="processes-transaction_launched-button-go_to_processes2" class="cts-button-tertiary-block col-12 my-2"
                     small @click="goToProcessesView">
                {{ $vuetify.lang.t('$vuetify.transactionRedirectToProcesses') }}
                <v-icon small class="float-right">{{const_icons.PROCESSES}}</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
  import { checkPermission } from "@/util/store_utils"
  import const_global from "@/constants/global"
  import const_icons from "@/constants/icons"
  import { NAMESPACE as PROCESSES_NAMESPACE, STATE as PROCESSES_STATE } from "@/constants/vuex/processes"
  import const_permissions from "@/constants/permissions"
  import { NAMESPACE as TRANSACTIONS_NAMESPACE, ACTIONS as TRANSACTIONS_ACTIONS } from "@/constants/vuex/transactions"
  import { redirect } from "@/util/router_utils"
  import { TRANSACTIONS, PROCESSES, TRANSACTION_VIEW } from "@/constants/router/routes"

  export default {
    name: "sectionTransactionLaunched",
    data() {
      return {
        const_icons,
        const_global,
        const_permissions,
        checkPermission
      }
    },
    computed: {
      transaction_status() {
        return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_TRANSACTION_STATUS]
      },
      transaction_result() {
        return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_TRANSACTION_RESULT]
      }
    },
    methods: {
      goToTransactionsView() {
        redirect(TRANSACTIONS)
      },
      goToProcessesView() {
        redirect(PROCESSES)
      },
      executeItem(instance_code) {

        this.$store.dispatch(TRANSACTIONS_NAMESPACE + "/" + TRANSACTIONS_ACTIONS.A_SET_TRANSACTION_SELECTED, instance_code)
        redirect(TRANSACTION_VIEW)
      },
    }
  }
</script>

<style lang="scss" scoped>
  @keyframes spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  .cts-icon-animated {
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
</style>
