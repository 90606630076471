<template>
    <v-list-item class="pa-0 my-2" :tabindex="false">
      <v-list-item-avatar class="ma-0 mr-1 cts-icon-title" :class="image ? 'cts-bgcolor-primary' : ''">
        <icon-base :id="`${idName}-image-icon`" v-if="image" :icon-name="title" class="mx-auto mt-1" :src="src" icon-color="var(--clear)" width="30" height="25">
        </icon-base>
        <v-icon  :id="`${idName}-icon`" aria-hidden="false" v-if="!image" class="cts-bgcolor-primary-alternative white--text">{{icon}}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content class="pa-0 pl-2">
        <v-list-item-title>
          <h1 :id="`${idName}-title`" class="cts-view-title">{{title}}</h1>
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-tooltip v-if="subtitle.length > 100" bottom max-width="98%">
            <template v-slot:activator="{ on, attrs }">
              <h2 :id="`${idName}-subtitle`" class="cts-view-subtitle font-weight-regular" v-bind="attrs" v-on="on">{{subtitle}}</h2>
            </template>
            <span :id="`${idName}-tooltip-subtitle`"  style="word-wrap: break-word">{{ subtitle  }}</span>
          </v-tooltip>
          <h2 :id="`${idName}-subtitle`" v-else class="cts-view-subtitle font-weight-regular">{{subtitle}}</h2>
          <span v-if="rightLink" class="cts-view-subtitle cts-color-primary float-right ">
            <a :id="`${idName}-right_link`" class="cts-link" @click="emitEvent">{{rightLink.text}}</a></span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
</template>

<script>
  import {ServerBus} from "@/main";
  import IconBase from "@/assets/images/IconBase";

  export default {
    name: "viewTitle",
    components: {IconBase},
    props:{
      icon: String,
      title: String,
      subtitle: String,
      rightLink: null,
      image: Boolean,
      idName: String
    },
    data() {
      return {
        src: null
      }
    },
    methods:{
      emitEvent(){
        ServerBus.$emit(this.rightLink.event, this.rightLink.args)
      }
    },
    created() {
      try{
        this.src = require('@/assets/images/svgIcons/' + this.$props.icon + '.vue')
        if (this.src) {
          this.src = this.$props.icon
          return this.src
        }
      } catch (e) {
        return this.src = 'default'
      }
    }
  }
</script>

<style scoped>

</style>
