<template>
  <v-container class="cts-container-pdf-viewer fluid grid-list-md text-xs-center pa-0 ma-0">
    <v-row class="cts-row-pdf-viewer pa-0 ma-0">
      <v-col class="pa-0 ma-0">
        <!-- <embed v-if="base64" :src="mediaElem" type="application/pdf" style="overflow: auto; width: 100%; height: 100%;"> -->
        <v-row v-if="pdfFile">
          <v-col class="ma-0 pa-0" cols="12">
            <v-row class="ma-0 pa-0">
              <v-col cols="12">
                <v-row dense class="ma-0 pa-0 cts-pdf-manual-container">
                  <v-col class="ma-0 pa-0" v-if="numPages">
                    <v-carousel class="cts-carousel" v-model="carousel" hide-delimiters>
                      <v-carousel-item
                        class="cts-carousel-item"
                        v-for="(page, i) in numPages"
                        :key="page"
                      >
                        <v-sheet
                          color="white"
                          height="100%"
                          width="100%"
                          tile
                        >
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                            <div class="cts-displayed-pdf">
                              <pdf
                                :src="loadingTask"
                                :page="i+1"
                                class="row pa-0 ma-0"
                                style="height: 100%"></pdf>
                            </div>
                          </v-row>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
                <v-row v-if="numPages">
                  <v-col cols="12" class="pa-0 mt-4">
                    <v-btn class="cts-button-standard mx-auto " block
                           @click="download()" dark>
                      <v-icon dense class="float-right">download</v-icon>
                      <span>{{ $vuetify.lang.t('$vuetify.download') }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="overlay">
          <loading-container/>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pdf from 'vue-pdf'
import LoadingContainer from '../../components/structures/loadingContainer'
import { generatePdfDownload } from "@/util/utils"

export default {
  name: 'PDFViewer',
  components: { LoadingContainer, pdf },
  props: {
    file: {
      default: null,
      type: Object
    }
  },
  data () {
    return {
      filePDF: null,
      fileToShow: null,
      numPages: null,
      loadingTask: null,
      overlay: false,
      carousel: 0,
      subdomain: window.location.host.split('.')[0].toLowerCase(),
    }
  },
  mounted () {
    if(this.file) {
      this.loadBlob(this.file)
    }
  },
  computed: {
    mediaElem () {
      return this.fileToShow
    },
    pdfFile () {
      this.setLoadingTaskToNull()

      if (this.file != null) {
        this.setPdfViewer(this.file.fileContent)
      }
      return this.file
    }
  },
  methods: {
    loadBlob (file) {
      const files = []

      files.push({
        name: file.name,
        content: file.fileContent
      })

      let mediaElem = {}

      if (files && files.length > 0) {

        const targetFile = files[0]

        let base64str = targetFile.content
        let binary = atob(base64str.replace(/\s/g, ''))
        let len = binary.length
        let buffer = new ArrayBuffer(len)
        let view = new Uint8Array(buffer)

        for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i)
        }

        let file = new Blob([view], { type: 'application/pdf' })

        try {
          const objectURL = URL.createObjectURL(file)

          mediaElem.src = objectURL

          this.fileToShow = mediaElem.src

        } catch (e) {

          try {
            const fileReader = new FileReader()
            fileReader.onload = (evt) => {
              mediaElem.src = evt.target.result
            }
            fileReader.readAsDataURL(targetFile)
          } catch (e) {
            console.log(`File Upload not supported: ${e}`)
          }

        }
      }
    },
    setLoadingTaskToNull () {
      this.overlay = true
      this.loadingTask = null
    },
    setPdfViewer (content) {
      setTimeout(() => {
        this.loadingTask = pdf.createLoadingTask('data:application/pdf;base64,' + content)
        this.loadingTask.promise.then(pdf => {
          this.numPages = pdf.numPages
        })
        this.overlay = false
      }, 1)
    },
    download () {
      generatePdfDownload(this.file.fileContent, this.file.name)
    }
  }
}
</script>
