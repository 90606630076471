var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"cts-tab-item-content",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"cts-tab-title mx-3 mb-3 py-0 px-2 hidden-xs-only"},[_c('h4',[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","size":"19"}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1)]),_c('v-card-text',{staticClass:"cts-tab-content pa-0 px-sm-4"},[_c('p',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.adminGroupInfoLinked')))]),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0 offset-lg-9",attrs:{"cols":"12","lg":"3","xl":"3","md":"12","sm":"12"}},[_c('v-row',{staticClass:"ma-0 justify-end",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"ma-0 cts-text-box",attrs:{"multiple":"","clearable":"","single-line":"","outlined":"","dense":"","placeholder":_vm.$vuetify.lang.t('$vuetify.search'),"append-icon":_vm.const_icons.SEARCH,"aria-label":_vm.$vuetify.lang.t('$vuetify.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-0 col-12 pa-0 pa-sm-3",attrs:{"dense":"","headers":_vm.headers,"items":_vm.linkedGroups,"search":_vm.search,"sort-by":"name","calculate-widths":"","footer-props":{
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
              itemsPerPageText: _vm.$vuetify.lang.t('$vuetify.groupsPerPage'),
              pageText: ((_vm.$vuetify.lang.t('$vuetify.groupsFound')) + " " + _vm.nOfGroups + " " + (_vm.$vuetify.lang.t('$vuetify.groups')))
            }},on:{"current-items":_vm.handleItemChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [(item.name != _vm.group.group_name)?_c('tr',{staticClass:"hidden-xs-only"},[_c('td',{staticClass:"text-start cts-group-linked-name-column"},[(item.name.length > 15)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({staticClass:"cts-color-tertiary cts-break-word"},'h4',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]):_c('h4',{staticClass:"cts-color-tertiary cts-break-word"},[_vm._v(_vm._s(item.name))])],1),_c('td',{staticClass:"text-start cts-group-linked-description-column"},[(item.description.length > 15)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({staticClass:"cts-color-tertiary cts-break-word"},'h4',attrs,false),on),[_vm._v(_vm._s(item.description))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])]):_c('h4',{staticClass:"cts-color-tertiary cts-break-word"},[_vm._v(_vm._s(item.description))])],1),_c('td',{staticClass:"text-center"},[_c('v-simple-checkbox',{staticClass:"cts-group-linked",attrs:{"ripple":false,"disabled":!_vm.checkPermission(_vm.const_permissions.GROUP_EDIT_LINKED_GROUPS),"color":"var(--primary)","aria-label":"checkbox-linked-group"},on:{"click":function($event){return _vm.$emit('change')}},model:{value:(item.linked_group),callback:function ($$v) {_vm.$set(item, "linked_group", $$v)},expression:"item.linked_group"}})],1)]):_vm._e(),_c('v-card',{staticClass:"mx-auto my-2 cts-card-transaction hidden-sm-and-up",attrs:{"max-width":"100%","outlined":""}},[_c('v-list-item',{staticClass:"cts-list-item-card"},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{staticClass:"ma-0 justify-space-between",attrs:{"dense":""}},[_c('v-col',{staticClass:"hidden-sm-and-up pa-0 ma-0",attrs:{"cols":"10"}},[(item.name.length > 15)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({staticClass:"cts-color-tertiary cts-break-word text-truncate",staticStyle:{"max-width":"300px !important"}},'h4',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]):_c('h4',{staticClass:"cts-font-size-5 cts-white-space pa-1 cts-color-tertiary cts-break-word"},[_c('span',{staticClass:"font-weight-bold mr-1"},[_vm._v(_vm._s(item.name))])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-simple-checkbox',{staticClass:"cts-group-linked",attrs:{"ripple":false,"disabled":!_vm.checkPermission(_vm.const_permissions.GROUP_EDIT_LINKED_GROUPS),"color":"var(--primary)"},on:{"click":function($event){return _vm.$emit('change')}},model:{value:(item.linked_group),callback:function ($$v) {_vm.$set(item, "linked_group", $$v)},expression:"item.linked_group"}})],1)],1)],1),_c('v-list-item-subtitle',[_c('v-row',{staticClass:"ma-0 justify-space-between",attrs:{"dense":""}},[_c('v-col',{staticClass:"hidden-sm-and-up pa-0 ma-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"cts-font-size-5 cts-white-space pa-1"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.description))])])])],1)],1)],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }