<template>
  <v-row>
    <v-col md="12">
      <v-row class="ma-0 cts-categories-title-b pt-0">
        <h3 :id="`${idName}-title`">
          <v-list-item-avatar v-if="initials" class="ma-0 cts-icon-title cts-field-medium-block" style="vertical-align: baseline">
            <span :id="`${idName}-title_initials`" class="cts-bgcolor-primary-alternative white--text pa-1 mr-1">{{ setinitials(text) }}</span>
          </v-list-item-avatar>
          <v-icon :id="`${idName}-title_icon`" aria-hidden="false" v-if="icon" class="mb-1">{{icon}}</v-icon>
          <span :id="`${idName}-title_text`">{{text}}</span>
        </h3>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import { getInitials } from "@/util/utils"

  export default {
    name: "sectionTitle",
    props:{
      initials: Boolean,
      icon: String,
      text: String,
      idName: String
    },
    methods: {
      setinitials() {
        return getInitials(this.text)
      }
    }
  }
</script>

<style scoped>

</style>
