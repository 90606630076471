<template>
  <v-container class="pa-0">
    <v-row dense>
      <v-file-input
        :id="`${customid}`"
        :ref="'field_'+fieldName"
        v-model="content"
        :aria-label="$vuetify.lang.t('$vuetify.selectFiles')"
        :multiple="!singleFile"
        :placeholder="$vuetify.lang.t('$vuetify.selectFiles')"
        :show-size="true"
        class="cts-field-file"
        color="primary"
        counter
        dense
        outlined
        :rules="transformedValidations"
        :accept="fileTypesAccepted ? fileTypesAccepted.join(', ') : ''"
        @change="onFileChange(content)"
        @click:clear="handleClear"
      >
        <template v-slot:selection="{ text }">
          <v-tooltip bottom v-if="fieldName.length > 21">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                  style="border-radius: 4px;"
                  class="pa-1"
                  color="tertiary"
                  label
                  small
                  v-bind="attrs" v-on="on"
              >
                {{ text }}
              </v-chip>
            </template>
            <p class="ma-0 pa-0">{{fieldName}}</p>
          </v-tooltip>

          <v-chip
            v-else
            style="border-radius: 4px;"
            class="pa-1"
            color="tertiary"
            label
            small
          >
            {{ text }}
          </v-chip>

        </template>
      </v-file-input>
      <v-progress-circular
        v-if="loading"
        size="24"
        indeterminate
        class="mx-2 mt-1"
        color="var(--primary-alternative)"
      />
    </v-row>

    <v-row class="pl-2">
      <div v-if="errorMessage" class="primary--text">
        <span class="b2b-filedata-input">{{ errorMessage }}</span>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import fileValidations from "@/util/validations/fileValidations"
import { readFileContentAsync, createFileObjectFromBase64, convertBytesToMegabytes } from "@/util/utils"
import { getDocumentFormatA4 } from "@/services/otherServices"

export default {
  name: 'fileDataField',
  props: {
    customid: String,
    value: [String, Array, Object, File],
    fieldName: String,
    singleFile: Boolean,
    fileTypesAccepted: Array,
    validations: {
      type: Object,
      default: null
    },
    formatA4: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    transformedValidations() {
      let rules = []

      if (this.validations) {
        if (!this.validations.allow_empty) {
          rules.push(v => !(!v)|| this.$vuetify.lang.t('$vuetify.requiredField'))
        }

        if (this.validations.max_size_bytes && this.validations.max_size_bytes > 0) {
          rules.push(this.ruleSize)
        }
      }

      return rules
    }
  },

  data() {
    return {
      content: null,
      errorMessage: null,
      files: [],
      loading: false
    }
  },

  mounted() {
    if (this.value) {
      this.content = Array.isArray(this.value) ? this.value.map(x => createFileObjectFromBase64(x)) : createFileObjectFromBase64(this.value)
    }

  },

  methods: {
    async addFile(file) {
      this.errorMessage = null
      this.loading = true

        if (this.extensionIsValid(file)) {
          const content = await readFileContentAsync(file)

          if (this.formatA4) {
            await getDocumentFormatA4(content).then(resp => {
              if (resp.data.format_correct) {
                this.files.push(file)

              } else {
                this.errorMessage = this.$vuetify.lang.t('$vuetify.processA4NotSupported')
              }
            })
          } else {
            this.files.push(file)
          }
        } else {
          this.files.push(file)
          this.errorMessage = this.$vuetify.lang.t('$vuetify.fileExtensionNotAccepted').replace("{acceptedExtensions}", this.validations.allowed_extensions.join(', '))
        }

        this.loading = false
    },

    async onFileChange(newFiles) {
      this.$emit("change", newFiles)
      this.files = []
      this.$emit("clear")

      for (const f of newFiles) {
        await this.addFile(f)
      }

      const firstFile = this.files[0] ? this.files[0] : null
      this.$emit("input", this.singleFile ? firstFile : this.files)
    },

    ruleSize(files) {
      let result = true
      if (Array.isArray(files)) {
        files.forEach(x => {
          if (x.size > this.validations.max_size_bytes) {
            this.errorMessage = result = this.$vuetify.lang.t('$vuetify.rulesFilesSize').replace("{fileSize}", convertBytesToMegabytes(this.validations.max_size_bytes))
          }
        })
      }
      
      return result
    },

    handleClear() {
      this.content = null
      this.files = []
      this.errorMessage = null
      this.$emit("clear")
    },

    extensionIsValid(file) {
      if ("allowed_extensions" in this.validations) {
        return fileValidations.fileExtension(file, this.validations.allowed_extensions)
      }

      return true
    }
  }
}
</script>

<style>
  .b2b-filedata-input {
    color: var(--error) !important;
    font-size: 12px !important;
  }
</style>
