<template>
  <div
    id="dropzone"
    ref="dropzone"
    class="dropzone"
    :style="`height: ${height}px`"
    @click="triggerFileInputClick"
  >
    <input
      id="dropzone-file_input"
      ref="uploader"
      :multiple="multiple"
      :accept="acceptedTypes"
      class="d-none"
      type="file"
      @change="onFileChange"
    >
    <v-row v-if="!hasCustomTextSlot" :justify="justify" :align-content="alingCenter" style="height: 100%">
      <v-icon :size="iconSize" :color="iconColor">{{ icon }}</v-icon>
      <v-col v-if="!loading" :cols="textCols" class="text-center">
        <span id="dropzone-label">{{ text }}</span>
      </v-col>
      <v-col v-else :cols="textCols" class="text-center">
        <v-progress-circular
          indeterminate
          color="red"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <slot name="customText" />
  </div>
</template>

<script>
import vModelMixin from '@/mixins/v-model-mixin'
  export default {
    name: "DropZone",
    mixins: [vModelMixin],
    props: {
      height: [Number, String],
      maxByteSizePerFile: {
        type: Number,
        default: () => null
      },
      multiple: Boolean,
      acceptedTypes: String,
      asyncMode: Boolean,
      text: String,
      icon: {
        type: String,
        default: "file_upload"
      },
      iconColor: {
        type: String,
        default: "var(--primary)"
      },
      iconSize: {
        type: Number,
        default: 96
      },
      justify: {
        type: String,
        default: "center"
      },
      alingCenter: {
        type: String,
        default: "center"
      },
      textCols: {
        type: Number,
        default: 1
      },
      isLoading: Boolean
    },

    data() {
      return {
        loading: false
      }
    },

    computed: {
      dropZoneRef() {
        return this.$refs.dropzone
      },

      hasCustomTextSlot() {
        return this.$slots["customText"]
      }
    },

    methods: {
      checkFileSize(file, limitSizeBytes) {
        return this.maxByteSizePerFile && this.maxByteSizePerFile > 0 ? file.size < limitSizeBytes : true
      },

      validateInputFiles(eventFiles) {
        const files = Array.from(eventFiles)
        return files.reduce((acc, cur) => {
          acc.filesExcedingLimit = !acc.filesExcedingLimit ? [] : [...acc.filesExcedingLimit]
          acc.okFiles = !acc.okFiles ? [] : [...acc.okFiles]

          this.checkFileSize(cur, this.maxByteSizePerFile) ? acc.okFiles.push(cur) : acc.filesExcedingLimit.push(cur)

          return acc
        }, {})

      },

      dropHandler(event) {
        event.preventDefault()

        if (event.dataTransfer.items) {
          const items = [...event.dataTransfer.items]
          if (this.multiple) {
            const filesToAdd = items
              .filter(x => x.kind === "file")
              .map(x => x.getAsFile())

            const validationResults = this.validateInputFiles(filesToAdd)
            this.emitFilesWithError(validationResults.filesExcedingLimit)
            
            this.internalValue = this.asyncMode ? [...this.internalValue, ...validationResults.okFiles] : [...validationResults.okFiles]
            this.loading = false

          } else {
            const item = items.pop()
            if (item.kind === 'file') {
              this.internalValue = item.getAsFile()
              this.loading = false
            }
          }
        }
      },

      dragOverHandler(event) {
        event.preventDefault()
      },

      triggerFileInputClick() {
        this.$refs.uploader.value = ""
        if (!this.asyncMode) {
          this.internalValue = null
        }
        this.$refs.uploader.click()
      },

      onFileChange(event) {
        if (event.target.files) {
          if (this.asyncMode) {
            // When asyncMode multiple is always true
            let newFiles = this.multiple ? Array.from(event.target.files) : event.target.files[0]
            newFiles = !Array.isArray(newFiles) ? [newFiles] : newFiles

            const validationResults = this.validateInputFiles(newFiles)
            this.emitFilesWithError(validationResults.filesExcedingLimit)
            this.internalValue = [...this.internalValue, ...validationResults.okFiles.filter(x => !this.internalValue.map(x => x.name).includes(x.name))]

          } else {
            const validationResults = this.validateInputFiles(event.target.files)
            this.emitFilesWithError(validationResults.filesExcedingLimit)
            this.internalValue = this.multiple ? validationResults.okFiles : validationResults.okFiles[0] 
          }
        }
      },

      emitFilesWithError(files) {
        if (files.length > 0)
        this.$emit("filesWithError", files)
      }
    },

    mounted() {
      this.dropZoneRef.ondrop = this.dropHandler
      this.dropZoneRef.ondragover = this.dragOverHandler

      if (this.isLoading) {
        this.loading = this.isLoading
      }
    },

    watch: {
      isLoading() {
        this.loading = this.isLoading
      }
    }
  }
</script>

<style lang="scss" scoped>
  div.dropzone {
    width: 100%;
    background-color: var(--lighten);
    border: 1px dashed var(--primary);
    cursor: pointer;
    border-radius: 8px;
  }
</style>