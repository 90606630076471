<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container class="cts-main-container cts-main-view col-12 pa-0">
    <v-scroll-y-transition>
      <v-col cols="12" class="ma-0 pa-0 ">
        <v-row dense class="ma-0">
          <v-col cols="11" xl="10" lg="10" md="11" sm="11" class="col-lg-offset-1 mx-auto cts-view-header">
            <v-row dense>
              <v-col cols="12" xl="10" lg="10" md="10" sm="10">
                <view-title
                  :icon="const_icons.STATISTICS"
                  :title="$vuetify.lang.t('$vuetify.statisticsTitle')"
                  :subtitle="$vuetify.lang.t('$vuetify.statisticsDescription')"
                  idName="statistics"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 pa-0 justify-center">
          <v-col cols="11" lg="10" xl="10" md="11" class="white ma-0 py-0">
            <v-row dense class="cts-row-processes-filters py-4 justify-space-between px-2 hidden-md-and-down">

              <v-col cols="12" md="10" class="pa-0">
                <v-row dense class="ma-0">
                  <v-col cols="12" md="2" sm="12" class="px-0 pr-md-2">
                    <v-menu
                        v-model="filter_date_model"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        :min-width="300"
                        offset-y>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          id="statistics-input-date_filter"
                          :value="dateRangeText"
                          background-color="white"
                          class="ma-0 cts-w-100 cts-text-box cts-text-box-fix-placeholder cts-cursor-button"
                          outlined
                          hide-details
                          single-line
                          readonly
                          dense
                          aria-label="Date-range"
                          :placeholder="dateRangeText"
                          clearable
                          @click:clear.prevent="resetFilerDates(true)"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-card>
                        <v-row class="pa-0 ma-0" dense>
                          <v-divider vertical></v-divider>
                          <v-col class="ma-2">
                            <v-date-picker
                              id="statistics-input-date_filter_datepicker"
                              @change="updateStatistics()"
                              v-model="statistics_date_filter_value" class="mx-auto d-block" no-title range
                              first-day-of-week="1"
                              :header-date-format="getMonthFormat"
                              :allowed-dates="allowed_dates"
                              width="300px"
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="px-0 pr-md-2">
                    <v-select single-line outlined dense
                      id="statistics-input-processes_filter"
                      class="ma-0 cts-select-box cts-w-100 cts-box-filter-tooltip"
                      :items="filterProcesses"
                      hide-details
                      v-model="statistics_process_code_filter_value"
                      item-text="name"
                      item-value="code"
                      @change="updateStatistics()"
                      clearable
                      :aria-label="$vuetify.lang.t('$vuetify.transactionsProcessFilter')"
                      :placeholder="$vuetify.lang.t('$vuetify.transactionsProcessFilter')"
                      :menu-props="selectsProps"
                    >
                      <template v-slot:selection="{ item }">
                        <v-tooltip v-if="item.name.length > 20 && windowWidth < 1760" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span class="cts-break-word" v-bind="attrs" v-on="on">{{ item.name }}</span>
                          </template>
                          <span>{{ item.name  }}</span>
                        </v-tooltip>
                        <v-tooltip v-else-if="item.name.length > 26 && windowWidth > 1760" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span class="cts-break-word" v-bind="attrs" v-on="on">{{ item.name }}</span>
                          </template>
                          <span>{{ item.name  }}</span>
                        </v-tooltip>
                        <span v-else>{{ item.name  }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-tooltip v-if="item.name.length > 50" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-content class="cts-break-word" v-bind="attrs" v-on="on">
                              <v-list-item-title>
                                {{item.name}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                          <span>{{ item.name  }}</span>
                        </v-tooltip>
                        <v-list-item-content v-else>
                          <v-list-item-title>
                            {{item.name}}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="px-0 pr-md-2" v-if="filterLinkedGroups && filterLinkedGroups.length != 1">
                    <v-select single-line outlined dense
                      id="statistics-input-group_filter"
                      class="ma-0 cts-select-box cts-w-100 cts-box-filter-tooltip"
                      :items="filterLinkedGroups"
                      v-model="statistics_linked_groups_filter_value"
                      item-text="name"
                      item-value="id"
                      @change="updateStatistics()"
                      hide-details
                      clearable
                      :aria-label="$vuetify.lang.t('$vuetify.transactionsLinkedGroupsFilter')"
                      :placeholder="$vuetify.lang.t('$vuetify.transactionsLinkedGroupsFilter')"
                      :menu-props="selectsProps"
                    >
                      <template v-slot:selection="{ item }">
                        <v-tooltip v-if="item.name.length > 15 && windowWidth < 1760" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span class="cts-break-word" v-bind="attrs" v-on="on">{{ item.name }}</span>
                          </template>
                          <span>{{ item.name  }}</span>
                        </v-tooltip>
                        <v-tooltip v-else-if="item.name.length > 26 && windowWidth > 1760" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span class="cts-break-word" v-bind="attrs" v-on="on">{{ item.name }}</span>
                          </template>
                          <span>{{ item.name  }}</span>
                        </v-tooltip>
                        <span v-else>{{ item.name  }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-tooltip v-if="item.name.length > 41" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-content class="cts-break-word" v-bind="attrs" v-on="on">
                              <v-list-item-title>
                                {{item.name}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                          <span>{{ item.name  }}</span>
                        </v-tooltip>
                        <v-list-item-content v-else>
                          <v-list-item-title>
                            {{item.name}}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense class="cts-row-processes-filters py-4 justify-space-between px-2 hidden-lg-and-up">
              <v-col cols="12">
                <v-row dense class="ma-0 justify-space-between">
                  <a @click="changeStateFiltersOpen()" class="cts-link pt-2">
                    <span v-if="filters_open">
                      <v-icon small color="var(--primary-alternative)" class="mr-2">search_off</v-icon>
                      {{$vuetify.lang.t('$vuetify.hideFilters')}}
                    </span>

                    <span v-else>
                      <v-icon small color="var(--primary-alternative)" class="mr-2">search</v-icon>
                      {{$vuetify.lang.t('$vuetify.advancedFilters')}}
                    </span>
                  </a>
                  <a @click="resetFilters()" class="cts-link pt-2">
                    <span v-if="active_filters">
                      {{$vuetify.lang.t('$vuetify.restartFilters')}}
                      <v-icon small color="var(--primary-alternative)" class="ml-2">cached</v-icon>
                    </span>
                  </a>
                </v-row>
              </v-col>
              <v-expand-transition>
                <v-col cols="12" class="pa-0 pt-2" v-if="filters_open">
                  <v-row dense class="ma-0">
                    <v-col cols="12" class="px-0">
                      <v-menu
                        v-model="filter_date_model_mobile"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        :min-width="300"
                        offset-y
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="dateRangeText"
                            background-color="white"
                            class="ma-0 cts-w-100 cts-text-box cts-text-box-fix-placeholder cts-cursor-button"
                            outlined
                            hide-details
                            single-line
                            readonly
                            dense
                            aria-label="Date-range"
                            :placeholder="dateRangeText"
                            clearable
                            @click:clear.prevent="resetFilerDates(true)"
                            v-on="on"
                          />
                        </template>
                        <v-card>
                          <v-row class="pa-0 ma-0" dense>
                            <v-divider vertical></v-divider>
                            <v-col class="ma-2">
                              <v-date-picker
                                      :allowed-dates="allowed_dates"
                                      v-model="statistics_date_filter_value" class="mx-auto d-block" no-title range
                                      first-day-of-week="1"
                                      width="300px"/>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" class="px-0">
                      <v-autocomplete 
                        single-line
                        outlined
                        dense
                        class="ma-0 cts-select-box cts-w-100"
                        :items="filterProcesses"
                        hide-details
                        v-model="statistics_process_code_filter_value"
                        item-text="name"
                        item-value="code"
                        clearable
                        :aria-label="$vuetify.lang.t('$vuetify.transactionsProcessFilter')"
                        :placeholder="$vuetify.lang.t('$vuetify.transactionsProcessFilter')"
                        :menu-props="selectsProps"
                      />
                    </v-col>
                    <v-col cols="12" class="px-0"  v-if="filterLinkedGroups && filterLinkedGroups.length != 1">
                      <v-autocomplete
                        single-line
                        outlined
                        dense
                        class="ma-0 cts-select-box cts-w-100"
                        :items="filterLinkedGroups"
                        v-model="statistics_linked_groups_filter_value"
                        item-text="name"
                        item-value="id"
                        hide-details
                        clearable
                        :aria-label="$vuetify.lang.t('$vuetify.transactionsLinkedGroupsFilter')"
                        :placeholder="$vuetify.lang.t('$vuetify.transactionsLinkedGroupsFilter')"
                        :menu-props="selectsProps"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense class="ma-0 pt-2">
                    <v-btn class="cts-button-standard-block " block small @click="updateStatistics">
                      {{$vuetify.lang.t('$vuetify.searchs')}}
                      <v-icon small class="float-right">{{const_icons.SEARCH}}</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-expand-transition>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="result" dense class="ma-0 pb-2">
          <v-col cols="12" class="ma-o pa-0 pb-2">
            <v-row dense class="justify-center">
              <v-col
                v-if="active_filters && !filters_open"
                cols="11" xs="11" md="11" lg="10" xl="10"
                class="pl-4 pb-2 cts-font-size-4 cts-color-tertiary"
              >
                <span id="statistics-text-active_filters" class="mr-4">
                  {{$vuetify.lang.t('$vuetify.activeFilters')}}:
                </span>
                <span id="statistics-text-active_filters_date" v-if="statistics_date_filter_value" class="mx-1">
                  {{$vuetify.lang.t('$vuetify.date')}}:
                  <span id="statistics-text-active_filters_date_value" class="font-weight-medium"> {{dateRangeText}}</span>
                </span>
                <span id="statistics-text-active_filters_groups" v-if="statistics_process_code_filter_value" class="mx-1">
                  | {{$vuetify.lang.t('$vuetify.process')}}:
                  <span id="statistics-text-active_filters_groups_value" class="font-weight-medium"> {{filterProcesses.find(p => p.code == statistics_process_code_filter_value).name}}</span>
                </span>
                <span id="statistics-text-active_filters_linked_groups" v-if="statistics_linked_groups_filter_value" class="mx-1">
                  | {{$vuetify.lang.t('$vuetify.group')}}:
                  <span id="statistics-text-active_filters_linked_groups_value" class="font-weight-medium"> {{filterLinkedGroups.find(lg => lg.id == statistics_linked_groups_filter_value).name}}</span>
                </span>
              </v-col>
            </v-row>
            <v-row v-if="result['days'] && totalsChecker" dense class="ma-0 pa-0 justify-center">
              <v-col cols="11" md="10">
                <v-row dense class="ma-0 cts-categories-title pt-4 justify-lg-space-between">
                  <v-col cols="12" lg="4" xl="4" md="4" class="cts-statistic-data-info px-2">
                    <v-row dense class="ma-0 py-2 pt-4">
                      <h1 id="statistics-text-total_transactions_phrase" class="font-weight-regular cts-font-size-2">{{$vuetify.lang.t('$vuetify.totalTransactions')}}:
                        <span class="font-weight-bold"> {{totals.total_count}}</span></h1>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="8" xl="8" md="8" class="cts-statistic-data-info px-2">
                    <h3 id="statistics-text-done_transactions_phrase" class="pt-md-4 cts-font-size-3 font-weight-regular font-italic float-right" v-html="datePhrase"></h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="result['days'] && totalsChecker" dense class="ma-0 pa-0 justify-center">
              <v-col cols="12" lg="10">
                <v-row dense class="ma-0">
                  <v-col cols="12" md="5" class="">
                    <pie-chart
                      v-if="loaded"
                      id="statistics-chart-chart_pie"
                      ref="pie"
                      :dates="statistics_date_filter_value"
                      :colors="colors"
                      :resolution="windowWidth"
                      :chartData="result"
                    />
                  </v-col>
                  <v-col cols="12" md="7" class="">
                    <bar-chart v-if="loaded" id="statistics-chart-bar_chart" ref="bar" :dates="statistics_date_filter_value" :colors="colors" :chartData="result"/>
                  </v-col>
                </v-row>
                <v-row dense class="ma-0 justify-start px-1 pt-4 d-none">
                  <v-col v-for="(process,index) in totals.processes" :key="process.name" cols="6" lg="3" md="3" class="my-2">
                    <a v-if="process.name.length < 25" class="cts-font-size-4 pa-2 d-block" :class="process.selected ? 'cts-statistic-process-selected' : ''"
                       @click="selectProcess(index)">
                      <h4>
                        <v-icon small class="pb-1 mr-2" :color="colors[index]">brightness_1</v-icon><span class="cts-font-size-4">{{process.name}}</span>: <span class="font-weight-bold cts-color-darken cts-font-size-3">{{process.total_count}}</span>
                      </h4>
                    </a>

                    <a v-else class="cts-font-size-4 pa-2 d-block" :class="process.selected ? 'cts-statistic-process-selected' : ''"
                       @click="selectProcess(index)">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <h4 v-bind="attrs" v-on="on">
                            <v-row dense class="ma-0 pa-0">
                              <v-col class="ma-0 pa-0 cts-break-word" :cols="process.total_count.toString().length > 4 ? 9 : 10">
                                <v-icon x-small class="pb-1 mr-2" :color="colors[index]">brightness_1</v-icon>{{process.name}}:
                              </v-col>
                              <v-col class="ma-0 pa-0" :cols="process.total_count.toString().length > 4 ? 3 : 2">
                                <span class="font-weight-bold cts-color-darken cts-font-size-3 float-right">{{process.total_count}}</span>
                              </v-col>
                            </v-row>
                          </h4>
                        </template>
                        <span>{{process.name}}:</span><br/>
                        <span>{{process.total_count}}</span>
                      </v-tooltip>
                    </a>

                  </v-col>
                </v-row>
              </v-col>

            </v-row>
            <v-row v-else dense class="ma-0 pa-0 justify-center">
              <v-col cols="11" lg="8" md="10" class="pt-4">
                <h2 class="ma-1 cts-color-tertiary font-weight-regular text-center">
                  {{$vuetify.lang.t('$vuetify.statisticsNoResults')}}</h2>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-scroll-y-transition>
      <v-col v-if="!result" cols="12" class="ma-0 pa-0">
        <loading-container/>
      </v-col>
  </v-container>
</template>

<script>
  import const_icons from '@/constants/icons'
  import ViewTitle from "../components/structures/viewTitle"
  import { getStatistics, getStatisticsSearchFilters } from "@/services/statisticsServices"
  import BarChart from "../components/sections/statistics/barChart"
  import PieChart from "../components/sections/statistics/pieChart"
  import LoadingContainer from "../components/structures/loadingContainer";
  import { allowed_dates_calendar } from "@/util/utils"
  import dayjs from "dayjs";
  import {mapGetters} from "vuex";
  import { NAMESPACE as LOCATION_NAMESPACE, GETTERS as LOCATION_GETTERS } from "@/constants/vuex/location"

  export default {
    name: "Statistics",
    components: {LoadingContainer, PieChart, BarChart, ViewTitle},
    data: function () {

      return {
        transactions: {},
        filters: {},
        const_icons,
        selectsProps: {
          "closeOnClick": false,
          "closeOnContentClick": true,
          "openOnClick": false,
          "maxHeight": 300
        },
        totals: null,
        colors: [
          "#297e65",
          "#796F1B",
          "#305426",
          "#a94e7e",
          "#705a86",
          "#C54D26",
          "#4A7D5B",
          "#4E7588"
        ],
        statistics_date_filter_value: [],
        statistics_process_code_filter_value: null,
        statistics_linked_groups_filter_value: null,
        filter_date_model: false,
        filter_date_model_mobile: false,
        active_filters: false,
        filters_open: false,
        first_date: null,
        second_date: null,
        params_info: null,
        windowWidth: window.innerWidth
      }
    },
    methods: {

      allowed_dates(val) {
        return allowed_dates_calendar(this.statistics_date_filter_value, val)
      },
      updateStatistics() {
        this.filter_date_model = false
        this.filter_date_model_mobile = false

        getStatisticsSearchFilters().then(response => {
          this.filters = response.data
        })

        this.first_date = this.statistics_date_filter_value[0]
        if (this.statistics_date_filter_value[1]) {
          this.second_date = this.statistics_date_filter_value[1]
        } else {
          this.second_date = null
        }
        let filters = {
          linked_groups: this.statistics_linked_groups_filter_value,
          process_code: this.statistics_process_code_filter_value,
          creation_timestamp: this.statistics_date_filter_value
        }

        this.loaded = false
        getStatistics(filters).then((response) => {
          this.transactions = response.data
          this.loaded = true
        })

        if (this.statistics_process_code_filter_value || this.statistics_linked_groups_filter_value || !this.isLastWeekDateFilter(this.statistics_date_filter_value)) {
          this.active_filters = true
        } else {
          this.active_filters = false

        }
        this.filters_open = false

      },
      isLastWeekDateFilter(current_filter) {
        return JSON.stringify(current_filter) === JSON.stringify(this.lastWeekDateFilter);

      },
      changeStateFiltersOpen() {
        if (this.filters_open) {
          this.filters_open = false
        } else {
          this.filters_open = true
        }
      },
      resetFilters() {
        this.active_filters = false
        this.statistics_process_code_filter_value = null
        this.statistics_linked_groups_filter_value = null
        this.resetFilerDates(true)
      },
      selectProcess(index) {
        let pie = this.$refs.pie.$refs.chart
        let bar = this.$refs.bar.$refs.chart

        if (this.totals.processes[index].selected) {
          this.totals.processes[index].selected = false
        } else {
          this.totals.processes[index].selected = true
        }


        this.totals.processes.forEach(process => {
          pie.dispatchAction({type: 'legendUnSelect', name: process.name})
          bar.dispatchAction({type: 'legendUnSelect', name: process.name})

          if (process.selected) {
            pie.dispatchAction({type: 'legendSelect', name: process.name})
            bar.dispatchAction({type: 'legendSelect', name: process.name})
          }
        })
      },
      fillTotals(totals) {
        this.totals = totals
      },
      resetFilerDates(reload) {
        this.statistics_date_filter_value = []
        let date = new Date()
        date.setDate(date.getDate() - 7)
        this.statistics_date_filter_value.push(`${date.getFullYear()}-${((date.getMonth() + 1).toString().padStart(2, "0"))}-${date.getDate().toString().padStart(2, "0")}`)

        date.setDate(date.getDate() + 7)
        this.statistics_date_filter_value.push(`${date.getFullYear()}-${((date.getMonth() + 1).toString().padStart(2, "0"))}-${date.getDate().toString().padStart(2, "0")}`)

        this.lastWeekDateFilter = this.statistics_date_filter_value
        if (reload) {
          this.updateStatistics()
        }

      },
      onResize() {
        this.windowWidth = window.innerWidth
      },
      // getMonthFormat(e){
      //   const date = new Date(e.split("-")[0], e.split("-")[1] - 1, 5)
      //
      //   return dayjs(date).locale(this.currentLocation.code).format("MMMM YYYY").toLowerCase()
      // },

      getMonthFormat(e) {
        const date = new Date(e.split("-")[0], e.split("-")[1] - 1, 5)

        const res = dayjs(date).locale(this.currentLocation.code).format("MMMM YYYY").toLowerCase()
        return res === "invalid date" ? "" : res
      }
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
    },
    created() {
      this.loaded = false
      this.resetFilerDates(false)

      this.updateStatistics()
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
    computed: {
      ...mapGetters({
        currentLocation: `${LOCATION_NAMESPACE}/${LOCATION_GETTERS.G_CURRENT_LOCATION}`,
      }),
      dateRangeText() {
        let result = []

        this.statistics_date_filter_value.forEach(d => {
          result.push(`${d.substr(8, 2)}/${d.substr(5, 2)}`)
        })

        return result.join(' ~ ')
      },

      filterProcesses() {
        return this.filters.processes ? this.filters.processes : []
      },

      filterLinkedGroups() {
        return this.filters.linked_groups ? this.filters.linked_groups :[]
      },

      result() {
        return this.transactions
      },

      totalsChecker() {
        let totals = null
        if (this.result) {
          totals = {
            total_count: this.result['total_count'],
            processes: []
          }

          Object.values(this.result['days']).forEach(day_value => {
            Object.entries(day_value.processes).forEach(([process_name, process_value]) => {

              if (!totals.processes.find(ds => ds.name == process_value.name)) {
                let total_process = 0
                Object.values(this.result['days']).forEach((day_value) => {
                  if (day_value.processes[process_name]) {
                    total_process = total_process + day_value.processes[process_name].total_count
                  }
                })

                let process = {
                  name: process_value.name,
                  total_count: total_process,
                  selected: true,
                }
                totals.processes.push(process)
              }
            })
          })

        }

        this.fillTotals(totals)
        return totals
      },

      datePhrase() {
        let phrase = this.$vuetify.lang.t('$vuetify.statisticsTransactionsOneTitle')
        
        if (this.second_date) {
          phrase = this.$vuetify.lang.t('$vuetify.statisticsTransactionsMultipleTitle')
        }

        if (this.first_date) {
          phrase = phrase.replace('{start_month}', dayjs(this.first_date).locale(this.currentLocation.code).format('MMMM'))
          phrase = phrase.replace('{start_day}', dayjs(this.first_date).locale(this.currentLocation.code).format('DD'))

          if (this.second_date) {
            phrase = phrase.replace('{end_month}', dayjs(this.second_date).locale(this.currentLocation.code).format('MMMM'))
            phrase = phrase.replace('{end_day}', dayjs(this.second_date).locale(this.currentLocation.code).format('DD'))
          }
        }

        return phrase
      }
    },
  }
</script>
