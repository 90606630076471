var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.list.length > 1)?_c('div',[(_vm.list.length > 1)?_c('draggable',{model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},_vm._l((_vm.list),function(doc,_index){return _c('v-row',{key:doc.fileName,staticClass:"ma-0 my-2",attrs:{"dense":"","justify":"space-between"}},[_c('v-col',{staticClass:"pr-5"},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"cts-button-tertiary-custom"},[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_index + 1)+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"10"}},[(_vm.getExtensionFromName(doc) == 'pdf')?_c('v-row',{staticClass:"cts-button-tertiary-block pa-0",attrs:{"dense":""}},[_c('v-btn',{staticClass:"cts-button-tertiary-block px-0 cts-preview-pdf-mobile-button float-left cts-w-100 cts-break-word",attrs:{"small":"","block":"","outlined":""},on:{"click":function($event){return _vm.handleOpenPDFpreview(doc)}}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"pr-7 text-center",attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"text-left",attrs:{"small":""}},[_vm._v("menu")])],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"8"}},[(doc.fileName.length > 28)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5"},'span',attrs,false),on),[_vm._v(" "+_vm._s(doc.fileName.slice(0, 28))+" "),(doc.fileName.length > 28)?_c('span',[_vm._v("...")]):_vm._e()])]}}],null,true)},[_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(_vm._s(doc.fileName))])]):_c('span',{staticClass:"cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5",attrs:{"id":("data_preview-detail_table-body-" + _vm.index + "-document_name_text")}},[_vm._v(_vm._s(doc.fileName))])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"float-right",attrs:{"id":("data_preview-detail_table-body-" + _vm.index + "-document_previsualization_icon")}},[_vm._v(" "+_vm._s(_vm.const_icons.PREVISUALIZATION)+" ")])],1)],1)],1)],1):_c('v-row',{attrs:{"dense":""}},[_c('v-btn',{staticClass:"cts-button-tertiary-block px-0 cts-preview-pdf-mobile-button float-left cts-w-100 cts-break-word",attrs:{"small":"","block":"","outlined":""},on:{"click":function($event){return _vm.openHTMLpreview(doc)}}},[_c('v-icon',[_vm._v("menu")]),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"10"}},[(doc.fileName.length > 26)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5"},'span',attrs,false),on),[_vm._v(_vm._s(doc.fileName))])]}}],null,true)},[_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(_vm._s(doc.fileName))])]):_c('span',{staticClass:"cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5"},[_vm._v(_vm._s(doc.fileName.toUpperCase()))])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.const_icons.PREVISUALIZATION)+" ")])],1)],1)],1)],1)],1)],1)],1)}),1):_vm._e(),_c('div',{staticClass:"text-right cts-font-size-4"},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.documentOrder')))])],1):_c('div',_vm._l((_vm.list),function(doc){return _c('v-row',{key:doc.fileName,staticClass:"ma-0 my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.getExtensionFromName(doc) == 'pdf')?_c('v-row',{staticClass:"cts-button-tertiary-block pa-0",attrs:{"dense":""}},[_c('v-btn',{staticClass:"cts-button-tertiary-block px-0 cts-preview-pdf-mobile-button float-left cts-w-100 cts-break-word",attrs:{"small":"","block":"","outlined":""},on:{"click":function($event){return _vm.handleOpenPDFpreview(doc)}}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"10"}},[(doc.fileName.length > 28)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5"},'span',attrs,false),on),[_vm._v(_vm._s(doc.fileName))])]}}],null,true)},[_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(_vm._s(doc.fileName))])]):_c('span',{staticClass:"cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5",attrs:{"id":("data_preview-detail_table-body-" + _vm.index + "-document_name_text")}},[_vm._v(_vm._s(doc.fileName))])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"float-right",attrs:{"id":("data_preview-detail_table-body-" + _vm.index + "-document_previsualization_icon")}},[_vm._v(" "+_vm._s(_vm.const_icons.PREVISUALIZATION)+" ")])],1)],1)],1)],1):_c('v-row',{attrs:{"dense":""}},[_c('v-btn',{staticClass:"cts-button-tertiary-block px-0 cts-preview-pdf-mobile-button float-left cts-w-100 cts-break-word",attrs:{"small":"","block":"","outlined":""},on:{"click":function($event){return _vm.openHTMLpreview(doc)}}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"10"}},[(doc.fileName.length > 26)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5"},'span',attrs,false),on),[_vm._v(_vm._s(doc.fileName))])]}}],null,true)},[_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(_vm._s(doc.fileName))])]):_c('span',{staticClass:"cts-no-letter-spacing d-block cts-break-word font-weight-medium cts-font-size-5"},[_vm._v(_vm._s(doc.fileName.toUpperCase()))])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.const_icons.PREVISUALIZATION)+" ")])],1)],1)],1)],1)],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }