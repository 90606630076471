import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import AppScss from '../assets/styles/App.scss';
import store from "@/store";
import { NAMESPACE as LOCATION_NAMESPACE, GETTERS as LOCATION_GETTERS } from "@/constants/vuex/location"

Vue.use(Vuetify)

const vuetify = new Vuetify({
  AppScss,
  icons: {
    iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: { disable: true },
  lang: {
    locales: store.getters[`${LOCATION_NAMESPACE}/${LOCATION_GETTERS.G_LOCALES}`],
    current: store.getters[`${LOCATION_NAMESPACE}/${LOCATION_GETTERS.G_CURRENT_LOCATION}`].code,
  }
})

export default vuetify
