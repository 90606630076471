import { render, staticRenderFns } from "./UsersViewer.vue?vue&type=template&id=5379643c&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&xmlns%3Av-scroll=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml"
import script from "./UsersViewer.vue?vue&type=script&lang=js"
export * from "./UsersViewer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCol,VContainer,VRow,VScrollYTransition})
