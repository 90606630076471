export default {
    badge: 'Placa',
    close: 'Cerrar',
    dataIterator: {
        noResultsText: 'Ningún elemento coincide con la búsqueda',
        loadingText: 'Cargando...'
    },
    dataTable: {
        itemsPerPageText: 'Filas por página:',
        ariaLabel: {
            sortDescending: ': Orden descendente. Pulse para quitar orden.',
            sortAscending: ': Orden ascendente. Pulse para ordenar descendente.',
            sortNone: ': Sin ordenar. Pulse para ordenar ascendente.',
            activateAscending: 'Activar ascenso',
            activateDescending: 'Activar descenso',
            activateNone: 'Desactivar todo'
        },
        sortBy: 'Ordenado por'

    },
    dataFooter: {
        itemsPerPageText: 'Elementos por página:',
        itemsPerPageAll: 'Todos',
        nextPage: 'Página siguiente',
        prevPage: 'Página anterior',
        firstPage: 'Primer página',
        lastPage: 'Última página',
        pageText: '{0}-{1} de {2}'
    },
    datePicker: {
        itemsSelected: '{0} seleccionados',
        prevMonthAriaLabel: 'mes anterior',
        nextMonthAriaLabel: 'mes siguiente'
    },
    noDataText: 'No hay datos disponibles',
    carousel: {
        prev: 'Visual anterior',
        next: 'Visual siguiente',
        ariaLabel: {
            delimiter: 'Carousel slide {0} of {1}'
        }
    },
    calendar: {
        moreEvents: '{0} más'
    },
    fileInput: {
        counter: '{0} archivos',
        counterSize: '{0} archivos ({1} en total)'
    },
    timePicker: {
        am: 'AM',
        pm: 'PM'
    },

    home: 'Inicio',
    templates: 'Flujos',
    accounts: 'Cuentas',
    processes: 'Procesos',
    transactions: 'Transacciones',
    statistics: 'Estadísticas',
    support: 'Soporte',
    contacts: 'Contactos',
    administration: 'Administración',
    search: 'Búsqueda',
    appearance: 'Estilos',
    users: 'Usuarios',
    alastria: 'Alastria',
    billing: 'Facturación',
    account: 'Gestionar mi cuenta',
    admin: 'Administración',
    demos: 'Demos',
    selectedSubaccount: 'Subcuenta seleccionada',
    withoutSubaccount: 'Sin subcuentas',
    groups: 'grupos',
    filterDate: 'Filtro de fecha',

    subaccount: 'Subcuenta',
    subaccounts: 'Subcuentas',
    costCenters: 'Centros de coste',
    subaccountsAssigned: 'Subcuentas asignadas',
    defaultCostCenter: 'Centro de coste por defecto',
    selectedCostCenter: 'Centro de coste seleccionado',
    availableCostCenters: 'Centros de coste disponibles',
    changeDefaultCostCenter: 'Cambiar centro de coste por defecto',
    costCenterDialogText: 'Si lo desea puede cambiar el centro de coste seleccionado para la transacción. Esto no modificará el centro de coste por defecto, únicamente se usará de forma puntual para esta transacción',
    costCenterDialogTextAccount: 'Si lo desea puede cambiar el centro de coste por defecto. Esta acción establecerá el centro de coste seleccionado como predeterminado',
    costCenterSelectedTitle: 'Centro de coste seleccionado',
    activeSubaccount: 'Subcuenta activa',
    user: 'Usuario',
    userData: 'Datos del usuario',
    userCECOS: 'Centros de coste',
    userCECO: 'Centro de coste',
    userCodeCECO: 'Código',
    userCECOPrimary: 'Por defecto',
    userCECOCodeTitle: 'Código de centro de coste',
    password: 'Contraseña',
    passwordchange: 'Cambiar contraseña',
    oldpassword: 'Contraseña actual',
    newpassword: 'Nueva contraseña',
    updatePassword: 'Actualizar contraseña',
    repeatpassword: 'Repetir contraseña',
    passwordrequirements: 'La contraseña debe tener al menos 8 caracteres y cumplir como mínimo 3 de las reglas adicionales:',
    passwordMinOneLowercase: 'Mínimo 1 letra minúscula.',
    passwordMinOneUppercase: 'Mínimo 1 letra mayúscula.',
    passwordMinOneNumber: 'Mínimo 1 número.',
    passwordMinOneSpecialChar: 'Mínimo 1 carácter especial.',
    passwordMinEightChars: 'Mínimo 8 caracteres.',
    passwordCompares: 'Comparación de contraseña requerida.',
    passwordFieldError: 'Error en el campo, consultar reglas.',
    passwordrequirementsMoreThanEightChars: 'La contraseña debe tener al menos 8 caracteres',
    notequalpasswords: 'No coinciden las contraseñas',
    samepasswordthanolder: 'La nueva contraseña es igual que la anterior',
    emptyOldPassword: 'No ha introducido la contraseña anterior',
    role: 'Rol',
    lastConnection: 'Última conexión',
    languageSelector: 'Seleccione un idioma',
    logout: 'Cerrar sesión',
    others: 'Otros',
    continue: 'Continuar',
    continueWSend: 'Continuar envío',
    sendRecoveryForm: 'Enviar solicitud',
    restartFilters: 'Reiniciar filtros',
    back: 'Volver',
    backToMethods: 'Métodos de recuperación',
    moreInfo: 'Más información',
    activateuser: 'Activar usuario',
    exit: 'Salir',
    platformVersion: 'Versión de la plataforma',
    termsAndConditionsCompliance: 'Términos y Condiciones',
    privacyPoliciesCompliance: 'Política de Protección de Datos',
    cookiesPoliciesCompliance: 'Política de Cookies',
    cookiesPoliciesCertySign: 'Cookies en CertySign',
    alertSizeFiles: 'Todos los archivos deben pesar como máximo 8Mb',
    login: 'Iniciar sesión',
    noSubaccountsMessage: 'No dispone de subcuentas asignadas',
    telephone: 'Teléfono',
    name: 'Nombre',
    lastName: 'Apellidos',
    email: 'Email',
    omit: 'Omitir',
    id: 'Referencia cliente',
    clear: 'Limpiar',
    add: 'Añadir',
    recipients: 'Destinatarios',
    shipmentData: 'Datos del envío',
    prevAndSend: 'Previsualización y envío',
    previewFileSize: 'El archivo debe pesar como máximo 15Mb',
    rulesFilesTotalSize: 'Todos los archivos deben pesar como máximo {totalSizeLimit}Mb',
    sectionDataFieldsDescription: 'En la parte inferior dispone de los distintos campos necesarios para el envío de la transacción a los destinatarios. Los campos de contacto serán rellenados automáticamente, desde los datos del destinatario',
    sectionDataFieldsNotAditionalFields: 'Este proceso no dispone de campos adicionales, finalice el envío de su transacción desde Previsualización.',
    sectionPreviewSubtitle: 'En la parte inferior puede navegar entre los destinatarios para comprobar individualmente cada envío',
    manual_version: 'Mostrando la última versión del manual de usuario.',

    view_login: 'Iniciar sesión',
    view_transactions: 'Transacciones',
    view_transaction: 'Detalles de transacción',
    view_process: 'Lanzar proceso',
    view_processes: 'Procesos',
    view_statistics: 'Estadísticas',
    view_support: 'Soporte y ayuda',

    view_administration: 'Administración',
    view_groups: 'Grupos',
    view_group: 'Detalles de grupo',
    view_users: 'Usuarios',
    view_account: 'Mi cuenta',

    view_remember_password: 'Recordar contraseña',
    view_change_password: 'Cambiar contraseña',
    view_activate_user: 'Activar usuario',
    view_new_password: 'Nueva contraseña',
    view_contacts: 'Contactos',
    view_user: 'Detalles de usuario',

    view_withoutsubaccounts: '',
    view_forbidden: '',
    view_root: '',
    view_error500: 'Error',


    searchAgain: 'Volver a buscar',
    recipient: 'Destinatario',
    sender: 'Remitente',
    namesender: 'Nombre del remitente',
    subject: 'Asunto',
    emailbody: 'Cuerpo del email',
    resourcespreview: 'Previsualización de recursos',
    attach: 'Adjuntar',
    actions: 'Acciones',
    changePassword: 'Actualizar contraseña',
    sessionClosed: 'Sesión cerrada correctamente',
    noPermissionMessage: 'No tiene permisos para acceder a esta sección',
    download: 'Descargar',
    viewDocument: 'Ver Documento',
    accept: 'Aceptar',
    reject: 'Rechazar',
    change: 'Cambiar',
    addVariables: 'Añadir variable',
    resources: 'Recursos',


    launchDataKo: 'Los datos proporcionados no son válidos para realizar el envío',
    launchDataOk: 'Los datos son correctos, puede proceder al envío',

    addNewRecipient: 'Añadir nuevo destinatario',
    transactionRecipients: 'Destinatarios de la transacción',
    noRecipientsSelected: 'Ningún destinatario seleccionado',
    recipientsSelected: 'Se han seleccionado',
    recipientSelected: 'Se ha seleccionado',
    preview: 'Previsualización',
    summary: 'Resumen',
    transaction_summary: 'Resumen del envío',
    PDFpreview: 'Previsualizar PDF',
    previewing: 'Previsualizando: ',
    notAvailable: 'No disponible',
    hide: 'Ocultar',
    send: 'Enviar',
    showFile: 'Ver fichero',
    error500Message: 'Ha ocurrido un error. Si el problema persiste, puede contactar con soporte a través de ',
    thisForm: 'este formulario',
    error401Message: 'La sesión ha expirado',
    withoutData: 'Sin datos',
    notInformed: 'No informado',


    loginTitleText: 'Iniciar sesión',
    changePasswordTitleText: 'Cambio de contraseña',
    activateUserTitleText: 'Activación de usuario',
    homeWelcomeMessageTitle: 'Bienvenido a CertySign',
    homeWelcomeMessageSubtitle: 'Entorno de gestión de transacciones y flujos de entrega certificada',
    changePasswordUserTitleText: 'Establecer nueva contraseña',
    activateUserSubtitleText: 'Para la activación del usuario es necesario introducir una nueva contraseña',
    changePasswordUserSubtitleText: 'Para establecer una nueva contraseña complete los campos',
    activateUserTitleTextWithoutHash: 'URL no válida',
    activateUserSubtitleTextWithoutHash: 'La URL que está utilizando no es válida. Si lo desea, puede solicitar una nueva URL a través de la opción "He olvidado la contraseña" de la pantalla de login.',

    goToLogin: 'Inicio de sesión',
    activeFilters: 'Filtros activos',
    rememberPassword: '¿Ha olvidado su contraseña?',
    rememberPasswordTitleText: 'Recuperar contraseña',
    supportContact: 'Contacto con soporte',
    rememberPasswordSubTitleText: 'Seleccione un método para recuperar la contraseña',
    rememberPasswordEmailResponse: 'Si el usuario proporcionado se encuentra dado de alta, recibirá un email con las instrucciones para el cambio de contraseña',
    rememberPasswordWebResponse: 'Si los datos introducidos son correctos, nuestro equipo de soporte contactará con usted por email y le proporcionará las instrucciones para el cambio de contraseña',
    rememberPasswordPhoneResponse: 'Si los datos introducidos son correctos, nuestro equipo de soporte contactará con usted por teléfono y le proporcionará las instrucciones para el cambio de contraseña',
    expiredPasswordResponse: 'Su contraseña ha expirado, en breve, recibirá un email con las instrucciones para establecer una nueva contraseña',
    rememberPasswordSupportEmail: 'Email de recuperación',
    rememberPasswordSupportEmailSubtitle: 'Le enviaremos un email desde el que podrá recuperar su contraseña',
    rememberPasswordSupportEmailText: 'Por favor rellene el formulario con su email registrado en CertySign',
    rememberPasswordSupportWeb: 'Soporte vía web',
    rememberPasswordSupportWebSubtitle: 'Enviar un email al equipo de soporte técnico de CertySign',
    rememberPasswordSupportWebText: 'Rellene los datos y el equipo de soporte técnico le responderá a la mayor brevedad.',
    emailSupportServiceHours: 'Horarios de atención:',
    rememberPasswordSupportPhone: 'Soporte telefónico',
    rememberPasswordSupportPhoneSubtitle: 'Llamada telefónica al equipo de soporte técnico de CertySign',
    rememberPasswordSupportPhoneText: 'Rellene los datos y el equipo de soporte técnico le llamará a la mayor brevedad.',
    phoneSupportServiceHours: 'Horario de soporte telefónico:',
    rememberPasswordNotSupportBySchedule: 'Opción deshabilitada por estar fuera del horario',
    rememberPasswordMondayToFriday: 'Lunes a Viernes:',
    rememberPasswordSaturdaySundayAndOthers: 'Sábados, Domingos y festivos:',
    rememberPasswordNotIncludedDays: '(festivos no incluidos)',
    supportFinalSendMessage: 'Se ha enviado su solicitud a nuestro departamento de Soporte',
    goBackToMethods: 'Seleccionar otro método',
    rememberPasswordContactName: 'Nombre y apellidos',
    goToC2C: 'Solicitar llamada',
    messageDescription: 'Mensaje',
    company: 'Empresa',
    legalCheckboxText: 'Acepto las Condiciones de uso de la página y la política de protección de datos',
    selectHelps: '¿Cómo le podemos ayudar?',
    selectHelpsTooltip: 'Soporte y ayuda',
    helpManual: 'Ver manual',
    helpGuide: 'Ver guía rápida',
    helpSupport: 'Contactar con soporte',
    helpSupportPhonePreffered: 'Contactar preferentemente por teléfono',
    helpSupportPhoneSchedule: 'Horario de atención telefónica, laborables',

    requiredEmail: 'Email requerido',
    requiredPhone: 'Teléfono requerido',
    requiredName: 'Nombre requerido',
    requiredEmailFormat: 'Formato de email incorrecto',
    requiredPhoneFormat: 'Formato de teléfono incorrecto',
    requiredTextarea: 'Mensaje requerido',


    tag_signature: 'Firma electrónica',
    tag_email: 'Email',
    tag_sms: 'Sms',
    tag_cert_email: 'Email certificado',
    tag_landing: 'Página web',
    tag_biometric_signature: 'Firma biométrica',
    tag_postal: 'Postal',

    processesViewListTitle: 'Procesos',
    processesViewListDescription: 'Listado con todos los procesos disponibles en CertySign',

    tags: 'Etiquetas',
    searchs: 'Buscar',
    processInfo: 'Información del proceso',
    processesCategoriesFilter: 'Filtro por categorías',
    processesTagFilter: 'Filtro por etiquetas',
    processesTagOthers: 'otros',
    processesSearch: 'Buscar procesos',
    searchTransactions: 'Consultar transacciones',
    searchTransaction: 'Consultar transacción',
    deleteFilters: 'Borrar filtros',
    withoutResults: 'Sin resultados',
    withoutProcessResults: 'No dispone de procesos para su lanzamiento según sus permisos o los criterios de búsqueda establecidos',
    allProcesses: 'Todos los procesos',
    emailNotValid: 'El email no es válido',
    phoneNotValid: 'El teléfono no es válido',
    phoneAtLeastNineChars: 'El teléfono debe tener al menos 9 números',
    doesntExist: 'No existe',
    dontExist: 'No existen',
    requiredField: 'Campo requerido',
    errorField: 'Campo erróneo',
    numberField: 'Campo numérico',
    fiveCharsField: 'Campo debe tener 5 caracteres',
    CPvalidField: 'CP no corresponde con provincia',
    delete: 'Eliminar',
    save: 'Guardar',
    file: 'Fichero',
    loading: 'Cargando',

    countrySelector: 'Seleccione un país',
    provinceSelector: 'Seleccione una provincia',
    andNumberMore: 'y {count} más',
    selectFile: 'Seleccione un fichero',
    selectFileHtml: 'Seleccione un fichero html',
    selectFiles: 'Seleccione archivos',

    allTransactions: 'Todas las transacciones',
    transactionsPerPage: 'Transacciones por página',
    transactionsSend: 'Se han enviado',
    transactionslower: 'transacciones',
    transactionWithoutId: 'Asignación de ID pendiente',
    withoutCertificated: 'Certificado no disponible',
    filter: 'Filtrar',
    transactionsProcessFilter: 'Filtro por proceso',
    processDataPreviewText: 'A continuación puede encontrar el resumen de la nueva transacción, incluyendo el número de envíos necesarios y el centro de coste asociado',
    processDataPreviewTextWithoutCC: 'A continuación puede encontrar el resumen de la nueva transacción, el cual indica el número necesario de envíos',
    transactionsLinkedGroupsFilter: 'Filtro por grupo',
    transactionsDateFilter: 'Filtro por fecha',
    numberOfTransactions: 'Número de envíos',
    associatedCECO: 'Centro de coste asociado',
    transactionsCategoryFilter: 'Filtro por categoría',
    transactionsStatusFilter: 'Filtro por estado',
    transactionState: 'Estado de la transacción',
    transactionSummary: 'Resumen de la transacción',
    transactionGeneralData: 'Información de la transacción',
    transactionData: 'Datos de la transacción',
    files: 'Ficheros',
    transactionContact: 'Datos del contacto',
    transaction: 'Transacción',
    transactionEvents: 'Eventos certificables de la transacción',
    transactionWithoutEvents: 'No hay eventos registrados para la transacción',
    transactionProgress: 'Progreso de la transacción',
    transactionSubtitle: 'Información del estado de la transacción',
    transactionDetails: 'Detalles de la transacción',
    transactionEventInfo: 'Información del evento',
    eventMeaningPrepend: '¿Qué significa el evento ',
    eventMeaningAppend: '?',
    eventMeaning: '¿Qué significa este evento?',
    transactionLaunchedDate: 'Fecha de creación',
    transactionLastEvent: 'Último evento recibido',
    transactionLastEventDate: 'Fecha de último evento',
    transactionContactName: 'Nombre y apellidos',
    transactionContactMobilephone: 'Teléfono móvil',
    transactionContactEmail: 'Email',
    transactionContactID: 'ID',
    advancedFilters: 'Filtros avanzados',
    group: 'Grupo',
    date: 'Fecha',
    hideFilters: 'Ocultar filtros',
    processName: 'Nombre del proceso',
    usernameTransactionDetail: 'Usuario remitente',
    recipientsNumber: 'Número de destinatarios',
    invalidTransaction: 'Datos inválidos',
    dataTableShowRecipients: 'Visualizando envío <span class=\'font-weight-medium\'>:page:</span> de <span class=\'font-weight-medium\'>{total}</span>',

    transactionRedirectToProcesses: 'Ir a procesos',
    transactionRedirectToTransactions: 'Ir a transacciones',
    transactionRedirectToNewSend: 'Nuevo envío',
    transactionProcessed: 'Transacción procesada con éxito',
    transactionsProcessed: 'Transacciones procesadas con éxito',
    transactionError: 'Transacción procesada con inválidos',
    transactionsError: 'Transacciones procesadas con inválidos',
    transactionProcessing: 'Procesando transacción...',
    transactionLaunchedMessage: 'La transacción ha sido procesada con éxito, a medida que sean procesados los envíos, serán visibles en la sección de',
    transactionsLaunchedMessage: 'Las transacciones han sido procesadas con éxito, a medida que sean procesados los envíos, serán visibles en la sección de',
    transactionDownloadFile: 'Ver certificado',
    transactionProcessingMessage: 'El sistema está procesando la transacción, por favor, espere unos instantes',
    transactionsProcessingMessage: 'El sistema está procesando las transacciones, por favor, espere unos instantes',
    transactionsErrorMessage: 'Durante el proceso de las transacciones de los distintos destinatarios, se han detectado datos inválidos en al menos una transacción. A continuación dispone del listado completo de transacciones del envío',
    transactionErrorMessage: 'Durante el proceso de la transacción se han detectado datos inválidos',
    recoverProcessQuestion: 'Si comienza un proceso nuevo, perderá los avances',
    recoverProcessQuestionAppend: '¿Desea iniciar un nuevo proceso?',
    statisticsTransactionsMultipleTitle: 'Transacciones realizadas entre los días <span class="font-weight-bold">{start_day} de {start_month}</span> y <span class="font-weight-bold">{end_day} de {end_month}</span>',
    statisticsTransactionsOneTitle: 'Transacciones realizadas el día {start_day} de {start_month}',
    statisticsTotalTransactionsTitle: 'Totales por tipo de transacción',
    statisticsNoResults: 'No hay resultados que coincidan con los filtros indicados',
    statisticsProcessTitle: 'Proceso:',
    total: 'Total',
    totalTransactions: 'Transacciones totales',
    totalTransactionsByProcesses: 'Desglose de transacciones por procesos',


    accountDescription: 'Configuración de cuenta',
    accountTitle: 'Cuenta',

    contactsDescription: 'Listado y gestión de contactos',
    contactsTitle: 'Contactos',

    transactionsDescription: 'Listado y detalles de transacciones',
    transactionsTitle: 'Transacciones',

    statisticsDescription: 'Análisis gráfico de sus transacciones',
    statisticsTitle: 'Estadísticas',

    supportDescription: 'En esta sección encontrará ayuda a través de soporte técnico o el manual de usuario',

    supportManualDescription: 'En este apartado puede consultar la documentación relacionada con el manejo de la plataforma',
    supportManualTitle: 'Consultar manual y guía',
    supportManualTitleOnlyManual: 'Consultar manual',
    supportManualTitleOnlyGuide: 'Consultar guía',

    administrationDescription: 'Administración de cuentas de la plataforma',
    administrationTitle: 'Administración',

    userActive: 'Usuario activo',
    userInactive: 'Usuario desactivado',
    userStateTitle: 'Estado del usuario',
    groupsTitle: 'Grupos de permisos',
    groupsDescription: 'Gestión de los grupos de la cuenta. Configuración de parámetros y permisos de cada grupo.',
    groupsAssignation: 'Grupo asignado',
    groupInheritFrom: 'Heredar permisos de',
    groupDataTitle: 'Información del grupo',
    groupUsersPermissionsTitle: 'Permisos, usuarios y procesos',
    groupPermissionsTabsTitle: 'Permisos',
    groupProcessesAndSubaccounts: 'Subcuentas y procesos',
    groupLinked: 'Grupos vinculados',
    groupPermissionsOwnerText: 'Este grupo es el propietario de la cuenta, tiene asignados todos los permisos',
    groupPermissionsOwnerSubaccountProcessesText: 'Este grupo es el propietario de la cuenta, tiene asignadas todas las subcuentas y todos los procesos',
    groupAssociatedProcesses: 'Procesos asociados',
    groupAssociatedSubaccounts: 'Subcuentas asociadas',
    newGroup: 'Nuevo grupo',
    groupName: 'Nombre del grupo',
    editGroup: 'Editando grupo',
    groupDescription: 'Descripción',
    groupMinDaysToChange: 'Días mínimos de vigencia de contraseña',
    groupTabUsersNameTitle: 'Nombre y apellidos',
    groupTabPermissionSectionTitle: 'Secciones',
    groupTabPermissionGroupTitle: 'Grupos',
    groupTabPermissionUsersTitle: 'Usuarios',
    usersSearch: 'Buscar usuarios',
    subaccountWithoutProcesses: 'Subcuenta sin procesos asociados',
    selectCostCenter: 'Seleccione un centro de coste',
    adminGroupInfoDescription: 'En esta sección puede editar la descripción, las políticias de sesión y contraseñas de la plataforma.',
    adminGroupInfoUsers: 'En esta sección puede gestionar los usuarios asociados a este grupo.',
    adminGroupInfoPerms: 'En esta sección puede gestionar los permisos relacionados con las funciones que el usuario puede realizar en la plataforma.',
    adminGroupInfoProcesses: 'En esta sección puede gestionar el acceso del usuario a procesos y subcuentas. Este acceso otorgará permiso de lanzamiento, visualización de transacciones y estadísticas de los procesos asignados.',
    adminGroupInfoLinked: 'En esta sección puede gestionar la supervisión sobre otros grupos. Los grupos que estén habilitados compartirán la información de las transacciones lanzadas, así como las estadísticas con el grupo actual.',


    passwordsQuantity: 'Número de contraseñas almacenadas',
    passwordExpiration: 'Días para la expiración de contraseña',
    loginRetriesQuantity: 'Cantidad de reintentos en login',
    loginRetriesTime: 'Minutos para reintentar en login',
    sessionExpiration: 'Minutos para la expiración de la sesión',
    processAlertExpirationQuestion: '¿Desea continuar?',
    processAlertExpirationPhrase: 'Por motivos de seguridad, la sesión expirará en breves momentos',
    processAlertExpiration: 'Alerta de expiración',
    continueSession: 'Continuar',
    usersTitle: 'Usuarios',
    deactivate: 'Desactivado',
    usersPerPage: 'Usuarios por página',
    usersDescription: 'Gestión de los usuarios de la cuenta',
    userInfoTitle: 'Información del usuario',
    userPermissionTitle: 'Tipo de permiso asignado',
    newUser: 'Nuevo usuario',
    editUser: 'Editando usuario',
    groupTitle: 'Nombre del grupo',
    administrator: 'Administrador',
    groupLinkedHeader: 'Vinculado',


    firmaElectronica: 'Firma electrónica',
    comunicacionCertificada: 'Comunicación certificada',
    publicacionWeb: 'Publicación web',
    groupVisibility: 'Visibilidad de grupo',
    passwordPolicy: 'Política de contraseñas',
    frontalPolicy: 'Política de sesión',
    groupData: 'Datos del grupo',
    groupsPerPage: 'Grupos por página',
    groupsFound: 'Se han encontrado',
    cancel: 'Cancelar',

    description: 'Descripción',

    subaccountChanged: 'Subcuenta cambiada',
    processContinueTitle: 'Tiene un proceso pendiente de envío',
    processContinueSubtitle: '¿Desea recuperar el proceso o iniciar uno nuevo?',
    newProcess: 'Nuevo proceso',
    recover: 'Recuperar',
    newShipping: 'Nuevo envío',
    toContinueFillFields: 'Para continuar rellene correctamente los campos',
    passwordRequired: 'Contraseña requerida',
    userRequired: 'Usuario requerido',

    fieldAlert: 'El campo %%fields%% se rellenará automáticamente desde los destinatarios',
    fieldAlertPlural: 'Los campos %%fields%% se rellenarán automáticamente desde los destinatarios',
    fieldNumberPhone: 'teléfono',
    fieldCountryCode: 'código de país',
    fieldEmail: 'email',
    fieldName: 'nombre',
    fieldSurname: 'apellidos',
    and: 'y',
    certificate: 'Certificado',
    country: 'País',
    continueEditing: 'Editar los datos del envío',

    second: 'segundo',
    seconds: 'segundos',
    nextRefreshAvailableAt: 'Próxima actualización disponible en',
    fastSearch: 'Búsqueda rápida',
    update: 'Actualizar',
    process: 'Proceso',
    transactionId: 'Id de la transacción',
    transactionClientId: 'Referencia de cliente',
    createdDate: 'Creada',
    updatedAt: 'Actualizada',
    state: 'Estado',
    noGroupAssigned: 'Sin grupo seleccionado',

    contact_country_code: 'Código de país',
    contact_email: 'Email',
    contact_id: 'Referencia cliente',
    contact_name: 'Nombre',
    contact_phone: 'Teléfono',
    contact_surname: 'Apellidos',
    contact_complete_name: 'Nombre completo',

    certificateValidDownload: 'Certificado descargado correctamente',
    certificateFailDownload: 'No se ha podido descargar el certificado',
    graphicBarValidDownload: 'Gráfica descargada correctamente',
    graphicBarFailDownload: 'No se ha podido descargar la gráfica',


    //events

    queued: 'La transacción está pendiente de ser procesada.',
    new_communication: 'La transacción se ha generado correctamente.',
    orchestrator_new_communications_communication_received: 'La transacción se ha procesado correctamente.',
    commstracker_generate_url_generated: 'Se ha generado el enlace de la URL acortada.',
    commstracker_generate_url_click: 'Se ha abierto el enlace de la transacción.',
    communication_send_sms_sent: 'Se ha depositado el mensaje de texto en el centro de servicio de envío del proveedor. Este, a su vez, gestionará el envío a su destinatario final.',
    communication_send_sms_not_sent: 'El mensaje de texto no ha podido depositarse en el centro de servicio de envío del proveedor.',
    communication_send_sms_delivered: 'El mensaje de texto se ha entregado al destinatario.',
    communication_send_sms_not_delivered: 'El mensaje de texto no se ha podido entregar al destinatario.',
    generate_otp_code_otp_generated: 'Se ha generado una clave de uso único (OTP) para su uso.',
    generate_certificate_generated: 'Se ha generado el certificado de finalización de la transacción, el cual recoge el contenido de la comunicación y las evidencias asociadas a los eventos ocurridos en el proceso.',
    communication_send_email_delivered: 'El mensaje se ha entregado al proveedor del servicio de correo electrónico (ESP) del destinatario.',
    communication_send_email_complaint: 'El destinatario del mensaje ha marcado el correo electrónico recibido como no deseado.',
    communication_send_email_queued: 'El mensaje de correo electrónico se ha encolado.',
    communication_send_email_deferred: 'El mensaje de correo electrónico se ha encolado y se entregará cuando sea posible.',
    communication_send_email_bounce: 'El mensaje de correo electrónico ha rebotado.',
    communication_send_email_sent: 'Se ha depositado el mensaje de correo electrónico en el servicio de envío del proveedor. Este, a su vez, gestionará el envío a su destinatario final.',
    communication_send_email_sender_not_allowed: 'El mensaje de correo electrónico no ha podido ser enviado pues el remitente no está autorizado.',
    communication_send_email_open: 'El mensaje de correo electrónico ha sido abierto por el destinatario.',
    communication_send_email_click: 'El destinatario del mensaje de correo electrónico ha pulsado sobre un enlace.',
    communication_send_email_block: 'La dirección de correo electrónico del destinatario está en la lista de exclusiones, el mensaje no se ha enviado.',
    communication_send_email_unsubscribe: 'El destinatario del mensaje de correo electrónico desea darse de baja de la lista de distribución.',
    communication_send_email_spam: 'El mensaje de correo electrónico ha sido identificado como no deseado.',
    communication_send_email_hard_blacklisted: 'La dirección de correo electrónico del destinatario está en la lista de exclusiones, el mensaje no se ha enviado.',
    communication_send_email_complaint_blacklisted: 'La dirección de correo electrónico del destinatario está en la lista de quejas, el mensaje no se ha enviado.',
    document_upload_s3_uploaded: 'Se ha almacenado de forma segura el certificado de finalización de la transacción, el cual recoge el contenido de la comunicación y las evidencias asociadas a los eventos ocurridos en el proceso.',
    communication_send_telegram_delivered: 'El mensaje de texto se ha entregado al destinatario.',
    communication_send_telegram_not_delivered: 'El mensaje de texto no ha podido entregarse',
    web_framework_generated: 'Se ha generado la interfaz gráfica web para que el destinatario pueda interactuar.',
    web_framework_solicitud_otp: 'Se ha solicitado una clave de uso único (OTP).',
    web_framework_signable_document_loaded: 'Se ha cargado en la interfaz web un documento para ser firmado.',
    web_framework_otp_simple_signature_ok: 'Se ha firmado la documentación mediante la clave de uso único.',
    web_framework_commited: 'Se ha completado el proceso en la interfaz web.',
    web_framework_document_signed: 'Se ha firmado la documentación.',
    web_framework_form_based_identification: 'Se ha completado el paso de auteticación a través de formulario.',
    web_framework_certificate_based_identification: 'Se ha realizado un acceso con certificado personal de forma satisfactoria',
    web_framework_document_based_identification: 'Se ha identificad oe ne lformulario de forma correcta.',
    web_framework_certificate_based_identification_failed: 'Ha fallado el proceso de identificación mediante certificado.',
    web_framework_document_based_identification_failed: 'Ha fallado el proceso de identificación mediante documento.',
    web_framework_form_based_identification_failed: 'Ha fallado el proceso de autenticación mediante formulario.',
    web_framework_web_framework_expired: 'La transacción ha expirado.',
    web_framework_documentation_downloaded: 'La documentación ha sido descargada por el destinatario.',
    web_framework_electronic_notificacion_rejected: 'La notificación electrónica ha sido rechazada por el destinatario.',
    web_framework_gdpr_formulary_committed: 'El formulario de consentimiento ha sido cumplimentado por el destinatario.',
    web_framework_sepa_formulary_committed: 'El formulario SEPA ha sido cumplimentado por el destinatario.',
    web_framework_requested_otp_code: 'Se ha solicitado una clave de uso único (OTP).',
    web_framework_entered_wrong_otp_code: 'La clave de uso único (OTP) introducida por el destinatario es incorrecta.',
    web_framework_entered_timedout_otp_code: 'La clave de uso único ha expirado.',
    web_framework_entered_correct_otp_code: 'La clave de uso único (OTP) introducida por el destinatario es correcta.',
    web_framework_electronic_notificacion_accepted: 'La notificación electrónica ha sido aceptada por el destinatario.',
    web_framework_signature_rejected: 'La solicitud de firma electrónica ha sido rechazada por el destinatario.',
    web_framework_request_otp_code_too_early: 'La clave de uso único (OTP) ha sido solicitada por el destinatario antes del tiempo estipulado para poder regenerarla',
    web_framework_capture_data_commited_no_response: 'Se ha intentado realizar un envío del formulario sin datos en las respuestas.',
    web_framework_capture_data_commited: 'Se ha realizado el envío de datos correctamente',
    web_framework_document_previewed: 'La documentación ha sido previsualizada por el destinatario.',
    web_framework_document_simply_signed: 'Se ha firmado electrónicamente la documentación.',
    document_upload_alastria_uploaded: 'El hash del certificado de finalización se ha subido al nodo de CertySign en la Red Blockchain de Alastria.',
    publish_downloadable_file_generated: 'Se ha generado la documentación para su publicación en la interfaz web.',
    publish_downloadable_file_open: 'Se ha abierto la documentación para su publicación en la interfaz web.',
    document_sign_signed: 'Se ha firmado el certificado',
    rpc_callback_published: 'Se ha publicado el retorno de la llamada RPC',
    loop_add_added: 'Se ha añadido la transacción al bucle de reintentos.',
    loop_add_tick: 'Se ha realizado un intento adicional',
    loop_add_timeout: 'Se ha agotado el límite de reintentos',
    loop_cancel_canceled: 'El bucle se ha cancelado',
    identification_create_identification_generated: 'Se ha creado el proceso mediante el cual el usuario se identificará en la transacción.',
    identification_create_identification_ok: 'Se ha identificado de forma correcta',
    test_load_processed: 'Se ha procesado la prueba de carga',
    instance_delete_deleted: 'La instancia correspondiente a la transacción ha sido borrada.',
    unzip_file_extracted: 'Se ha descomprimido el fichero.',
    communicalia_consent_accepted: 'El destinatario de la comunicación ha aceptado el consentimiento.',
    communicalia_consent_declined: 'El destinatario de la comunicación ha rechazado el consentimiento.',
    communicalia_consent_no_data: 'El destinatario de la comunicación no ha respondido.',
    robinson_list_accepted: 'El destinatario no se encuentra en la lista de Robinson',
    robinson_list_declined: 'El destinatario se encuentra dado de alta en la lista Robinson',
    robinson_list_no_data: 'El destinatario se encuentra dado de alta en la lista Robinson',
    publish_embeddable_file_generated: 'Se ha generado el fichero embebido para la interfaz web.',
    document_upload_sftp_uploaded: 'Se ha realizado la carga de la documentación a través de SFTP.',
    file_delete_deleted: 'Se ha borrado el archivo.',
    disable_framework_web_framework_web_disabled: 'Se ha desactivado la interfaz web asociada a la trasacción.',
    generate_onespan_transaction_transaction_create: 'Se ha creado la transacción de firma electrónica con OneSpan',
    generate_onespan_transaction_transaction_create_error: 'Ha ocurrido un error al crear la transacción de firma electrónica en OneSpan.',
    generate_onespan_transaction_transaction_complete: 'Se ha completado la transacción de firma electrónica en OneSpan.',
    generate_onespan_transaction_transaction_expire: 'La transacción de firma electrónica en OneSpan ha expirado.',
    generate_onespan_transaction_transaction_decline: 'La transacción de firma electrónica de OneSpan ha sido rechazada.',
    generate_onespan_transaction_email_bounce: 'El mensaje de correo electrónico, correspondiente a la transacción de firma electrónica de OneSpan, ha rebotado.',
    generate_onespan_transaction_transaction_opt_out: 'El destinatario ha optado por no firmar la transacción.',
    generate_onespan_transaction_transaction_delete: 'La transacción de firma electrónica de OneSpan ha sido eliminada.',
    generate_onespan_transaction_transation_desactivate: 'La transacción de firma electrónica de OneSpan ha sido desactivada.',
    generate_onespan_transaction_kba_failure: 'La transacción se ha bloqueado debido a motivos de seguridad en el acceso o proceso.',
    generate_onespan_transaction_transaction_sent: 'La transacción de firma electrónica de OneSpan ha sido enviada.',
    generate_onespan_transaction_transaction_sent_error: 'El envío de la transacción de firma electrónica de OneSpan ha fallado.',
    generate_onespan_transaction_package_trash: 'La transacción se ha movido a la papelera de OneSpan',
    get_onespan_files_files_onespan_generated: 'Se han generado los ficheros necesarios para OneSpan',
    communication_send_postal_generated: 'Se ha generado el envío de la comunicación postal.',
    communication_send_postal_sent_to_factory: 'El envío ha sido cargado en la plataforma comunicación postal.',
    communication_send_postal_printed: 'La documentación asociada al envío postal ha sido impresa.',
    communication_send_postal_pre_admission_requested: 'El envío de la comunicación está siendo pre-registrado telematicamente en la web del operador postal.',
    communication_send_postal_pre_admitted: 'El envío de la comunicación ha sido registrado telemáticamente por el operador postal, previo a su depósito.',
    communication_send_postal_sent_to_logistic_operator: 'El envío de la comunicación se está depositando en el operador postal.',
    communication_send_postal_on_the_way_to_logistic_operator: 'El envío está en camino de la oficina postal más cercana al destinatario de la comunicación.',
    communication_send_postal_received_by_logistic_operator: 'El envío está en la oficina postal más cercana al destinatario de la comunicación.',
    communication_send_postal_not_received_by_logistic_operator: 'El envío de la comunicación no ha llegado a la oficina postal.',
    communication_send_postal_on_delivery: 'El envío de la comunicación postal está en camino.',
    communication_send_postal_delivered_to_recipient: 'El envío de la comunicación postal se ha entregado al destinatario.',
    communication_send_postal_returned_to_postal_office: 'El envío está en la oficina postal y el destinatario de la comunicación tiene el aviso en su buzón',
    communication_send_postal_delivered_into_postal_office: 'El envío de la comunicación se ha entregado al destinatario en la oficina postal.',
    communication_send_postal_generic_delivered: 'El envío de la comunicación postal se ha entregado al destinatario.',
    communication_send_postal_returned: 'Ha sido imposible realizar la entrega del envío y la comunicación postal ha sido devuelta al origen.',
    communication_send_postal_canceled: 'El envío de la comunicación postal ha sido cancelado.',
    communication_send_postal_lost: 'El envío de la comunicación postal se ha extraviado.',
    communication_send_postal_expired: 'El envío de la comunicación postal ha sido devuelto a origen por no haber sido recogido por el destinatario en los 15 días hábiles siguientes al aviso dejado en el buzón.',
    communication_send_postal_rejected: 'El envío de la comunicación postal ha sido rechazado por el destinatario.',
    communication_send_postal_stolen: 'El envío de la comunicación postal ha sido sustraído o robado.',
    communication_send_postal_outside_sicer: 'El envío de la comunicación no está registrado en el operador postal.',
    communication_send_postal_bad_postal_address: 'La dirección postal del envío no es correcta.',
    communication_send_postal_passed_away: 'El destinatario del envío de la comunicación postal ha fallecido.',
    communication_send_postal_other: 'Otros.',
    communication_send_postal_no_information: 'No consta información del envío de la comunicación en el operador postal.',
    communication_send_postal_received_pee: 'Se ha recibido la Prueba Electrónica de Entrega (PEE) del envío.',
    communication_send_postal_certificate_returned: 'Se ha generado el certificado  devolución con la Prueba de Imposibilidad de entrega del operador postal.',
    communication_send_postal_unknown: 'El destinatario del envío de la comunicación es desconocido en la dirección postal indicada.',
    zip_files_path_zipped: 'Se han comprimido uno o varios documentos',
    intromission_api_retries_muted: 'Se han desactivado los reintentos de la transacción',
    intromission_api_retries_unmuted: 'Se han activado los reintentos de la transacción',
    intromission_api_instance_canceled: 'La transacción ha sido cancelada',
    antivirus_filtered: 'El antivirus ha filtrado la transacción debido a que se han subido recursos potencialmente peligrosos.',

    //API messages
    user_operation_error_creating_user: 'Ha ocurrido un error al crear el usuario',
    user_operation_user_already_exists: 'El usuario ya existe',
    user_operation_user_created: 'Usuario creado con éxito',
    user_operation_user_updated: 'Usuario actualizado con éxito',
    user_operation_no_exists: 'El usuario no existe',
    user_operation_password_changed: 'Se ha actualizado la contraseña',

    user_operation_pass_incorrect: 'La combinación de usuario y contraseña es incorrecta',
    user_operation_password_expired: 'La contraseña ha expirado',
    user_operation_logout: 'Sesión cerrada correctamente',
    user_operation_blocked_user: 'El usuario está bloqueado',
    user_operation_wrong_subdomain: 'El nombre de usuario o la contraseña no es correcto',
    user_operation_max_pass_tries: 'Usuario bloqueado, número de intentos excedidos',

    user_operation_deleted: 'Usuario borrado correctamente',
    user_operation_error_deleted: 'Error al borrar el usuario',
    user_has_been_disabled: 'Usuario desactivado',

    user_operation_error_user_not_found: 'Si el email indicado existe, recibirá en breve un email con las indicaciones para cambiar su contraseña',
    user_operation_remember_password_email: 'Si el email indicado existe, recibirá en breve un email con las indicaciones para cambiar su contraseña',
    email_sent_to_support: 'Solicitud enviada correctamente',
    user_operation_error_change_password_min_days: 'No es posible cambiar la contraseña debido a que no han pasado el mínimo de días requerido',
    user_operation_error_passwords_mismatch: 'Las contraseñas no coinciden',
    user_operation_error_password_has_been_used_before: 'La contraseña ha sido usada con anterioridad, elija una nueva',

    user_or_group_no_exists: 'Usuario o grupo no existe',
    user_error_already_active: 'El usuario ya está activo',
    activation_email_resend: 'Se ha reenviado el email de activación',

    group_operation_error_creating_group: 'Ha ocurrido un error al crear el grupo',
    group_operation_group_already_exists: 'El grupo ya existe',
    group_operation_group_created: 'Grupo creado con éxito',
    group_operation_group_updated: 'Grupo :group_name: actualizado con éxito',

    //Terms and conditions

    termsConditionsDPOTitle: 'Delegado de Protección de Datos',
    termsConditionsTitular: 'Titular',
    termsConditionsNIF: 'N.I.F.',
    termsConditionsDomicilioSocial: 'Domicilio Social',
    termsConditionsDatosRegistrales: 'Datos Registrales',
    termsConditionsContacto: 'Contacto',
    termsConditionsDPOEmailTitle: 'Email Customer Comms',
    termsConditionsDPOEmail: 'Correo electrónico del Delegado de Protección de Datos',

    termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (en adelante CCOMMS) en su condición de titular y responsable de la presente APP, en cumplimiento de lo establecido en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, pone a disposición de los usuarios la siguiente información genera de esta APP:',
    termsConditionsObjetoTitulo: 'OBJETO',
    termsConditionsObjetoParrafo1: 'Las presentes Condiciones Generales de Uso (en adelante, las “CGU”) regulan el uso de la Aplicación CertySign (en adelante, la APP) que CCOMMS pone a disposición de los usuarios que accedan al mismo con el fin de proceder a poder realizar la solicitud realizada.',
    termsConditionsObjetoParrafo2: 'Por tanto, las presentes CGU se aplicarán tanto a la actividad promocional y de suministro de información que se efectúa a través de la APP como a la prestación de los servicios ofrecidos en el mismo, de tal manera que las mismas regirán en todo momento tanto para la simple navegación por la APP, como para la contratación de servicios en el marco de la APP, si bien estas últimas actividades adicionalmente, se someterán tanto a las CGU descritas a continuación, como a las Condiciones Generales de Contratación aplicables, y las Condiciones Particulares, que en su caso, pudieran existir.',
    termsConditionsUsuariosTitulo: 'USUARIOS',
    termsConditionsUsuariosParrafo1: 'El acceso a y/o uso de la APP, atribuye a quien lo realiza la condición de usuario, aceptando, desde ese mismo momento, plenamente y sin reservas alguna, las presentes CGU, así como las Condiciones Particulares que, en su caso, complementen, modifiquen o sustituyan las CGU en relación con determinados servicios y contenidos de la APP, obligándose a respetar las restricciones de uso de dicha página establecidas por CCOMMS en cada momento.',
    termsConditionsAccesoAPPTitulo: 'ACCESO A LA APP',
    termsConditionsAccesoAPPParrafo1: 'El acceso y uso de la APP tiene carácter restringido y para poder acceder a la misma será necesario el registro previo del usuario.',
    termsConditionsUsoAPPTitulo: 'USO DE LA APP',
    termsConditionsUsoAPPParrafo1: 'El usuario se compromete a utilizar la APP, sus servicios y contenidos sin contravenir la legislación vigente, la buena fe, los usos generalmente aceptados y el orden público. Asimismo, queda prohibido, el uso de la APP con fines ilícitos o lesivos contra CCOMMS o cualquier tercero, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento de la APP.',
    termsConditionsResposabilidadTitulo: 'RESPONSABILIDAD DEL USUARIO POR DAÑOS Y PERJUICIOS',
    termsConditionsResposabilidadParrafo1: 'El uso de la APP se realizará bajo la única y exclusiva responsabilidad del usuario. Dicha responsabilidad se extenderá al uso, por parte del usuario o de cualquier tercero, de cualesquiera contraseña o similares asignadas para el acceso a la APP o a cualesquiera de sus servicios.',
    termsConditionsResposabilidadParrafo2: 'Sin perjuicio de lo anterior, CCOMMS se reserva el derecho a denegar o inhabilitar en cualquier momento y sin necesidad de aviso previo, el acceso a la APP o al acceso restringido, a aquellos usuarios que incumplan estas CGU, las normas establecidas por CCOMMS o sus colaboradores o puedan perturbar el buen funcionamiento, imagen, credibilidad y/o prestigio de CCOMMS o sus colaboradores.',
    termsConditionsResposabilidadParrafo3: 'En este sentido, CCOMMS se reserva la facultad de cancelar o restringir, el acceso a de la APP a determinados Usuarios, en caso de observar cualesquiera conductas que a juicio de CCOMMS resulten contrarias a estas Condiciones de Uso, la Ley, las normas establecidas por CCOMMS o sus colaboradores o puedan perturbar el buen funcionamiento, imagen, credibilidad y/o prestigio de CCOMMS o sus colaboradores. Asimismo, CCOMMS podrá exigir responsabilidad a los Usuarios por los daños y perjuicios derivados del mal uso de la APP.',
    termsConditionsResposabilidadParrafo4: 'CCOMMS no garantiza la licitud, fiabilidad y utilidad, así como su veracidad o exactitud, de los contenidos que los usuarios transmitan durante la utilización de la APP.',
    termsConditionsResposabilidadParrafo5: 'CCOMMS no controla la utilización que los usuarios hacen de la APP, ni garantiza que lo hagan de forma conforme a las presentes Condiciones Generales.',
    termsConditionsResposabilidadParrafo6: 'CCOMMS no controla ni garantiza la ausencia de virus informáticos en los servicios prestados por terceros a través de la APP que puedan producir alteraciones en su sistema informático (software y hardware) o en los documentos y ficheros electrónicos almacenados en su sistema informático.',
    termsConditionsPropiedadIntelectualTitulo: 'PROPIEDAD INTELECTUAL',
    termsConditionsPropiedadIntelectualParrafo1: 'Cualquier uso o modificación del Material o de los Contenidos para cualquier otro fin distinto del autorizado en las Condiciones Generales será considerado una violación de las leyes internacionales del "copyright", que protegen los derechos de autor.',
    termsConditionsPropiedadIntelectualParrafo2: 'Respecto de los contenidos, se prohíbe:',
    termsConditionsPropiedadIntelectualListaELemento1: 'Su reproducción, copia, distribución, modificación o manipulación, de cualquier forma y con cualquier finalidad, a menos que se cuente con la autorización previa y por escrito de sus legítimos titulares o resulte legalmente permitido.',
    termsConditionsPropiedadIntelectualListaELemento2: 'Cualquier vulneración de los derechos de CCOMMS o de sus legítimos titulares sobre los mismos.',
    termsConditionsPropiedadIntelectualListaELemento3: 'Su utilización para todo tipo de fines comerciales o publicitarios, distintos de los estrictamente permitidos.',
    termsConditionsPropiedadIntelectualParrafo3: 'CCOMMS garantiza que los contenidos, incluidos los de propiedad intelectual, no son de carácter ilícito ni infringen la normativa vigente. Por consiguiente, los contenidos de la APP no tendrán carácter xenófobo, pornográfico, discriminatorio, racista o que de cualquier modo fomente la violencia.',
    termsConditionsPropiedadIntelectualParrafo4: 'Además, CCOMMS adoptará las medidas legales que considere convenientes para impedir cualquier tipo de conducta contraria a la Ley o inmoral.',
    termsConditionsModificacionUnilateralTitulo: 'MODIFICACIÓN UNILATERAL',
    termsConditionsModificacionUnilateralParrafo1: 'CCOMMS podrá modificar unilateralmente y sin previo aviso, siempre que lo considere oportuno, la estructura y diseño de la APP, así como modificar o eliminar, los servicios, los contenidos y las condiciones de acceso y/o uso de la APP.',
    termsConditionsExclusionGarantiasTitulo: 'EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD',
    termsConditionsExclusionGarantiasParrafo1: 'CCOMMS no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran traer causa de:',
    termsConditionsExclusionGarantiasListaELemento1: 'El incumplimiento de la Ley, la moral y las buenas costumbres, así como el orden público, o el uso de los productos y/o servicios ofertados de forma no diligente o incorrecta, o con fines o efectos ilícitos o contrarios a lo establecido en las presentes CGU o en las condiciones aplicables que en su caso resulten de aplicación.',
    termsConditionsExclusionGarantiasListaELemento2: 'Por la adquisición o contratación de los servicios, bienes o productos que puedan ser adquiridos o contratados a terceros a través de un acceso en la APP de CCOMMS, especialmente en aquellos casos en los que el proceso de compra o contratación se realiza directamente en la app del tercero y aunque aparezcan distintivos o un “frame” con los elementos gráficos de la app (Branding).',
    termsConditionsExclusionGarantiasListaELemento3: 'La falta disponibilidad, mantenimiento y efectivo funcionamiento de la APP y/o de sus servicios o contenidos.',
    termsConditionsExclusionGarantiasListaELemento4: 'La falta de utilidad, adecuación o validez de la APP y/o de sus servicios o contenidos para satisfacer necesidades, actividades o resultados concretos o expectativas de los usuarios.',
    termsConditionsExclusionGarantiasListaELemento5: 'La existencia de virus, programas maliciosos o lesivos en los contenidos.',
    termsConditionsExclusionGarantiasListaELemento6: 'La recepción, obtención, almacenamiento, difusión o transmisión, por parte de los usuarios, de los contenidos.',
    termsConditionsExclusionGarantiasListaELemento7: 'La ilicitud o falta de veracidad, exactitud, fiabilidad, pertinencia, actualidad y exhaustividad de los contenidos e informaciones transmitidas o puestos a disposición de los usuarios, incluidos las informaciones y servicios prestados por terceros o por los usuarios a través de la APP.',
    termsConditionsExclusionGarantiasListaELemento8: 'El incumplimiento por parte de terceros de sus obligaciones o compromisos en relación con los servicios prestados a los usuarios a través de la APP.',
    termsConditionsExclusionGarantiasListaELemento9: 'De los contenidos, archivos, informaciones, publicidad, opiniones, conceptos e imágenes que no dependan de la APP, ni sean gestionados por CCOMMS. Tampoco se responsabiliza del mal uso que se realice de los contenidos de la APP, siendo exclusiva responsabilidad de la persona que acceda a ellos o los utilice.',
    termsConditionsExclusionGarantiasListaELemento10: 'Del uso indebido de las calves de acceso de los usuarios para el acceso a los contenidos del servicio de la APP que los requieran y de las consecuencias derivadas de cualquier naturaleza del mal uso por los usuarios, su pérdida u olvido, y su uso indebido por terceros no autorizados.',
    termsConditionsExclusionGarantiasListaELemento11: 'La incapacidad de cualquier usuario o la suplantación de la personalidad de un tercero efectuada por el usuario.',
    termsConditionsExclusionGarantiasListaELemento12: 'De los contenidos facilitados por los usuarios.',
    termsConditionsProteccionDatosTitulo: 'PROTECCIÓN DE DATOS',
    termsConditionsProteccionDatosParrafo1: 'Puede consultar la información detallada sobre Protección de Datos en nuestra página de',
    termsConditionsLeyJurisdiccionTitulo: 'LEY APLICABLE Y JURISDCCIÓN',
    termsConditionsLeyJurisdiccionParrafo1: 'Todas las Condiciones, Generales y Particulares, insertadas en la presente APP se rigen por la normativa española. Todos los conflictos, controversias o situaciones derivadas de las Condiciones Generales y particulares se someterán a los Juzgados y Tribunales de la Villa de Madrid, renunciando expresamente las partes a su fuero.',
    termsConditionsProteccionDatosTitle: 'Consultar información de Protección de Datos',
    termsConditionsProteccionDatos: 'Protección de Datos',

    //Privacy Policies

    privacyPoliciesConditionsTitle: 'Protección de datos de Mailteck & Customer Communications Tecknalia',
    privacyPoliciesConditionsParrafo1: 'De conformidad con lo establecido tanto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD), como en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPD-GDD) y demás normativa sobre protección de datos vigente, se informa a los usuarios de la APP, que voluntariamente han manifestado su consentimiento de manera libre, clara e inequívoca para el tratamiento de los datos personales al aceptar la casilla de “Política de protección de datos”, que sus datos van a ser tratados, en función de la empresa en la que se hayan dado de alta, bien por la empresa MAILTECK, S.A. o bien por la empresa CUSTOMER COMMUNICATIONS TECKNALIA, S.L., las cuales serán denominadas conjuntamente como GRUPO MAILCOMMS.',
    privacyPoliciesConditionsResponsable1Title: 'Responsable 1',
    privacyPoliciesConditionsResponsable2Title: 'Responsable 2',
    privacyPoliciesConditionsFinalidadTitle: 'Finalidad',
    privacyPoliciesConditionsLegitimacionTitle: 'Legitimacion',
    privacyPoliciesConditionsDestinatariosTitle: 'Destinatarios',
    privacyPoliciesConditionsDerechosTitle: 'Derechos',
    privacyPoliciesConditionsInformacionAdicionalTitle: 'Información adicional',
    privacyPoliciesConditionsPoliticaInformacion1Title: 'Política de la información 1',
    privacyPoliciesConditionsPoliticaInformacion2Title: 'Política de la información 2',
    privacyPoliciesConditionsIdentidadTitle: 'Identidad',
    privacyPoliciesConditionsNIFTitle: 'NIF',
    privacyPoliciesConditionsDireccionTitle: 'Dirección',
    privacyPoliciesConditionsTelefonoTitle: 'Teléfono',
    privacyPoliciesConditionsCorreoTitle: 'Correo',
    privacyPoliciesConditionsDPDTitle: 'Contacto con el DPD',
    privacyPoliciesConditionsDPDCustomerCommsTitle: 'Delegado de Protección de Datos de Customer Comms',
    privacyPoliciesConditionsDPDMailteckTitle: 'Delegado de Protección de Datos de Mailteck',
    privacyPoliciesConditionsFinalidadIntro: 'Gestionar y tramitar los servicios contratados',
    privacyPoliciesConditionsFinalidadParrafo1: 'GRUPO MAILCOMMS va a tratar los datos facilitados, como parte de la ejecución de un contrato suscrito por usted o por su empresa, con el fin de gestionar y tramitar los servicios pactados en el mismo, así como dar soporte de la aplicación Certysign.',
    privacyPoliciesConditionsFinalidadParrafo2: 'GRUPO MAILCOMMS, solo tratará los datos facilitados para las finalidades descritas anteriormente y no serán tratados ulteriormente de manera incompatible con dichos fines. Los datos personales proporcionados se conservarán mientras se mantenga la relación contractual.',
    privacyPoliciesConditionsLegitimacionIntro: 'Ejecución del contrato',
    privacyPoliciesConditionsLegitimacionParrafo1: 'Los datos han sido facilitados por usted o por su empresa a través de los canales establecidos para poder darle acceso a la aplicación Certysign, como consecuencia del contrato de prestación de servicio suscrito.',
    privacyPoliciesConditionsDestinatariosIntro: 'No se ceden a terceros',
    privacyPoliciesConditionsDestinatariosParrafo1: 'Por exigencia legal cuando una norma así lo exija.',
    privacyPoliciesConditionsDerechosIntro: 'Puede ejercer sus derechos en la forma y plazos legalmente previstos.',
    privacyPoliciesConditionsDerechosElem1ListaTitulo: 'Confirmación general:',
    privacyPoliciesConditionsDerechosElem1ListaDescripcion: 'Cualquier persona tiene derecho a obtener confirmación sobre si GRUPO MAILCOMMS se están tratando datos personales que les conciernan.',
    privacyPoliciesConditionsDerechosElem2ListaTitulo: 'Derechos ARCO:',
    privacyPoliciesConditionsDerechosElem2ListaDescripcion: 'Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.',
    privacyPoliciesConditionsDerechosElem3ListaTitulo: 'Limitación del tratamiento:',
    privacyPoliciesConditionsDerechosElem3ListaDescripcion: 'En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.',
    privacyPoliciesConditionsDerechosElem4ListaTitulo: 'Oposición al tratamiento:',
    privacyPoliciesConditionsDerechosElem4ListaDescripcion: 'En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. Por lo tanto, GRUPO MAILCOMMS dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.',
    privacyPoliciesConditionsDerechosElem5ListaTitulo: 'Portabilidad de los datos:',
    privacyPoliciesConditionsDerechosElem5ListaDescripcion: 'En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán solicitar recibir los datos personales facilitados en el formulario de contacto, en un formato estructurado, de uso común y legible por máquina, así como transmitir dichos datos a otro responsable del tratamiento sin impedimentos.',
    privacyPoliciesConditionsDerechosElem6ListaTitulo: 'Reclamación ante organismo público:',
    privacyPoliciesConditionsDerechosElem6ListaDescripcion: 'En caso de considerar vulnerado su derecho a la protección de datos personales, podrá interponer una reclamación ante la Agencia Española de Protección de Datos',
    privacyPoliciesConditionsDerechosAEPDTitle: 'AEPD',
    privacyPoliciesConditionsDerechosElem7ListaTitulo: 'Canal para ejercitar los derechos:',
    privacyPoliciesConditionsDerechosElem7ListaDescripcion: 'Los interesados podrán ejercer los derechos que les reconoce en la normativa frente a, y en contra de, cada uno de los responsables, ante las direcciones habilitada como DPO en el apartado "Responsable".',
    privacyPoliciesConditionsInformacionAdicionalIntro: 'Más información sobre Protección de Datos.',
    privacyPoliciesConditionsInformacionAdicionalLista1: 'Los datos personales se han obtenido de los canales que las empresas del GRUPO MAILCOMMS han establecido para poder gestionar y los servicios contratados por usted o por su empresa.',
    privacyPoliciesConditionsInformacionAdicionalLista2: 'Las categorías de datos que se van a tratan serán los datos facilitados para el desarrollo de cada servicio contratado.',
    privacyPoliciesConditionsInformacionAdicionalLista3: 'GRUPO MAILCOMMS no trata datos especialmente protegidos.',
    privacyPoliciesConditionsPoliticaInformacionIntro: 'MailTecK, S.A. Política de Seguridad y Privacidad de la Información basada en la norma ISO 27001 -> Leer más.',
    privacyPoliciesConditionsPoliticaInformacio2nIntro: 'Customer Communication Tecknalia, S.L. Política de Seguridad y Privacidad de la Información basada en la norma ISO 27001 -> Leer más.',
    privacyPoliciesConditionsPoliticaInformacionTituloH: 'ANEXO I: POLÍTICA DEL SISTEMA DE GESTIÓN DE SEGURIDAD Y PRIVACIDAD DE LA INFORMACIÓN.',
    privacyPoliciesConditionsPoliticaInformacionP1: 'Esta Política de Seguridad y Privacidad de la Información muestra el compromiso de la Dirección dotando a la organización de recursos, responsabilidades y procedimientos que permitan garantizar el cumplimiento de la normativa vigente, así como la integridad, confidencialidad y disponibilidad de la información y los sistemas, siendo crucial para la seguridad, privacidad y continuidad de nuestra organización, así como la de nuestros clientes.',
    privacyPoliciesConditionsPoliticaInformacionP2: 'Grupo Mailteck, consciente de que la seguridad y privacidad de la información relativa a nuestros clientes es un recurso con gran valor, ha establecido un Sistema de Gestión de la Seguridad y Privacidad de la Información de acuerdo con los requisitos de las normas ISO/IEC 27001:2013 e ISO/IEC 27701:2019 para garantizar los niveles de Seguridad requeridos, la Continuidad de los servicios prestados y el Cumplimiento legal en el tratamiento de datos de carácter personal, minimizando así los riesgos derivados de esta gestión.',
    privacyPoliciesConditionsPoliticaInformacionCCTP2: 'Custommer Comms, consciente de que la seguridad y privacidad de la información relativa a nuestros clientes es un recurso con gran valor, ha establecido un Sistema de Gestión de la Seguridad y Privacidad de la Información de acuerdo con los requisitos de las normas ISO/IEC 27001:2013 e ISO/IEC 27701:2019 para garantizar los niveles de Seguridad requeridos, la Continuidad de los servicios prestados y el Cumplimiento legal en el tratamiento de datos de carácter personal, minimizando así los riesgos derivados de esta gestión.',
    privacyPoliciesConditionsPoliticaInformacionP3: 'El objetivo de la Política de Seguridad y Privacidad es fijar el marco de actuación necesario para proteger los recursos de información frente a amenazas, internas o externas, deliberadas o accidentales, con el fin de asegurar el cumplimiento de la confidencialidad, integridad y disponibilidad de la información.',
    privacyPoliciesConditionsPoliticaInformacionP4: 'La eficacia y aplicación del Sistema de Gestión de la Seguridad y Privacidad de la Información es responsabilidad directa del Comité de la Seguridad y Privacidad de la Información, el cual es responsable de la aprobación, difusión y cumplimiento de la presente Política de Seguridad y Privacidad. En su nombre y representación se ha nombrado un Responsable del Sistema de Gestión de la Seguridad y Privacidad de la Información, que posee la suficiente autoridad para desempeñar un papel activo en el Sistema de Gestión de la Seguridad y Privacidad de la Información, supervisando su implantación, desarrollo y mantenimiento.',
    privacyPoliciesConditionsPoliticaInformacionP5: 'Adicionalmente, y para dar cumplimiento a la normativa vigente de protección de datos, se ha nombrado un Delegado de Protección de datos (DPO) con el objeto de garantizar la seguridad y privacidad de los datos de carácter personal, especialmente teniendo en cuenta la actividad de la compañía.',
    privacyPoliciesConditionsPoliticaInformacionP6: 'El Comité de Seguridad y Privacidad de la Información procederá a desarrollar y aprobar la metodología de análisis de riesgos utilizada en el Sistema de Gestión de la Seguridad y Privacidad de la Información.',
    privacyPoliciesConditionsPoliticaInformacionP7: 'Toda persona cuya actividad pueda, directa o indirectamente, verse afectada por los requisitos del Sistema de Gestión de la Seguridad y Privacidad de la Información, está obligada al cumplimiento estricto de la Política de Seguridad y Privacidad.',
    privacyPoliciesConditionsPoliticaInformacionP8: 'En Grupo Mailteck se implantarán todas las medidas necesarias para cumplir la normativa aplicable en materia de seguridad y privacidad en general y de seguridad informática, relativa a la política informática, a la seguridad de edificios e instalaciones y al comportamiento de empleados y terceras personas asociadas con Grupo Mailteck en el uso de sistemas informáticos y tratamiento de datos personales. Las medidas necesarias para garantizar la seguridad y privacidad de la información mediante la aplicación de normas, procedimientos y controles deberán permitir asegurar la confidencialidad, integridad, disponibilidad de la información, esenciales para:',
    privacyPoliciesConditionsPoliticaInformacionCCTP8: 'En Custommer Comms se implantarán todas las medidas necesarias para cumplir la normativa aplicable en materia de seguridad y privacidad en general y de seguridad informática, relativa a la política informática, a la seguridad de edificios e instalaciones y al comportamiento de empleados y terceras personas asociadas con Custommer Comms en el uso de sistemas informáticos y tratamiento de datos personales. Las medidas necesarias para garantizar la seguridad y privacidad de la información mediante la aplicación de normas, procedimientos y controles deberán permitir asegurar la confidencialidad, integridad, disponibilidad de la información, esenciales para:',
    privacyPoliciesConditionsPoliticaInformacionLista1: 'Cumplir con la legislación, reglamentación y normativa vigente en materia de privacidad y de los sistemas de información y con todos los requisitos contractuales en materia de protección de datos, así como los que considere oportuno llevar a cabo con el objetivo de lograr una mejora continua de la organización.',
    privacyPoliciesConditionsPoliticaInformacionLista2: 'Asegurar la confidencialidad de los datos gestionados por Grupo Mailteck.',
    privacyPoliciesConditionsPoliticaInformacionCCTLista2: 'Asegurar la confidencialidad de los datos gestionados por Custommer Comms.',
    privacyPoliciesConditionsPoliticaInformacionLista3: 'Asegurar la disponibilidad de los sistemas de información, tanto en los servicios ofrecidos a los clientes como en la gestión interna.',
    privacyPoliciesConditionsPoliticaInformacionLista4: 'Asegurar la capacidad de respuesta ante situaciones de emergencia, restableciendo el funcionamiento de los servicios críticos en el menor tiempo posible.',
    privacyPoliciesConditionsPoliticaInformacionLista5: 'Evitar alteraciones indebidas en la información.',
    privacyPoliciesConditionsPoliticaInformacionLista6: 'Promover la concienciación y formación en seguridad y privacidad de la información.',
    privacyPoliciesConditionsPoliticaInformacionLista7: 'Impulsar y participar en conseguir la mejora continua del sistema de gestión de la seguridad y privacidad de la información.',
    cookiesPoliciesIntroText: 'Este sitio web utiliza cookies para mejorar su experiencia mientras navega por el sitio web. Puede cambiar estos ajustes en cualquier momento. Las cookies clasificadas como necesarias se almacenan en su navegador y son esenciales para el funcionamiento de las funcionalidades básicas del sitio web. Las cookies de terceros se almacenarán en su navegador solo con su consentimiento y nos ayudan a analizar y comprender cómo se utiliza este sitio web. Tiene la opción de optar por no recibir estas cookies, sin embargo, la exclusión voluntaria de alguna de esta cookies puede hacer que algunas funciones dejen de estar disponibles. Para obtener información sobre eliminar las cookies, por favor consulte la función de ayuda de su navegador.',
    cookiesPoliciesNecessaryCookies: 'Cookies necesarias',
    cookiesPoliciesNecessaryCookiesText: 'Este tipo de cookies son absolutamente esenciales para que el sitio web funcione correctamente. Esta categoría solo incluye cookies que garantizan funcionalidades básicas y características de seguridad del sitio web, y no recopilan o almacenan ninguna información personal sobre los usuarios del sitio web.',
    cookiesPoliciesUnnecessaryCookies: 'Cookies no necesarias',
    cookiesPoliciesUnnecessaryCookiesText: 'Las cookies que pueden no ser particularmente necesarias para que el sitio web funcione y se utilizan específicamente para recopilar datos personales del usuario a través de análisis, anuncios y otros contenidos integrados se denominan cookies no necesarias. Es obligatorio obtener el consentimiento del usuario antes de ejecutar estas cookies en su sitio web.',
    cookiesPoliciesGoogleAnalyticsCookies: 'Cookies de Google Analytics',
    cookiesPoliciesGoogleAnalyticsCookiesText: 'Para obtener más información respecto a este tipo de cookies, puede obtener más información consultando el documento de “Uso de las cookies de Google Analytics en sitios web',
    cookiesPoliciesGoogleAnalyticsCheckboxText: 'Mantener cookies activas',
    cookiesNotificationBarText: 'Utilizamos cookies, tanto propias como de terceros, para medir las visitas, garantizar la funcionalidad del sitio web y analizar la navegación en la misma. A continuación puede aceptar las cookies o configurar/rechazar el uso de las mismas. Puede obtener más información sobre la titularidad de la web, responsable del tratamiento de las cookies y de sus datos de contacto en nuestra',
    cookiesNotificationBarConfig: 'Configurar',
    cookiesPoliciesModalIntro: 'Introducción',
    cookiesPoliciesModalIntroP1: 'Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas para mejorar y optimizar la experiencia del usuario.',
    cookiesPoliciesModalIntroP2: 'En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocer como usuario, obtener información sobre hábitos de navegación, o personalizar la forma en que se muestra el contenido.',
    cookiesPoliciesModalIntroP3: 'De conformidad con el Real Decreto Ley 13/2012 y su afectación en el artículo 22.2 de la Ley de Servicios de la Sociedad de la Información y Comercio Electrónico, mediante la presente Política se le informa de las diferentes cookies que CUSTOMER COMMUNICATIONS TECKNALIA, S.L. recaba a través de los distintos medios o canales de comunicación, propias o de terceros, exentas o no del deber de informar y los diferentes medios a través de los cuales puede rechazar, permitir o bloquear aquellas.',
    cookiesPoliciesModalIntroP4: 'A continuación se detalla información sobre: qué son las “Cookies”, qué tipología utiliza este sitio web, cómo pueden ser desactivarlas en un navegador y cómo bloquear específicamente la instalación de Cookies de terceros.',
    cookiesPoliciesModalResponsibility: '¿Quién es el responsable del uso de las cookies en el presente sitio web?',
    cookiesPoliciesModalResponsibilityP1: 'El responsable del uso de las cookies es CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en adelante CCOMMS), con N.I.F. número B-86414000 y con domicilio social en Leganés (Madrid) Avenida de la Recomba, 14, debidamente inscrita en el Registro Mercantil de Madrid al Tomo 29.801, Folio 98, Sección 8ª, Hoja número M-536210.',
    cookiesPoliciesModalResponsibilityP2: 'Correo electrónico del Delegado de Protección de Datos.',
    cookiesPoliciesModalWhatIsCookie: '¿Qué es una cookie?',
    cookiesPoliciesModalWhatIsCookieP1: 'Una cookie es un dato, fichero o dispositivo de almacenamiento y recuperación de datos (DARD), que un navegador web almacena de forma automática en el dispositivo (Smartphone, tableta o televisión conectada) de un usuario al acceder a páginas web, cuentas de redes sociales o al recibir un correo electrónico.',
    cookiesPoliciesModalWhatIsCookieP2: 'Las cookies permiten almacenar y recuperar de forma automatizada datos sobre el uso que se ha hecho de los citados medios digitales y del equipo o dispositivo desde el que accedes a los mismos.',
    cookiesPoliciesModalWhatIsCookieP3: 'Una cookie son sólo datos que se almacenan en el ordenador del usuario, por lo que no son software, tampoco son fragmentos de código, son simplemente datos. Por tanto, en principio las cookies no pueden transmitir y ejecutar virus, ni instalar malware como troyanos o programas de espionaje.',
    cookiesPoliciesModalCookieTypes: 'Qué tipo de cookies hay en la página web y su finalidad',
    cookiesPoliciesModalCookiesInstall: '¿Qué cookies se pueden instalar en la web de CertySign?',
    cookiesPoliciesModalCookiesInstallP1: 'El acceso de Web de CertySign implica que se puedan instalar las Cookies, propios y/o de tercero, que se detallan en el siguiente cuadro:',
    cookiesPoliciesModalBrowserConfigCookies: 'Cómo configurar las cookies en el navegador',
    cookiesPoliciesModalBrowserConfigCookiesP1: 'Usted tiene la opción de no recibir cookies o de ser informado acerca de su fijación mediante la configuración de su navegador a tal efecto, todas las cookies, salvo las de Flash, puede ser gestionadas desde su navegador, normalmente en la sección “Opciones” o “Preferencias”.',
    cookiesPoliciesModalBrowserConfigCookiesP2: 'Puede desactivarlas del todo, en cuyo caso su navegador no permitirá que se instale ninguna (imposibilitando que pueda hacer uso de todas las funcionalidades de la página web), o sólo las que provenga de determinadas webs, lo que le permitirá poder disfrutar de las funcionalidades avanzadas de las webs que respetan la privacidad de sus datos, y excluir aquellas que no lo hagan.',
    cookiesPoliciesModalBrowserConfigCookiesP3: 'En los siguientes enlaces le facilitamos los accesos a las principales páginas de soporte:',
    cookiesPoliciesModalBrowserConfigCookiesP4: 'Asimismo, el Usuario puede oponerse al uso de determinadas cookies mediante los siguientes servicios:',
    cookiesPoliciesModalBrowserConfigCookiesLI1: 'Bloqueo de cookies en Chrome',
    cookiesPoliciesModalBrowserConfigCookiesLI2: 'Bloqueo de cookies en Safari',
    cookiesPoliciesModalBrowserConfigCookiesLI3: 'Bloqueo de cookies en Firefox',
    cookiesPoliciesModalBrowserConfigCookiesLI4: 'Bloqueo cookies en Internet Explorer',
    cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
    cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
    cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
    cookiesPoliciesModalUnavailableCookies: '¿Qué ocurre si se deshabilitan las cookies?',
    cookiesPoliciesModalUnavailableCookiesP1: 'Si rechaza las cookies podrá seguir navegando por nuestro Sitio Web, aunque el uso de algunos de sus servicios podrá ser limitado y, por tanto, su experiencia puede resultar menos satisfactoria.',
    cookiesPoliciesModalUnavailableCookiesP2: 'El rechazo, bloqueo o no permisibilidad de las cookies descritas en la presente Política puede afectar a una correcta navegación por los contenidos reproducidos en el sitio web.',
    cookiesPoliciesModalAcceptCookies: 'Aceptación de la política de cookies',
    cookiesPoliciesModalAcceptCookiesP1: 'Pulsando el botón “ACEPTAR COOKIES” se asume que usted acepta el uso de cookies.',
    cookiesPoliciesModalAcceptCookiesP2: 'No obstante, con cada inicio de sesión se mostrará la Política de cookies en la parte inferior de cualquier página del portal con el objeto de que usted sea consciente.',
    cookiesPoliciesModalUpgradeCookies: '¿Actualizamos nuestra política de cookies?',
    cookiesPoliciesModalUpgradeCookiesP1: 'Es posible que la información proporcionada en la presente Política de Cookies, pueda sufrir modificaciones debidas a actualizaciones o cambios en la política de tratamiento de cookies, así mismo, la Agencia Española de Protección de Datos pudiere dictar instrucciones y/o recomendaciones que hicieren variar la misma.',
    cookiesPoliciesModalUpgradeCookiesP2: 'Por ello le recomendamos revisar esta política cada vez que acceda a nuestro Sitio Web con el objetivo de estar adecuadamente informado sobre cómo y para qué usamos las cookies.',
    cookiesPoliciesModalRecipientCookies: '¿Quiénes son los destinatarios de la información y cómo el uso de cookies afecta a la protección de datos personales?',
    cookiesPoliciesModalRecipientCookiesP1: 'Para más información sobre el tratamiento de sus datos puede consultar la ',
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Política de Protección de datos',
    cookiesPoliciesModalContactInfoCookies: 'Información de contacto',
    cookiesPoliciesModalContactInfoCookiesP1: 'Para cualquier duda, aclaración y/o sugerencia al respecto de las cookies, rogamos se pongan en contacto a través de correo electrónico ',
    cookiesPoliciesModalDatatableCookieType: 'Tipo de Cookie',
    cookiesPoliciesModalDatatableTreatmentTerm: 'Plazo de Tratamiento',
    cookiesPoliciesModalDatatableTypeAnalytics: 'Analíticas',
    cookiesPoliciesModalDatatableTypeSelf: 'Propias',
    cookiesPoliciesModalDatatableTypeAnalyticsD: 'Cada vez que un Usuario visita un Servicio, una herramienta de un proveedor externo genera una Cookie analítica en el ordenador del Usuario. Esta Cookie, que sólo se genera en la visita, servirá en próximas visitas a CCOMMS para identificar de forma anónima al visitante.',
    cookiesPoliciesModalDatatableTypeAnalyticsF: 'Permitir la identificación anónima de los Usuarios navegantes a través de la “Cookie” (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de visitantes y su tendencia en el tiempo. Identificar de forma anónima los contenidos más visitados y más atractivos para los Usuarios. Saber si el Usuario que está accediendo es nuevo o repite visita. Salvo que el Usuario decida registrarse en CCOMMS, la “Cookie” nunca irá asociada a ningún dato de carácter personal que pueda identificarle directamente. Dichas Cookies sólo serán utilizadas con propósitos estadísticos que ayuden a la optimización y mejora de la experiencia de los Usuarios en el sitio.',
    cookiesPoliciesModalDatatableTypeAnalyticsT: 'Persistentes',
    cookiesPoliciesModalDatatableTypeSelfD: 'Este tipo de Cookies permiten ampliar la información mostrada a cada Usuario anónimo en los Servicios de CCOMMS.',
    cookiesPoliciesModalDatatableTypeSelfF: 'Entre otros, se almacena la duración o frecuencia de visualización de las distintas secciones, la interacción con las mismas, o los patrones de navegación y/o comportamientos del Usuario.',
    cookiesPoliciesModalDatatableTypeSelfT: 'Persistentes',
    eventInfo: 'Información del evento',
    manual: 'Manual',
    guia: 'Guía',
    supportLoggedPhonePrefferedMessage: '(Soporte de usuario ya logado - Preferido Teléfono) ',
    supportLoggedNotPhoneMessage: 'No marca teléfono',
    supportLoggedUserMessage: '(Soporte de usuario ya logado) ',
    supportNotLoggedUserWebMessage: '(Login - Soporte vía web) El usuario solicita asistencia para recuperar la contraseña',
    supportNotLoggedUserNotAvailableMessage: 'No disponible',
    supportNotLoggedUserC2CMessage: '(Login - Soporte vía C2C) El usuario solicita asistencia telefónica para recuperar la contraseña',
    processA4NotSupported: 'Los documentos deben ser A4, no debe superar las 389 páginas ni los 20mb',
    cookiesGAMessage: 'Medición interna con Google Analytics. Estas cookies almacenan un identificador único para reconocer al usuario las futuras ocasiones que visite el sitio web.',
    cookiesGAFGenericMessage: 'Medición interna con Google Analytics.',
    cookiesCookieMessage: 'Cookie',
    cookiesNecessaryCookieDescription: 'Esta cookie es necesaria y se utiliza para establecer la preferencia del usuario al respecto de la política de cookies.',
    documentHtmlRule: 'Tipo de fichero aceptado - HTML',

    rulesFilesSizePostal: 'El archivo debe pesar como máximo 15Mb',
    rulesFilesSizeEmail: 'El archivo debe pesar como máximo 20Mb',
    rulesFilesSizeMultiple: 'El tamaño maximo total de los archivos es 14Mb',
    rulesFilesSize: 'El archivo debe pesar como máximo {fileSize}Mb',
    rulesFilesExtensionHtml: 'El archivo debe ser un HTML',

    //16/06/2021
    group_cannot_be_empty: 'El grupo :group_name: no puede estar vacío',

    //23/06/2021
    language: 'Idioma',
    defaultLanguage: 'Idioma por defecto',

    //21/07/2021
    transactionDownloadPee: 'Ver PEE',
    transactionTracking: 'Seguimiento de envío',

    // 06/09/2021
    documentOrder: 'Establecer orden de documentos',

    // 13/09/2021
    yes: 'Sí',
    no: 'No',
    notifications: 'Notificaciones',
    activateNotifications: 'Activar',
    deactivateNotifications: 'Desactivar',
    selectCategoryNotifications: 'Seleccionar acción para notificaciones',
    activateCategoryNotifications: 'Activar todas',
    deactivateCategoryNotifications: 'Desactivar todas',
    customCategoryNotifications: 'Personalizar',
    enabledNotifications: 'Notificaciones activadas',
    disabledNotifications: 'Notificaciones desactivadas',
    customNotifications: 'Notificaciones personalizadas',
    selectNotifications: 'Seleccionar configuración de notificaciones',
    enabledNotificationsDescriptionText: 'Cada evento realizado generará una notificación. Podrá elegir entre recibir' +
        ' un email por cada una de ellas, o un informe diario con las notificaciones agrupadas.',
    disabledNotificationsDescriptionText: 'No recibirá ninguna notificación relacionada con los estados de los envíos' +
        ' realizados.',
    customNotificationsDescriptionText: 'Podrá seleccionar procesos de los que quiera recibir notificaciones' +
        ' acerca de sus estados, y procesos de los que no.',
    enabledNotificationsConfigurationText: 'Configuración',
    enabledNotificationsConfigurationRealTime: 'Notificaciones en tiempo real',
    enabledNotificationsConfigurationGrouped: 'Notificaciones agrupadas',
    selectEnabledNotifications: 'Seleccionar tipo de notificaciones',
    // enabledNotificationsRealTimeDescriptionText: "Recibe un email instantáneo por cada evento realizado. Si elige un" +
    //     " horario específico, además recibirá un email con el informe de todos los eventos realizados fuera de ese" +
    //     " horario.",
    enabledNotificationsRealTimeDescriptionText: 'Recibe un email instantáneo por cada evento realizado.',
    enabledNotificationsGroupedDescriptionText: 'Recibirá un email en el horario seleccionado con el informe de todos' +
        ' los eventos realizados durante el día anterior.',
    enabledNotificationsScheduleRangeDescriptionText: 'Notificar durante el siguiente horario.',
    enabledNotificationsScheduleDescriptionText: 'Notificar solo en el siguiente horario.',
    chooseNotificationsRange: 'Elija cómo y cuándo quiere recibir las notificaciones.',
    enabledNotificationsCustomText: 'Personalización de notificaciones',
    // realTimeNotificationsDescription: "Las notificaciones se envían en tiempo real. Por cada evento realizado, se envía un\n" +
    //     "          email avisando de su estado, en el horario seleccionado.",
    realTimeNotificationsDescription: 'Las notificaciones se envían en tiempo real. Por cada evento realizado, se envía un\n' +
        '          email avisando de su estado.',
    groupedNotificationsDescription: 'Las notificaciones se envían, mediante un informe adjunto que contiene un listado con todas las transacciones y el último evento registrado en cada una de ellas, en el horario seleccionado.',
    groupedNotificationsReportTime: 'A qué hora quiere recibir el informe',
    // languageAndTimezone: "Idioma y franja horaria",
    languageAndTimezone: 'Idioma',
    defaultTimeZone: 'Franja horaria por defecto',
    user_process_notifications_updated: 'Notificaciones actualizadas',
    changeSectionConfirmation: 'Cambios pendientes de guardar',
    changeSectionConfirmationText: 'Usted ha realizado cambios que están pendientes de ser guardados. Si desea' +
        ' guardarlos ahora, haga click en',

    // 12/11/21
    discard: 'Descartar',

    // 16/11/21
    cost_center_updated: 'Centro de coste actualizado con éxito',

    //20/09/22
    loginInfo: 'Información de inicio de sesión',
    lastLoginSuccess: 'Último inicio de sesión',
    lastLoginError: 'Último intento de acceso fallido',
    noRecord: 'Sin registro',

    //14/10/22

    charLimitLength: 'Campo debe tener {limit} caracteres o menos',
    requiredLastName: 'Apellidos requeridos',
    optional: 'Opcional',
    availableVariables: 'Variables disponibles',

    //04/11/22
    twoFactorText: 'El usuario requiere doble factor de autenticación. Para iniciar sesión introduzca el código que podra visualizar en su aplicación de gestión de accesos multifactor.',
    mfa_configuration_required: 'El usuario requiere doble factor de autenticación. Para configurarlo, lea el QR desde su aplicación de gestión de accesos multifactor. Posteriormente, introduzca el código en el cuadro inferior',
    code: 'Código',
    codeRequired: 'El código es requerido para iniciar sesión',
    mfa_code_invalid: 'El código no es correcto',

    // 07/11/22
    communicationContent: 'Contenido de la comunicación',
    uploadFiles: 'Incluir documentos',
    modifyFiles: 'Modificar documentos',
    fileResume: '{nfiles} archivo(s) ({size})MB en total',
    moreThan1City: 'El código postal corresponde a más de una población, consulte el desplegable o edite el campo manualmente',
    shipmentDataSectionDescription: 'En esta sección se añaden los contenidos de la comunicación. Los documentos deben ser de tipo PDF y tamaño A4',
    zipCodeFormat: 'Debe introducir un código postal válido',
    deleteRecipient: 'Eliminar este destinatario',
    mobileAddRecipientDescription: '¿Más de un destinatario?',

    // 30/11/22
    errorFilesTotalSizeExceeded: 'El tamaño total de los ficheros no puede superar los 15MB',
    fileExtensionNotAccepted: 'La extensión del fichero no es válida. (Las extensiones aceptadas son: {acceptedExtensions})',
    nameAndSurnameLength: 'El nombre y los apellidos no pueden superar los 99 caracteres',
    batchModeTitle: 'Envío por lotes',
    batchModeCSVStep: 'Destinatarios',
    batchModeAttachmentsStep: 'Ficheros requeridos',
    batchModeDownloadSampleCSV: 'Descargar fichero ejemplo',
    batchModeExtraCSVStepIntroduction: 'En primer lugar debe subir un fichero <strong>CSV</strong> o <strong>XLS</strong> con los datos de los envíos. A continuación, puede descargar un fichero de ejemplo que incluye los campos necesarios.',
    batchModeExtraCSVStepIntroduction2: 'Una vez tenga preparado el fichero, puede añadirlo haciendo clic o bien arrastrándolo en la zona habilitada para ello. Recuerde que el fichero <strong>no puede contener líneas vacías, ni errores</strong> en ninguno de los destinatarios.',
    batchModeExtraCSVStepIntroduction3: 'Una vez validada la estructura del fichero, se habilitará el botón <strong>Continuar</strong> y podrá seguir con el proceso.',
    batchModeExtraFilesStepIntroduction: 'En este paso se añadirán los recursos necesarios para realizar el envío a cada destinatario.<br><br>Se han detectado un total de <b>{count}</b> ficheros. Puede añadirlos haciendo clic o bien arrastrándolo en la zona habilitada para ello.<br><br>Recuerde que la suma de ficheros de cada envío no puede superar los <b>{fileSizeLimit} MB</b> y el envío tiene un tamaño máximo general de <b>{totalSizeLimit} GB</b>.',
    extraFilesTableHeaderFileName: 'Nombre del fichero',
    extraFilesTableHeaderStatus: 'Estado',
    extraFilesTableStatusNotFound: 'Pendiente de subir',
    extraFilesTableStatusProcessing: 'Procesando',
    extraFilesTableStatusUploaded: 'OK',
    extraFilesTableStatusUploadedCorrectly: 'Procesado',
    extraFilesTableHeaderActions: 'Acciones',
    batchModePreviewStep: 'Previsualizar y enviar',
    batchModeStep1RemoveFile: 'Borrar fichero',
    batchModeCSVTableTitle: 'El fichero <strong>{filename}</strong> contiene <strong>{nOfRecipients}</strong> destinatarios.',
    batchModeCSVTableSubtitle: 'A continuación, dispone de la previsualización de los primeros destinatarios del fichero, haga clic en Ver más para ampliar información.',
    batchModeCSVTableSubtitleNoData: 'El fichero seleccionado no contiene ningún destinatario.',
    batchModeNoDataTitle: 'Ningún fichero seleccionado. Una vez añadido dispondrá de los primeros destinatarios del fichero.',
    dropZoneText: 'Haga clic o arrastre aquí sus ficheros',
    dropZoneTextSingle: 'Haga clic o arrastre aquí su fichero',
    batchModeDialogTitle: 'Agregar destinatarios',
    batchModeDialogManualColTitle: 'De forma individual',
    batchModeDialogManualColDescription: 'Si desea realizar uno o varios envíos de forma individual, elija esta opción.',
    batchModeDialogBatchColTitle: 'Envío por lotes',
    batchModeDialogBatchColDescription: 'Si quiere realizar envíos mediante un fichero csv o xls con varios destinatarios y documentos, de una forma sencilla, elija esta opción.',
    batchModeDialogBatchBtnManual: 'Envío individual',
    batchModeDialogBatchBtnBatch: 'Envío por lotes',
    batchModeResourceTableNOfFilesFound: '<b>{nOfRequiredFiles}</b> ficheros pendientes de añadir',
    batchModeResourceTableAllFilesAdded: 'Se han añadido todos los ficheros necesarios',
    // batchModeResourceTableStatus: 'Estado actual: <b>$currentFiles$</b>/$nOfRequiredFiles$',
    batchModeResourceTableExpandText: 'Haga clic para ver más detalles',
    batchModeResourceTableFilenameText: 'Nombre del fichero',
    batchModeResourceTableFoundOnNRows: 'destinatarios afectados',
    batchModeResourceTableErrorFilenameInvalid: 'Uno o más de los ficheros que se han intentado añadir no se corresponden con los ficheros esperados.',
    batchModeResourceTableErrorFileSizeLimit: 'El tamaño del fichero es superior a {size}MB',
    batchModeResourceTableErrorTotalFileSizeLimit: 'El total de los ficheros subidos no puede superar {size}GB',
    batchModeResourceTableInfoFileReplaced: 'Se ha reemplazado el fichero {filename}.',
    seeMore: 'Ver más',
    seeLess: 'Ver menos',
    pending: 'Pendiente',
    batchModePreviewText: 'Una vez sea procesado podrá visualizar las transacciones en la vista correspondiente y recibirá un email con la información de cada envío, así como el identificador de transacción asociada a cada destinatario.',
    batchModePreviewText1: 'En el caso de no poder realizarse alguno de los envíos, en el email se especificará cuales de ellos no han sido procesados con el motivo del error.',
    csvError: 'No se ha podido procesar el fichero',
    csvErrorDetailStructure: 'La estructura del fichero es errónea.',
    csvErrorDetailIncorrectFields: 'Los campos no coinciden con los datos necesarios del proceso',
    csvErrorDetailIncorrectExtensions: 'Hay extensiones de fichero inválidas en las siguientes filas:',
    batchModeStep2SpaceLeft: 'Espacio usado',
    batchModeStep2PendingFiles: 'Ficheros pendientes',
    launchDataKoBatchMode: 'Los datos proporcionados no son válidos',
    launchDataKoBatchModeStep1: 'No se ha seleccionado ningún fichero',
    launchDataOkBatchModeStep1: 'La estructura del fichero es correcta, puede continuar con el proceso',
    launchDataKoBatchModeStep2: 'Añada los ficheros necesarios',
    launchDataOkBatchModeStep2: 'Se han añadido los ficheros necesarios, puede continuar con el proceso',
    launchDataOkBatchMode: 'Los datos son correctos, puede continuar con el proceso',
    batchModeProcessLaunchedStep1: 'Iniciando el proceso de registro',
    batchModeProcessLaunchedStep2: 'Subiendo recursos necesarios para los envíos',
    batchModeProcessLaunchedStep3: 'Subiendo fichero de destinatarios',
    batchModeProcessLaunchedStep4: 'Proceso de registro finalizado',
    transactionsProcessedBatchMode: 'Envío por lotes registrado correctamente',
    transactionProcessingBatchMode: 'Procesando envío por lotes...',
    transactionProcessingBatchModeError: 'Error en el procesamiento del envío por lotes',
    transactionProcessingBatchModeErrorDescription: 'Ha ocurrido un error en el procesamiento del lote, por favor póngase en contacto con soporte.',
    csvErrorDialogDescription: 'Recuerde que debe subir un fichero <b>CSV</b> o <b>XLS</b> y que el mismo <strong>no puede contener líneas vacías, ni errores</strong> en ninguno de los destinatarios.',
    extraFileError: 'No se ha podido añadir el archivo',
    extraFileErrorDialogDescription: 'Recuerde que el límite de tamaño por transacción es de <b>{fileSizeLimit} MB</b> y el envío completo del lote tiene un tamaño máximo de <b>{totalSizeLimit} GB</b>.',
    extraFileErrorTransactionSizeLimit: 'Se ha superado el tamaño máximo para el envío en los siguientes registros: {rows}',
    htmlEditorInfoParagraph1: 'En este paso, se personalizará el contenido del email que será enviado a cada uno de los destinatarios.',
    htmlEditorInfoParagraph3: 'Puede seleccionar un modo de edición:',
    htmlEditorInfoBullet1: '<span>Selección de plantilla</span>. Seleccione una plantilla y realice los ajustes necesarios desde el editor.',
    htmlEditorInfoBullet2: '<span>HTML personalizado</span>. Suba un archivo HTML personalizado y realice los ajustes necesarios desde el editor.',
    htmlEditorButtonUploadFile: 'SUBIR HTML',
    htmlEditorDragAndDrop: 'Haga clic aquí o arrastre los archivos adjuntos al email',
    batchModeExtraCSVStepIntroductionVariables: 'Recuerde que tiene a su disposición las siguientes variables para su uso en los campos de transacción:',
    batchModeContactId: 'Referencia de destinatario',
    batchModeContactName: 'Nombre de destinatario',
    batchModeContactSurname: 'Apellidos de destinatario',
    htmlEditorTemplateSelected: 'Plantilla seleccionada',
    htmlSubjectError: 'El asunto tiene más de <span>{subjectLimit}</span> caracteres',
    // 19/04/2023
    // htmlEditorReplaceAttachmentFiles: 'Los siguientes archivos han sido reemplazados',
    htmlEditorInfoParagraph4: 'Recuerde que el tamaño máximo total de los archivos es <b>{totalSizeLimit} Mb</b>.',
    backToProcess: 'Volver a procesos',
    infoAndHelp: 'Información y ayuda',
    modelEmailHtmlTemplateSelector: 'Seleccionar plantilla',
    modelEmailEmailBodyNotHtml: "El archivo seleccionado no es html",
    templateSelectorDefaultTemplate: 'Por defecto',
    templateSelectorCustomTemplate: 'Personalizada',
    templateSelectorNoLogoTemplate: 'Sin logo',
    filesExcedingSizeLimit: 'Archivos que superan el límite de tamaño',
    emailAttachmentsSectionTitle: 'Archivos adjuntos del email',
    emailContent: 'Contenido del email',
    emailSubject: 'Asunto del email',

    multipleFileExtensionError: "Hay uno o más ficheros con extensiones inválidas. (Las extensiones aceptadas son: {acceptedExtensions})",

    //18/03/24
    backLostChangesHTMLText: "Si regresa, perderá los cambios en la plantilla de email así como los ficheros adjuntos. <br>¿Está seguro de <b>volver</b> al paso anterior?",
    backLostChangesHTMLConfirmBack: "Volver al paso anterior",
    backLostChangesHTMLConfirmContinue: "Continuar con el envío",
  filePasswordProtectedOrDamage: "Fichero no válido. Compruebe que el archivo no esté dañado o protegido con contraseña e inténtelo de nuevo"

}
