<template>
  <v-container class="cts-main-container cts-main-view col-12 pa-0">
    <v-scroll-y-transition>
      <v-col v-if="categories" cols="12" class="ma-0 pa-0">
        <v-row dense class="ma-0 justify-center">
          <v-col cols="11" xl="10" lg="10" md="11" sm="11" class="cts-view-header">
            <v-row dense>
              <v-col cols="12" xl="10" lg="10" md="12" sm="12">
                <view-title
                  idName="processes"
                  :icon="const_icons.PROCESSES"
                  :title="$vuetify.lang.t('$vuetify.processesViewListTitle')"
                  :subtitle="$vuetify.lang.t('$vuetify.processesViewListDescription')"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense class="ma-0 pa-0 justify-center">
          <v-col cols="11" lg="10" xl="10" md="11" class="white mb-6">
            <v-row dense class="cts-row-processes-filters py-4 justify-space-between px-2">
              <v-col cols="12" xl="6" lg="6" md="12" sm="12" class="pa-0">
                <v-row dense class="ma-0">
                  <v-col cols="12" xl="6" lg="6" md="12" sm="12" class="px-0 pr-lg-2">
                    <v-select
                      single-line outlined dense id="processes-input-select_categories"
                      class="ma-0 cts-select-box cts-box-filter-tooltip"
                      clearable
                      :placeholder="$vuetify.lang.t('$vuetify.processesCategoriesFilter')"
                      :menu-props="selectsProps"
                      :items="categoriesFilter"
                      v-model="selectedCategory"
                      :label="$vuetify.lang.t('$vuetify.processesCategoriesFilter')"
                      :aria-label="$vuetify.lang.t('$vuetify.processesCategoriesFilter')"
                    >
                      <template v-slot:selection="{ item }">
                        <v-tooltip v-if="item.length > 35" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span class="cts-break-word" v-bind="attrs" v-on="on">{{ item }}</span>
                          </template>
                          <span>{{ item  }}</span>
                        </v-tooltip>
                        <span :id="`processes-input-select_categories_${item.toLowerCase()}`" v-else>
                          {{ item  }}
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" xl="6" lg="6" md="12" sm="12" class="px-0">
                    <v-select
                      id="processes-input-select_tags" multiple clearable single-line outlined dense
                      :class="'ma-0 cts-select-box cts-select-tags-'+lang.toLowerCase()"
                      :items="tags"
                      v-model="selectedTags"
                      :placeholder="$vuetify.lang.t('$vuetify.processesTagFilter')"
                      :menu-props="selectsProps"
                      :label="$vuetify.lang.t('$vuetify.processesTagFilter')"
                      :aria-label="$vuetify.lang.t('$vuetify.processesTagFilter')"
                    >
                      <template v-slot:item="{ item }">
                          <div class="v-list-item__content">
                            <div :id="`processes-input-select_tags_${item.toLowerCase()}`" class="v-list-item__title">
                              {{ ($vuetify.lang.t('$vuetify.tag_' + item.toLowerCase())).toUpperCase()}}
                            </div>
                          </div>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <div v-if="index < 2"
                             class="ml-1 py-1">
                          <v-chip dark label
                                  class="py-1 cts-select-chip">
                            <span :id="`processes-select-tags_${item.toLowerCase()}`">
                              {{ ($vuetify.lang.t('$vuetify.tag_' + item.toLowerCase())).toUpperCase() }}
                            </span>
                          </v-chip>

                        </div>
                        <span v-if="index >= 2"
                              class="grey--text caption">(+{{ selectedTags.length - 2 }})</span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="3" xl="3" md="12" sm="12" class="px-0">
                <v-row dense class="ma-0 justify-end">
                  <v-text-field
                    id="processes-input-text_search" multiple clearable single-line outlined dense
                    class="ma-0 cts-text-box"
                    v-model="searchText"
                    :placeholder="$vuetify.lang.t('$vuetify.processesSearch')"
                    :append-icon="const_icons.SEARCH"
                    :menu-props="selectsProps"
                    :label="$vuetify.lang.t('$vuetify.processesSearch')"
                    :aria-label="$vuetify.lang.t('$vuetify.processesSearch')"
                  >
                    <template v-slot:selection="{ item, index }">
                      <div v-if="index < 2"
                           class="mr-1">
                        <v-chip x-small label
                                class="py-1">
                          <span>{{ item }}</span>
                        </v-chip>
                      </div>
                      <span v-if="index >= 2"
                            class="grey--text caption">
                          (+{{ selectedTags.length - 2 }} {{ $vuetify.lang.t('$vuetify.others') }})
                        </span>
                    </template>
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <div v-if="categories.length != 0">
              <v-row dense
                     v-show="anyResults()"
                     v-for="(category , index) in categories"
                     :key="index">
                <v-slide-y-transition>
                  <v-col cols="12" xl="12" lg="12" md="12" class="mx-lg-auto">
                    <v-row class="ma-0 cts-categories-title">
                      <h3
                        :id="`processes-category_title-${category.name.toLowerCase().replaceAll(' ', '_')}`"
                        class="ma-1 font-weight-regular"
                      >
                        {{ category.name }}
                      </h3>
                    </v-row>
                    <v-row v-if="(typeof category.processes != 'undefined')" class="mx-auto pa-0" dense>
                      <v-fade-transition v-for="(process ,index) in category.processes" :key="index">
                        <v-col
                          @click="handleClickProcessCard(process.tags)"
                          cols="12"
                          xs="12"
                          xl="4"
                          lg="6"
                          md="6"
                          sm="12"
                          class="py-1 cts-process-card"
                        >
                          <processCard :tags="process.tags" :process="process" :from-processes="true"/>
                        </v-col>
                      </v-fade-transition>
                    </v-row>
                  </v-col>
                </v-slide-y-transition>
              </v-row>
            </div>
            <v-row dense
                   v-show="categories.length == 0"
                   class="ma-0 justify-center pt-8">
              <span class="font-weight-light grey--text">
                 {{$vuetify.lang.t('$vuetify.withoutProcessResults')}}
              </span>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-scroll-y-transition>

  </v-container>
</template>

<script>
  import const_icons from "@/constants/icons"
  import ProcessCard from "../components/sections/processes/processCard"
  import ViewTitle from "../components/structures/viewTitle"
  import { NAMESPACE as PROCESSES_NAMESPACE, GETTERS as PROCESSES_GETTERS, ACTIONS as PROCESSES_ACTIONS } from "@/constants/vuex/processes"
  import { getAvailableProcesses } from "@/services/groupServices"
  import { getObjectWithoutReactivity } from "@/util/utils"
  import const_permissions from "@/constants/permissions"
  import { redirect } from "@/util/router_utils"
  import { checkPermission } from "@/util/store_utils"
  import { ERROR_500 } from "@/constants/router/routes"

  export default {
    name: "Processes",
    components: { ViewTitle, ProcessCard},
    data() {
      return {
        const_icons,
        searchText: null,
        searching: false,
        tags: [],
        categoriesFilter: [],
        selectedTags: [],
        selectedCategory: undefined,
        selectsProps: {
          "closeOnClick": false,
          "closeOnContentClick": true,
          "openOnClick": false
        },
        lang: undefined
      }
    },
    created() {
      if (checkPermission(const_permissions.SECTION_PROCESS)) {
        this.$store.dispatch(PROCESSES_NAMESPACE + "/" + PROCESSES_ACTIONS.A_SET_AVAILABLE_PROCESSES, null)
        getAvailableProcesses().then((response) => {
          if (response.data && Array.isArray(response.data)) {
          this.$store.dispatch(PROCESSES_NAMESPACE + "/" + PROCESSES_ACTIONS.A_SET_AVAILABLE_PROCESSES, response.data)

          } else {
          redirect(ERROR_500)
          }
        })
      } else {

        this.$store.dispatch(PROCESSES_NAMESPACE + "/" + PROCESSES_ACTIONS.A_SET_AVAILABLE_PROCESSES, [])
      }
      this.lang = this.$store.state.location.currentLocation.code
    },
    computed: {
      categories() {
        let self = this
        self.tags = []
        self.categoriesFilter = []
        let categories = getObjectWithoutReactivity(this.$store.getters[PROCESSES_NAMESPACE + "/" + PROCESSES_GETTERS.G_AVAILABLE_PROCESSES_BY_CATEGORIES])

        let filter_categories = null
        if (categories) {
          filter_categories = []
          categories.forEach(cat => {
            self.categoriesFilter.push(cat.name)
            let filter_processes = []
            cat.processes.forEach(process => {
              if (Array.isArray(process.tags)) {
                process.tags.forEach(pt => {

                  if (self.tags.find(t => t == pt) == null) {
                    self.tags.push(pt)
                  }
                })
                if (self.selectedTags.length != 0) {

                  self.selectedTags.forEach(tag => {
                    if (process.tags.find(t => t == tag) != null) {
                      if (filter_processes.find(p => p.name == process.name) == null) {
                        if (self.searchText == null || process.name.toLowerCase().indexOf(self.searchText.toLowerCase()) !== -1) {
                          filter_processes.push(process)
                        }
                      }

                    }
                  })
                } else if (self.searchText == null || process.name.toLowerCase().indexOf(self.searchText.toLowerCase()) !== -1) {
                  filter_processes.push(process)

                }
              }
            })
            cat["processes"] = filter_processes
            if (filter_processes.length != 0 && self.selectedCategory == undefined ||
                (filter_processes.length != 0 && self.selectedCategory != null && cat.name == self.selectedCategory)) {
              filter_categories.push(cat)
            }
          })
        }
        return filter_categories
      }
    },
    methods: {
      handleClickProcessCard(tag){
        this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_PROCESS_TYPE}`, ...tag)
      },
      anyResults() {
        return this.categories.length != 0
      },
    }
  }
</script>

<style scoped>

</style>
