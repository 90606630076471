var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"cts-main-container cts-main-view col-12 pa-0"},[_c('v-scroll-y-transition',[(_vm.categories)?_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 justify-center",attrs:{"dense":""}},[_c('v-col',{staticClass:"cts-view-header",attrs:{"cols":"11","xl":"10","lg":"10","md":"11","sm":"11"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xl":"10","lg":"10","md":"12","sm":"12"}},[_c('view-title',{attrs:{"idName":"processes","icon":_vm.const_icons.PROCESSES,"title":_vm.$vuetify.lang.t('$vuetify.processesViewListTitle'),"subtitle":_vm.$vuetify.lang.t('$vuetify.processesViewListDescription')}})],1)],1)],1)],1),_c('v-row',{staticClass:"ma-0 pa-0 justify-center",attrs:{"dense":""}},[_c('v-col',{staticClass:"white mb-6",attrs:{"cols":"11","lg":"10","xl":"10","md":"11"}},[_c('v-row',{staticClass:"cts-row-processes-filters py-4 justify-space-between px-2",attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","xl":"6","lg":"6","md":"12","sm":"12"}},[_c('v-row',{staticClass:"ma-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0 pr-lg-2",attrs:{"cols":"12","xl":"6","lg":"6","md":"12","sm":"12"}},[_c('v-select',{staticClass:"ma-0 cts-select-box cts-box-filter-tooltip",attrs:{"single-line":"","outlined":"","dense":"","id":"processes-input-select_categories","clearable":"","placeholder":_vm.$vuetify.lang.t('$vuetify.processesCategoriesFilter'),"menu-props":_vm.selectsProps,"items":_vm.categoriesFilter,"label":_vm.$vuetify.lang.t('$vuetify.processesCategoriesFilter'),"aria-label":_vm.$vuetify.lang.t('$vuetify.processesCategoriesFilter')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.length > 35)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"cts-break-word"},'span',attrs,false),on),[_vm._v(_vm._s(item))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item))])]):_c('span',{attrs:{"id":("processes-input-select_categories_" + (item.toLowerCase()))}},[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,865659743),model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","xl":"6","lg":"6","md":"12","sm":"12"}},[_c('v-select',{class:'ma-0 cts-select-box cts-select-tags-'+_vm.lang.toLowerCase(),attrs:{"id":"processes-input-select_tags","multiple":"","clearable":"","single-line":"","outlined":"","dense":"","items":_vm.tags,"placeholder":_vm.$vuetify.lang.t('$vuetify.processesTagFilter'),"menu-props":_vm.selectsProps,"label":_vm.$vuetify.lang.t('$vuetify.processesTagFilter'),"aria-label":_vm.$vuetify.lang.t('$vuetify.processesTagFilter')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-list-item__content"},[_c('div',{staticClass:"v-list-item__title",attrs:{"id":("processes-input-select_tags_" + (item.toLowerCase()))}},[_vm._v(" "+_vm._s((_vm.$vuetify.lang.t('$vuetify.tag_' + item.toLowerCase())).toUpperCase())+" ")])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('div',{staticClass:"ml-1 py-1"},[_c('v-chip',{staticClass:"py-1 cts-select-chip",attrs:{"dark":"","label":""}},[_c('span',{attrs:{"id":("processes-select-tags_" + (item.toLowerCase()))}},[_vm._v(" "+_vm._s((_vm.$vuetify.lang.t('$vuetify.tag_' + item.toLowerCase())).toUpperCase())+" ")])])],1):_vm._e(),(index >= 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedTags.length - 2)+")")]):_vm._e()]}}],null,false,734675815),model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})],1)],1)],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","lg":"3","xl":"3","md":"12","sm":"12"}},[_c('v-row',{staticClass:"ma-0 justify-end",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"ma-0 cts-text-box",attrs:{"id":"processes-input-text_search","multiple":"","clearable":"","single-line":"","outlined":"","dense":"","placeholder":_vm.$vuetify.lang.t('$vuetify.processesSearch'),"append-icon":_vm.const_icons.SEARCH,"menu-props":_vm.selectsProps,"label":_vm.$vuetify.lang.t('$vuetify.processesSearch'),"aria-label":_vm.$vuetify.lang.t('$vuetify.processesSearch')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('div',{staticClass:"mr-1"},[_c('v-chip',{staticClass:"py-1",attrs:{"x-small":"","label":""}},[_c('span',[_vm._v(_vm._s(item))])])],1):_vm._e(),(index >= 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.selectedTags.length - 2)+" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.others'))+") ")]):_vm._e()]}}],null,false,4261806849),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1)],1),(_vm.categories.length != 0)?_c('div',_vm._l((_vm.categories),function(category,index){return _c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.anyResults()),expression:"anyResults()"}],key:index,attrs:{"dense":""}},[_c('v-slide-y-transition',[_c('v-col',{staticClass:"mx-lg-auto",attrs:{"cols":"12","xl":"12","lg":"12","md":"12"}},[_c('v-row',{staticClass:"ma-0 cts-categories-title"},[_c('h3',{staticClass:"ma-1 font-weight-regular",attrs:{"id":("processes-category_title-" + (category.name.toLowerCase().replaceAll(' ', '_')))}},[_vm._v(" "+_vm._s(category.name)+" ")])]),((typeof category.processes != 'undefined'))?_c('v-row',{staticClass:"mx-auto pa-0",attrs:{"dense":""}},_vm._l((category.processes),function(process,index){return _c('v-fade-transition',{key:index},[_c('v-col',{staticClass:"py-1 cts-process-card",attrs:{"cols":"12","xs":"12","xl":"4","lg":"6","md":"6","sm":"12"},on:{"click":function($event){return _vm.handleClickProcessCard(process.tags)}}},[_c('processCard',{attrs:{"tags":process.tags,"process":process,"from-processes":true}})],1)],1)}),1):_vm._e()],1)],1)],1)}),1):_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.categories.length == 0),expression:"categories.length == 0"}],staticClass:"ma-0 justify-center pt-8",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-light grey--text"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.withoutProcessResults'))+" ")])])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }