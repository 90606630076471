var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:" hidden-md-and-down cts-transaction-row",attrs:{"id":"transactions-table-transaction_row_desktop"},on:{"click":function($event){return _vm.openTransaction(_vm.item.id)}}},[_c('td',{staticClass:"text-start cts-transaction-datatable-process"},[_c('v-row',{staticClass:"ma-0 justify-space-between",attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({staticClass:"cts-color-tertiary text-uppercase"},'h4',attrs,false),on),[_c('v-avatar',{staticClass:"cts-process-card-icon ma-1",attrs:{"id":"transactions-table-transaction_row_icon","tile":"","size":"35"}},[_c('icon-base',{attrs:{"icon-name":_vm.item.process_name,"src":_vm.item.process_image,"icon-color":"var(--primary-alternative)","width":"35","height":"35"}})],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.item.process_name))])]),_c('v-col',{staticClass:"cts-transaction-process-info my-auto cts-break-word"},[_c('v-row',{staticClass:"ma-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"cts-process-title cts-break-word ma-0 pa-0",attrs:{"id":"transactions-table-transaction_col_title"}},[(_vm.item.process_name.length > 40)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"cts-process-title cts-break-word",attrs:{"id":"transactions-table-transaction_row_title_compressed"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.item.process_name))])]}}],null,false,308244340)},[_c('span',{attrs:{"id":"transactions-table-transaction_row_title_tooltip"}},[_vm._v(_vm._s(_vm.item.process_name))])]):_c('span',{staticClass:"cts-process-title",attrs:{"id":"transactions-table-transaction_row_title"}},[_vm._v(_vm._s(_vm.item.process_name))])],1)],1),_c('v-row',{staticClass:"ma-0",attrs:{"dense":""}},[(_vm.item.id.indexOf('INVALID') === -1)?_c('v-col',{staticClass:"cts-process-subtitle cts-break-word ma-0 pa-0",attrs:{"id":"transactions-table-transaction_col_subtitle"}},[_c('span',{staticClass:"cts-color-tertiary",attrs:{"id":"transactions-table-transaction_row_subtitle"}},[_vm._v(_vm._s(_vm.clearTransactionId(_vm.item.id)))])]):_c('v-col',{staticClass:"cts-process-subtitle cts-break-word",attrs:{"id":"transactions-table-transaction_col_subtitle_invalid"}},[_c('span',{staticClass:"cts-color-tertiary",attrs:{"id":"transactions-table-transaction_row_subtitle_invalid"}},[_vm._v(_vm._s(_vm.item.id))])])],1)],1)],1)],1),(_vm.item.id != null)?_c('td',{staticClass:"text-start cts-transaction-client-code"},[(_vm.item.contact_id.length > 15)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({staticClass:"cts-color-tertiary cts-break-word"},'h4',attrs,false),on),[_vm._v(_vm._s(_vm.item.contact_id))])]}}],null,false,2276398063)},[_c('span',[_vm._v(_vm._s(_vm.item.contact_id))])]):_c('h4',{staticClass:"cts-color-tertiary",attrs:{"id":"transactions-table-transaction_client_name"}},[_vm._v(_vm._s(_vm.item.contact_id))])],1):_vm._e(),_c('td',{staticClass:"text-start cts-transaction-datatable-client-name"},[(_vm.item.contact_complete_name.length > 15)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({staticClass:"cts-color-tertiary cts-break-word"},'h4',attrs,false),on),[_vm._v(_vm._s(_vm.item.contact_complete_name))])]}}],null,false,1425102515)},[_c('span',[_vm._v(_vm._s(_vm.item.contact_complete_name))])]):_c('h4',{staticClass:"cts-color-tertiary cts-break-word",attrs:{"id":"transactions-table-transaction_fullname"}},[_vm._v(" "+_vm._s(_vm.item.contact_complete_name))])],1),_c('td',{staticClass:"text-start cts-transaction-datatable-date"},[_c('h4',{staticClass:"cts-color-tertiary",attrs:{"id":"transactions-table-transaction_created_at"}},[_vm._v(" "+_vm._s(_vm.dayjs.unix(_vm.item.created_at).format(_vm.const_global.DATE_TIME_FORMAT_A))+" ")])]),_c('td',{staticClass:"text-start cts-transaction-datatable-state"},[(_vm.item.state.length > 15)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({staticClass:"cts-color-tertiary cts-break-word"},'h4',attrs,false),on),[_vm._v(_vm._s(_vm.item.state))])]}}],null,false,2253954122)},[_c('span',[_vm._v(_vm._s(_vm.item.state))])]):_c('h4',{staticClass:"cts-color-tertiary cts-break-word",attrs:{"id":"transactions-table-transaction_state"}},[_vm._v(_vm._s(_vm.item.state))])],1),_c('td',{staticClass:"text-center cts-transaction-datatable-certificate"},[(_vm.item.certification != '0' && _vm.checkPermission(_vm.const_permissions.TRANSACTION_DOWNLOAD_CERTIFICATE))?_c('download-certificate',{staticClass:"d-block mx-auto",attrs:{"id":"transactions-table-transaction_download_certificate","buttonDisabled":_vm.item.certification != '0',"hash":_vm.item.certification,"mode":"icon"}}):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',[_c('v-icon',_vm._g(_vm._b({staticClass:"d-block mx-auto",staticStyle:{"cursor":"default"},attrs:{"color":"var(--input-icons)"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.const_icons.DOWNLOAD_DOCUMENT)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.withoutCertificated')))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }