<template>
  <v-card class="mx-auto my-2 cts-card-transaction hidden-lg-and-up" max-width="95%" outlined
          @click="openTransaction(item.id)">
    <v-list-item class="cts-list-item-card px-2 px-sm-2">
      <v-list-item-avatar class="hidden-xs-only">
        <v-avatar tile class="cts-process-card-icon ml-2 cts-bgcolor-clear" size="30">
          <icon-base :icon-name="item.process_name" :src="item.process_image"
                     icon-color="var(--primary-alternative)"
                     width="30" height="30"></icon-base>
        </v-avatar>

      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <v-row dense class="ma-0 justify-space-between">
            <v-col cols="1" class="hidden-sm-and-up pa-0 ma-0">
              <icon-base class="mx-0 my-1" :icon-name="item.process_name"
                         :src="item.process_image"
                         icon-color="var(--primary-alternative)" width="20" height="20"
                         style="vertical-align: sub"></icon-base>
              <v-spacer></v-spacer>
              <span class="text-center">{{ getInitials(item.process_name).toUpperCase() }}</span>
            </v-col>
            <v-col cols="11" class="pa-0 ma-0 hidden-sm-and-up">
              <v-row dense class="ma-0 pa-0">
                <v-col class="ma-0 pa-0" cols="12">
                  <h4 class="cts-font-size-5 font-weight-bold cts-white-space pa-1">
                    <span class="cts-font-size-5 font-weight-bold cts-white-space pr-1">
                      {{ dayjs.unix(item.created_at).format(const_global.DATE_TIME_FORMAT_A) }}
                    </span> - <span class="cts-font-size-5 font-weight-bold cts-white-space px-1">
                    {{ item.contact_id }} </span></h4>
                  <h4 class="cts-font-size-5 cts-white-space cts-color-tertiary cts-color-darken pl-1 pb-2 mb-2"
                      style="border-bottom: 1px solid var(--card-border)">{{ item.contact_complete_name }}</h4>
                </v-col>
              </v-row>
              <v-row dense class="ma-0 hidden-sm-and-up justify-space-between">
                <v-col cols="12" class="px-1">
                  <span class="cts-font-size-5 cts-white-space pa-0">{{ item.state }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pa-0 ma-0 hidden-xs-only" cols="8">

              <h4 class="cts-font-size-5 font-weight-bold cts-white-space cts-color-primary-alternative">{{
                  item.process_name
                }}</h4>
              <span class="cts-font-size-5 font-weight-bold cts-white-space">{{ item.contact_id }} </span>
              {{ " - " + item.contact_complete_name }}
            </v-col>

            <v-col class="pa-0 ma-0 hidden-xs-only" cols="4">
              <h4 class="cts-font-size-5 font-weight-bold cts-white-space float-right">
                {{ $vuetify.lang.t('$vuetify.createdDate') }}: {{ dayjs.unix(item.created_at).format(const_global.DATE_TIME_FORMAT_A) }}
              </h4>
            </v-col>
          </v-row>
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-row dense class="ma-0 justify-space-between hidden-xs-only">
            <h4 v-if="item.id"
                class="cts-color-tertiary cts-transaction-datatable-contact-name cts-font-size-5 ">{{
                item.id.split(".").pop()
              }}</h4>
            <h4 v-else
                class="cts-color-tertiary cts-transaction-datatable-contact-name cts-font-size-5 ">
              {{ $vuetify.lang.t('$vuetify.transactionWithoutId') }}</h4>
            <span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <h4 class="cts-color-tertiary  font-weight-bold cts-font-size-5" v-bind="attrs"
                      v-on="on">{{ item.state }}</h4>
                </template>
                <span>{{ item.state }}</span>
              </v-tooltip>
            </span>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>

    </v-list-item>
  </v-card>
</template>

<script>
import { getInitials } from "@/util/utils"
import const_global from "@/constants/global"
import IconBase from "../../../assets/images/IconBase"
import const_permissions from "@/constants/permissions"
import { checkPermission } from "@/util/store_utils"

export default {
  name: "transactionsListRowMobile",
  components: {IconBase},
  props: {
    item: Object
  },
  data() {
    return {
      const_global,
      getInitials
    }
  },
  methods: {
    openTransaction(id) {

      if (checkPermission(const_permissions.SECTION_TRANSACTION)) {
        this.$emit('clicked', id)
      }
    }
  }
}
</script>

<style scoped>

</style>
