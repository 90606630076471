<template>
  <v-container class="cts-main-view col-12">
    <v-row v-if="transaction_content" class="ma-0" dense>
      <v-col class="pa-0" cols="12">
        <v-row class="ma-0 pa-0 justify-center" dense>
          <v-col class="mx-auto cts-view-header" cols="11" lg="10">
            <v-row class="cts-breadcrumbs ma-0 mt-1 pa-0" dense>
              <a :title="$vuetify.lang.t('$vuetify.allTransactions')" @click.prevent="backToMenu()">{{
                  $vuetify.lang.t('$vuetify.transactions')
                }} </a>
              <span class="cts-color-black_soft_text font-weight-regular ml-1">/ {{
                  $vuetify.lang.t('$vuetify.transactionDetails')
                }} </span>
            </v-row>
            <view-title
              :icon="const_icons.TRANSACTION"
              :subtitle="`${transaction_content.process_name} | ${$vuetify.lang.t('$vuetify.transactionId')}: ${transaction_content.transaction_id.split('.').pop()}`"
              :title="$vuetify.lang.t('$vuetify.transactionDetails')"
              idName="transaction-detail"
            />

          </v-col>
        </v-row>
        <v-row class="pa-0 justify-center">
          <v-col class="px-4" cols="11" lg="10" xl="10">
            <v-row class="ma-0 pb-0" dense>
              <v-col class="pa-0" md="4">
                <v-row class="ma-0 justify-space-between" dense>
                  <section-title
                    :text="$vuetify.lang.t('$vuetify.transactionSummary')"
                    icon="assignment"
                    idName="transaction-detail"
                  >
                  </section-title>
                </v-row>
              </v-col>
              <v-col class="pa-0 hidden-md-and-down" cols="12" md="8">
                <v-row class="ma-0 mt-2 justify-end" dense>
                  <v-btn
                    v-if="transaction_content.postamail_data && Object.values(transaction_content.postamail_data)[0].tracking_url"
                    class="cts-button-standard my-auto" small @click="openTracking()">

                    {{ $vuetify.lang.t('$vuetify.transactionTracking') }}
                    <v-icon aria-hidden="false" class="float-right" small>local_shipping</v-icon>
                  </v-btn>
                  <download-certificate
                    v-if="transaction_content.certification && transaction_content.certification !== '0' && transaction_content.certification !== '' && checkPermission(const_permissions.TRANSACTION_DOWNLOAD_CERTIFICATE)"
                    :hash="transaction_content.certification"
                    class="my-auto ml-2"
                    mode="button"
                  />
                  <v-btn
                    v-if="transaction_content.postamail_data && Object.values(transaction_content.postamail_data)[0].pee"
                    class="cts-button-standard my-auto ml-2"
                    small @click="downloadPEE()">

                    {{ $vuetify.lang.t('$vuetify.transactionDownloadPee') }}
                    <v-icon aria-hidden="false" class="float-right" small>approval</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
              <v-col class="pa-0 hidden-lg-and-up" cols="12" md="12">
                <v-row class="my-2 justify-space-between" dense>
                  <v-col
                    v-if="transaction_content.postamail_data && Object.values(transaction_content.postamail_data)[0].tracking_url"
                    cols="12"
                    small @click="openTracking()">
                    <v-btn class="cts-button-standard-mobile">
                      {{ $vuetify.lang.t('$vuetify.transactionTracking') }}
                      <v-icon aria-hidden="false" class="float-right" small>local_shipping
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="transaction_content.postamail_data && Object.values(transaction_content.postamail_data)[0].pee"
                    cols="12">
                    <v-btn class="cts-button-standard-mobile my-2" @click="downloadPEE()">
                      {{ $vuetify.lang.t('$vuetify.transactionDownloadPee') }}
                      <v-icon aria-hidden="false" class="float-right" small>approval</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="transaction_content.certification !== '0' && checkPermission(const_permissions.TRANSACTION_DOWNLOAD_CERTIFICATE)"
                    cols="12">
                    <download-certificate
                      :hash="transaction_content.certification"
                      :mobile="true"
                      mode="button"
                    >
                    </download-certificate>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="ma-0 pb-0" dense>
              <v-col class="pa-0" md="12">
                <div class="hidden-md-and-down cts-w-100">
                  <v-row class="col-11 mx-auto cts-transaction-state" dense>
                    <!-- col title document -->
                    <v-col cols="12" data-col-title-data>
                      <h5 class="px-0 mt-0 font-weight-medium text-uppercase cts-color-tertiary">
                        {{ $vuetify.lang.t('$vuetify.transactionData') }}</h5>
                    </v-col>

                    <!-- col date creation -->
                    <v-col class="col-12 col-lg-6 col-xl-6" data-col-date>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{ $vuetify.lang.t('$vuetify.transactionLaunchedDate') }}:</span>
                          <span v-if="transaction_content.last_event_ts !== 0"
                                class="cts-transaction-state-value">
                            {{ dayjs.unix(transaction_date_creation).format(const_global.DATE_TIME_FORMAT_A) }}
                          </span>
                          <span v-else
                                class="cts-transaction-state-value">{{ $vuetify.lang.t('$vuetify.withoutData') }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{ $vuetify.lang.t('$vuetify.processName') }}:</span>
                          <span
                            class="cts-transaction-state-value">{{ transaction_content.process_name }} </span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{ $vuetify.lang.t('$vuetify.usernameTransactionDetail') }}:</span>
                          <span
                            class="cts-transaction-state-value">{{ transaction_content.username }} </span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{ $vuetify.lang.t('$vuetify.transactionState') }}:</span>
                          <span
                            class="cts-transaction-state-value">{{ capitalize(transaction_content.state) }} </span>
                        </v-col>
                      </v-row>
                      <v-row v-if="transaction_content.last_event !== 'Instance_Delete'"
                             class="ma-0 pa-0"
                             dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{ $vuetify.lang.t('$vuetify.transactionLastEvent') }}:</span>
                          <span v-if="transaction_content.last_event !== ''"
                                class="cts-transaction-state-value">{{
                              capitalize(transaction_content.last_event)
                            }}</span>
                          <span v-else
                                class="cts-transaction-state-value">{{ $vuetify.lang.t('$vuetify.withoutData') }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{ $vuetify.lang.t('$vuetify.transactionLastEventDate') }}:</span>
                          <span v-if="transaction_content.last_event_ts !== 0"
                                class="cts-transaction-state-value">
                            {{ dayjs.unix(transaction_content.last_event_ts).format(const_global.DATE_TIME_FORMAT_A) }}
                          </span>
                          <span v-else
                                class="cts-transaction-state-value">{{ $vuetify.lang.t('$vuetify.withoutData') }}</span>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- col email -->
                    <v-col class="col-12 col-lg-6 col-xl-6" data-col-email>
                      <v-row
                        v-for="(parameter, index) in filteredTransactionParameters"
                        :key="index"
                        class="ma-0 pa-0"
                        dense
                      >
                        <v-col class="px-0" cols="12" lg="12" md="12" sm="12" xl="12">
                          <span class="cts-transaction-state-title">{{ parameter.translation }}:</span>
                          <span class="cts-transaction-state-value">{{ parameter.value }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- dialog pdf/html viewer -->
                  <v-dialog
                    v-if="showDocumentViewer"
                    v-model="showDocumentViewer"
                    max-width="1200"
                    @click:outside='handleClickModalDocument'
                  >

                    <v-card class="pa-2" tile>
                      <v-card-title class="col-lg-12 pa-2 cts-dialog-terms-header">
                        <dialog-title
                          :close="true"
                          :text="documentData.file_name"
                          event="closeModal"
                          icon="attach_file"
                          @close="handleClickModalDocument"
                        />
                      </v-card-title>
                      <v-card-text class="pb-0 mt-4">
                        <documentsViewer
                          :documentType='documentViewerFileType.split("/")[0]'
                          :fileContent="isPdf(documentData.file_name) ? `data:application/pdf;base64,${documentData.file_content}` : `data:application/text;base64,${documentData.file_content}`"
                          :fileName="documentData.file_name"
                        />
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <!--  -->

                  <!-- row datos de contacto -->
                  <v-row class="col-11 mx-auto cts-transaction-contact" dense>
                    <v-col cols="12">
                      <h5 class="px-0 mt-0 font-weight-medium text-uppercase cts-color-tertiary">
                        {{ $vuetify.lang.t('$vuetify.transactionContact') }}</h5>
                    </v-col>
                    <v-row class="ma-0" dense>
                      <v-col v-for="(value, key) in transaction_recipient_data" :key="key" lg="6"
                             md="6" sm="6" xl="6">
                        <v-row class="ma-0" dense>
                                                    <span
                                                      class="cts-transaction-state-title">{{ $vuetify.lang.t('$vuetify.' + key) }}:</span>
                          <span
                            v-if="value === '' || value.indexOf('+') !== -1 && (value.replace(' ','').length === 3 || value.replace(' ','').length === 3) "
                            class="cts-transaction-state-value">{{ $vuetify.lang.t('$vuetify.notInformed') }}</span>
                          <span v-else class="cts-transaction-state-value">{{ value }}</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-row>

                  <!-- row recursos - documents -->
                  <v-row v-if="documentsList.length > 0"
                         class="col-11 mx-auto cts-transaction-contact">
                    <v-col class="col-12 pa-0 col-lg-4 col-xl-4">
                      <v-col class="pl-0 pb-0" cols="12">
                        <h5
                          class="px-0 mt-0 font-weight-medium text-uppercase cts-color-tertiary">
                          {{ $vuetify.lang.t('$vuetify.resources') }}</h5>
                      </v-col>
                      <v-col class="col-12 col-lg-4 col-xl-4" data-col-date>
                        <v-row>
                          <ul class="list-resources d-flex flex-column pa-0">
                            <li
                              v-for='(document, id) in documentsList'
                              :key='id'
                            >
                              <div
                                class="document-card--container d-flex align-center   justify-space-between">
                                <div class="d-flex align-center" @click="handleClickDocument(document)">
                                  <img
                                    :src="documentImgSrc(document.type)"
                                    alt="Document"
                                    class="document-img"
                                  />
                                  <v-tooltip
                                    bottom
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <p
                                        class="cts-transaction-state-value mb-0"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        {{ getDocumentName(document.key) }}
                                      </p>

                                    </template>
                                    <span> {{ getDocumentName(document.key) }} </span>
                                  </v-tooltip>
                                </div>
                                <div>
                                  <button
                                    :disabled='isBtnDisabled'
                                    class="button-preview d-flex align-center"
                                    @click="handleClickDocument(document)"
                                  >
                                    <v-tooltip
                                      bottom
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          class="float-right"
                                          color="lighten"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          {{ ["pdf", "html"].includes(document.type.toLowerCase()) || Mime.getType(document.type).split("/")[0] === "image" ? const_icons.PREVISUALIZATION : "download" }}
                                        </v-icon>
                                      </template>
                                      <span>
                                        {{ $vuetify.lang.t('$vuetify.viewDocument') }}</span>
                                    </v-tooltip>
                                  </button>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                </div>
                <div class="hidden-lg-and-up py-4">
                  <v-expansion-panels accordion class="elevation-0">
                    <v-expansion-panel class="cts-data-info-panel">
                      <v-expansion-panel-header>
                        {{ $vuetify.lang.t('$vuetify.transactionGeneralData') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{ $vuetify.lang.t('$vuetify.transactionLaunchedDate') }}:</span>
                            <span v-if="transaction_content.last_event_ts !== 0"
                                  class="cts-transaction-state-value">
                              {{ dayjs.unix(transaction_date_creation).format(const_global.DATE_TIME_FORMAT_A) }}
                            </span>
                            <span v-else
                                  class="cts-transaction-state-value">{{ $vuetify.lang.t('$vuetify.withoutData') }}</span>

                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{ $vuetify.lang.t('$vuetify.processName') }}: </span>
                            <span
                              class="cts-transaction-state-value">{{ transaction_content.process_name }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{ $vuetify.lang.t('$vuetify.usernameTransactionDetail') }}: </span>
                            <span
                              class="cts-transaction-state-value">{{ transaction_content.username }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{ $vuetify.lang.t('$vuetify.transactionState') }}:</span>
                            <span
                              class="cts-transaction-state-value">{{ capitalize(transaction_content.state) }} </span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{ $vuetify.lang.t('$vuetify.transactionLastEvent') }}:</span>
                            <span v-if="transaction_content.last_event !== ''"
                                  class="cts-transaction-state-value">{{
                                capitalize(transaction_content.last_event)
                              }}</span>
                            <span v-else
                                  class="cts-transaction-state-value">{{ $vuetify.lang.t('$vuetify.withoutData') }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{ $vuetify.lang.t('$vuetify.transactionLastEventDate') }}:</span>
                            <span v-if="transaction_content.last_event_ts !== 0"
                                  class="cts-transaction-state-value">
                              {{ dayjs.unix(transaction_content.last_event_ts).format(const_global.DATE_TIME_FORMAT_A) }}
                            </span>
                            <span v-else
                                  class="cts-transaction-state-value">{{ $vuetify.lang.t('$vuetify.withoutData') }}</span>
                          </v-col>
                        </v-row>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-expansion-panels accordion class="elevation-0 mt-4">
                    <v-expansion-panel class="cts-data-info-panel">
                      <v-expansion-panel-header>
                        {{ $vuetify.lang.t('$vuetify.transactionData') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row
                          v-for="(parameter, index) in filteredTransactionParameters"
                          :key="index"
                          class="ma-0 pa-0"
                          dense
                        >
                          <v-col
                            class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                            <span class="font-weight-bold mr-2">{{ parameter.key }}:</span>
                            <span class="cts-transaction-state-value">{{ parameter.value }}</span>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <!-- expansion panel contact -->
                  <v-expansion-panels accordion class="elevation-0 mt-4">
                    <v-expansion-panel class="cts-data-info-panel">
                      <v-expansion-panel-header>{{ $vuetify.lang.t('$vuetify.transactionContact') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="ma-0" dense>
                          <v-col v-for="(value, key) in transaction_recipient_data" :key="key"
                                 cols="12">
                            <v-row class="ma-0" dense>
                              <span class="cts-transaction-state-title">{{ $vuetify.lang.t('$vuetify.' + key) }}:</span>
                              <span
                                v-if="value === '' || value.indexOf('+') !== -1 && (value.replace(' ','').length === 3 || value.replace(' ','').length === 3) "
                                class="cts-transaction-state-value">{{ $vuetify.lang.t('$vuetify.notInformed') }}</span>
                              <span v-else class="cts-transaction-state-value">
                                <a v-if="key === const_global.CONTACT_PHONE" :href="'tel:' + value"
                                   class="cts-link">{{ value }}</a>
                                <span v-else>{{ value }}</span>
                              </span>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <!-- expansion panel resources resoruces -->
                  <v-expansion-panels v-if="documentsList.length > 0" accordion
                                      class="elevation-0 mt-4">
                    <v-expansion-panel class="cts-data-info-panel">
                      <v-expansion-panel-header>
                        {{ $vuetify.lang.t('$vuetify.resources') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>

                        <v-row>
                          <v-col class="pt-0">

                            <v-row class="mt-2">
                              <ul class="list-resources d-flex flex-column">
                                <li
                                  v-for='(document, id) in documentsList'
                                  :key='id'
                                >
                                  <div
                                    class="document-card--container d-flex align-center   justify-space-between">
                                    <div class="d-flex align-center">
                                      <img
                                        :src="documentImgSrc(document.type)"
                                        alt="Document"
                                        class="document-img"
                                      />
                                      <v-tooltip
                                        bottom
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }">
                                          <p
                                            class="cts-transaction-state-value title-doc-expandible mb-0"
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            {{
                                              getDocumentName(document.key)
                                            }}
                                          </p>

                                        </template>
                                        <span>  {{
                                            getDocumentName(document.key)
                                          }}</span>
                                      </v-tooltip>
                                    </div>
                                    <div>
                                      <button
                                        class="button-preview d-flex align-center"
                                        @click="handleClickDocument(document)"
                                      >
                                        <v-tooltip
                                          bottom
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }">
                                            <v-icon
                                              class="float-right"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              {{ const_icons.PREVISUALIZATION }}
                                            </v-icon>
                                          </template>
                                          <span> {{
                                              $vuetify.lang.t('$vuetify.viewDocument')
                                            }}</span>
                                        </v-tooltip>
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-col>
            </v-row>
            <v-col v-if="checkPermission(const_permissions.TRANSACTION_SHOW_EVENTS)" class="pa-0">
              <v-row class="ma-0 pa-0" dense>
                <v-col class="pa-0" md="12">
                  <section-title
                    :text="$vuetify.lang.t('$vuetify.transactionEvents')"
                    icon="fact_check"
                    idName="transaction-detail"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="transaction_days.length !== 0 && checkPermission(const_permissions.TRANSACTION_SHOW_EVENTS)"
              class="pa-0 mb-6"
            >
              <v-row
                v-for="(day, key) in transaction_days"
                :key="key"
                class="ma-0 py-0 justify-center"
                dense
              >
                <v-col class="px-0">
                  <v-row class="cts-transaction-event-row-date mx-0 my-4" dense>
                    <h4 class="px-0 mt-0 font-weight-medium text-uppercase cts-color-tertiary">
                      <v-icon aria-hidden="false" class="pr-1" small>calendar_today</v-icon>
                      {{ day["day_name"] }}
                    </h4>
                  </v-row>
                  <transaction-event-row
                    v-for="(item, i) in day.events"
                    :key="i"
                    :event="item"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else>
              <h5 v-if="checkPermission(const_permissions.TRANSACTION_SHOW_EVENTS)"
                  class="cts-color-tertiary">
                {{ $vuetify.lang.t('$vuetify.transactionWithoutEvents') }}</h5>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else class="ma-0 justify-center" dense>
      <v-col cols="11" lg="10">
        <loading-container/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import const_global from "@/constants/global"
import const_icons from "@/constants/icons"
import ViewTitle from "@/components/structures/viewTitle"
import SectionTitle from "@/components/sections/processes/processView/sectionTitle"
import DownloadCertificate from "@/components/structures/downloadCertificate"
import TransactionEventRow from "./transactionEventRow"
import {
  ACTIONS as TRANSACTIONS_ACTIONS,
  NAMESPACE as TRANSACTIONS_NAMESPACE,
  STATE as TRANSACTIONS_STATE
} from "@/constants/vuex/transactions"
import const_permissions from "@/constants/permissions"
import LoadingContainer from "@/components/structures/loadingContainer"
import DocumentsViewer from "@/components/documentsViewer"
import DialogTitle from "@/components/structures/dialogTitle"
import {ServerBus} from "@/main"
import {getDocumentBase64} from "@/services/otherServices"
import {getTransactionInfo, getTransactionPEE} from "@/services/transactionServices"
import {redirect} from "@/util/router_utils"
import {checkPermission} from "@/util/store_utils"
import {TRANSACTIONS} from "@/constants/router/routes"
import {capitalize, documentIsGivenExtension, generateFileDownload, showToast} from "@/util/utils"
import Mime from "@/plugins/mime"

const dayjs = require('dayjs')

export default {
  name: "transactionView",
  components: {
    LoadingContainer,
    TransactionEventRow,
    DownloadCertificate, SectionTitle, ViewTitle,
    DocumentsViewer,
    DialogTitle
  },
  data() {
    return {
      const_icons,
      const_global,
      checkPermission,
      const_permissions,
      transaction_recipient_data: {},
      transaction_days: [],
      transaction_date_creation: null,
      showDocumentViewer: null,
      documentData: {},
      showTooltipText: false,
      isBtnDisabled: false,
      dayjs,
      Mime
    }
  },
  created() {
    getTransactionInfo().then((response) => {
      this.$store.dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_TRANSACTION}`, response.data)
    })
    ServerBus.$on("closeModal", () => {
      this.handleClickModalDocument()
    })
  },
  computed: {
    filteredTransactionParameters() {
      let behaviourLayer
      try {
        behaviourLayer = JSON.parse(this.transaction_content.behavior_layer)
      } catch {
        behaviourLayer = null
      }

      const hiddenParameters = behaviourLayer?.transactionDetailView?.hiddenParameters || []

      return this.transaction_content.parameters.filter(x => !hiddenParameters.includes(x.key))
    },

    documentViewerFileType() {
      if (!this.documentData.file_name) return null

      if (documentIsGivenExtension(this.documentData.file_name, "pdf")) {
        return "pdf"
      }

      if (documentIsGivenExtension(this.documentData.file_name, "html")) {
        return "html"
      }

      return Mime.getType(this.documentData.file_name.split(".")[1])
    },

    transaction_content() {
      let tc = this.$store.state[TRANSACTIONS_NAMESPACE][TRANSACTIONS_STATE.S_TRANSACTION]
      if (tc) {
        if (Object.prototype.hasOwnProperty.call(tc, const_global.EVENTS)) {
          this.formatEvents(tc)
        }
        this.formatRecipientData(tc)
      }
      if (tc != null && tc.parameters != null && tc.parameters["_COST_CENTER_"] != null) {
        delete tc.parameters["_COST_CENTER_"]
      }
      return tc
    },

    documentsList() {
      const docList = []

      if (this.transaction_content.files == null) {
        return docList
      }

      const transactionParameters = Object.values(this.transaction_content.files)

      transactionParameters.forEach(element => {
        if (Array.isArray(element)) {
          element.forEach(value => {
            if (this.isDocument(value)) {
              const documentType = value.substring(value.lastIndexOf('.') + 1)
              docList.push({
                "key": value,
                "type": documentType
              })
            }
          })

        } else if (this.isDocument(element)) {
          const documentType = element.substring(element.lastIndexOf('.') + 1)

          docList.push({
            "key": element,
            "type": documentType
          })
        } else {
          console.log("Document not supported", element)
        }
      })

      return docList
    },
  },
  methods: {
    handleClickModalDocument() {
      this.showDocumentViewer = false
      this.isBtnDisabled = false
    },

    getDocumentName(documentKey) {
      return documentKey.substring(documentKey.lastIndexOf('/') + 1)
    },

    documentImgSrc(documentType) {
      if (documentType.toLowerCase() === 'pdf') {
        return require('@/assets/images/icons/pdf.png')
      }

      if (documentType.toLowerCase() === 'html') {
        return require('@/assets/images/icons/html.png')
      }

      return require('@/assets/images/icons/other-docs.png')
      // return documentType.toLowerCase() === 'pdf' ? require('@/assets/images/icons/pdf.png') : require('@/assets/images/icons/html.png')
    },

    documentViewerLoadData(fileName, fileContent) {
      this.documentData.file_name = fileName
      this.documentData.file_content = fileContent
    },

    async handleClickDocument(document) {
      this.isBtnDisabled = true
      this.documentData = {}

      let fileObject
      try {
        fileObject = await getDocumentBase64(document.key)
        fileObject = fileObject.data

        if (documentIsGivenExtension(fileObject.file_name, "pdf") || documentIsGivenExtension(fileObject.file_name, "html")) {
          const fileContent = this.htmlReplaceFields(fileObject, this.transaction_recipient_data)
          this.documentViewerLoadData(fileObject.file_name, fileContent)
          this.showDocumentViewer = true

        } else if (Mime.getType(fileObject.file_name.split(".")[1]).split("/")[0] === "image") {
          this.documentViewerLoadData(fileObject.file_name, fileObject.file_content)
          this.showDocumentViewer = true
        } else {
          generateFileDownload(fileObject.file_content, fileObject.file_name, this.documentViewerFileType)
        }

      } catch (error) {
        showToast(error, this.$vuetify.lang.t(`$vuetify.${error.message}`))
      }
      this.isBtnDisabled = false
    },

    htmlReplaceFields(html, recipient) {
      let editedHTML = ""
      let decodedHTML = atob(html.file_content)

      const fieldsToReplace = [...new Set(decodedHTML.match(/%%\w+%%/ig))]

      if (fieldsToReplace.length > 0) {
        fieldsToReplace.forEach(x => {
          let replacement = recipient[`contact_${x.replace(/%%/g, "")}`]

          if ([const_global.FIELD_NAME, const_global.FIELD_SURNAME].includes(x.replace(/%%/g, ""))) {
            replacement = recipient[`contact_${x.replace(/%%/g, "")}`].charAt(0).toUpperCase() + recipient[`contact_${x.replace(/%%/g, "")}`].slice(1)
          }
          editedHTML = decodedHTML.replaceAll(x, replacement)
        })

        return btoa(editedHTML)
      }

      return html.file_content
    },

    isPdf(value) {
      return documentIsGivenExtension(value, "pdf")
    },

    isDocument(value) {
      return typeof value == "string" && value !== ""
    },

    formatEvents(tc) {
      let arr_aux = []
      let dates = []

      if (tc.events !== null && typeof tc.events !== 'undefined') {

        tc.events.forEach((event) => {
          if (event.event !== "Instance_Delete") {
            if (event.event_at != null) {
              dates.push(event.event_at)
            }

            let day = {}
            const day_name = dayjs.unix(event.event_at).format(const_global.DATE_TIME_FORMAT_B)

            if (arr_aux.find(d => d.day_name === day_name) === undefined) {
              day["day_name"] = day_name
              day["day_at"] = event.event_at
              day["events"] = []
              arr_aux.push(day)
            }
            arr_aux.find(d => d.day_name === day_name)["events"].push(event)


          }
        })

        arr_aux.forEach(day => {
          day.events.sort((a, b) => {
            return b.event_at - a.event_at;
          });
        })
        dates.sort((a, b) => {
          return a - b;
        });

        this.transaction_date_creation = Math.min(...dates)
        arr_aux.sort((a, b) => {
          return b.day_at - a.day_at;
        });
        this.transaction_days = arr_aux

      }
    },
    formatRecipientData(tc) {
      Object.entries(tc.recipient_data).forEach(([key, value]) => {
        if (key !== const_global.CONTACT_COUNTRY_CODE) {
          this.transaction_recipient_data[key] = key === const_global.CONTACT_PHONE ? `+${tc.recipient_data[const_global.CONTACT_COUNTRY_CODE]} ${value}` : value
        }
      })
    },
    downloadPEE() {
      getTransactionPEE(Object.keys(this.transaction_content.postamail_data)[0])
    },
    openTracking() {
      window.open(Object.values(this.transaction_content.postamail_data)[0].tracking_url, "_blank")
    },
    backToMenu() {
      redirect(TRANSACTIONS)
    },
    capitalize
  },
  beforeDestroy() {
    ServerBus.$off("closeModal")
  }

}
</script>
