export default {
    badge: 'Label',
    close: 'Sluiten',
    dataIterator: {
        noResultsText: 'Geen enkel element komt overeen met zoekargument',
        loadingText: 'Aan het laden ...'
    },
    dataTable: {
        itemsPerPageText: 'Rijen per pagina',
        ariaLabel: {
            sortDescending: ': Sortering hoog naar laag. Klik om sortering te verwijderen',
            sortAscending: ': Sortering laag naar hoog. Klik voor sortering van hoog naar laag.',
            sortNone: ': Zonder sortering. Klik voor sortering van laag naar hoog.',
            activateAscending: 'Oplopend activeren',
            activateDescending: 'Aflopend activeren',
            activateNone: 'Alles deactiveren'
        },
        sortBy: 'Gesorteerd op'
    },
    dataFooter: {
        itemsPerPageText: 'Elementen per pagina',
        itemsPerPageAll: 'Alles',
        nextPage: 'Volgende pagina',
        prevPage: 'Vorige pagina',
        firstPage: 'Eerste pagina',
        lastPage: 'Laatste pagina',
        pageText: '{0}-{1} van {2}'
    },
    datePicker: {
        itemsSelected: '{0} geselecteerd',
        prevMonthAriaLabel: 'vorige maand',
        nextMonthAriaLabel: 'volgende maand'
    },
    noDataText: 'Er zijn geen beschikbare datums',
    carousel: {
        prev: 'Vorige view',
        next: 'Volgende view',
        ariaLabel: {
            delimiter: 'Carrousel-slider {0} van {1}'
        }
    },
    calendar: {
        moreEvents: '{0} meer'
    },
    fileInput: {
        counter: '{0} bestanden',
        counterSize: '{0} bestanden ({1} in totaal)'
    },
    timePicker: {
        am: 'AM',
        pm: 'PM'
    },
    home: 'Opstart',
    templates: 'Flows',
    accounts: 'Accounts',
    processes: 'Processen',
    transactions: 'Transacties',
    statistics: 'Statistieken',
    support: 'Support',
    contacts: 'Contacten',
    administration: 'Beheer',
    search: 'Zoeken',
    appearance: 'Stijlen',
    users: 'Gebruikers',
    alastria: 'Alastria',
    billing: 'Facturering',
    account: 'Mijn account beheren',
    admin: 'Beheer',
    demos: 'Demo\'s',
    selectedSubaccount: 'Subaccount geselecteerd',
    withoutSubaccount: 'Zonder subaccounts',
    groups: 'groepen',
    filterDate: 'Datumfilter',
    subaccount: 'Subaccount',
    subaccounts: 'Subaccounts',
    costCenters: 'Kostenplaatsen',
    subaccountsAssigned: 'Toegewezen subaccounts',
    defaultCostCenter: 'Default kostenplaats',
    selectedCostCenter: 'Geselecteerde kostenplaats',
    availableCostCenters: 'Beschikbare kostenplaatsen',
    changeDefaultCostCenter: 'Default kostenplaats wijzigen',
    costCenterDialogText: 'U kunt desgewenst de geselecteerde kostenplaats voor de transactie wijzigen. De default kostenplaats wordt hierdoor niet gewijzigd. De gewijzigde kostenplaats wordt alleen op ad-hocbasis voor deze transactie gebruikt.',
    costCenterDialogTextAccount: 'U kunt desgewenst de default kostenplaats wijzigen. Door deze actie wordt de geselecteerde kostenplaats als default ingesteld.',
    costCenterSelectedTitle: 'Geselecteerde kostenplaats',
    activeSubaccount: 'Actief subaccount',
    user: 'Gebruiker',
    userData: 'Gebruikersgegevens',
    userCECOS: 'Kostenplaatsen',
    userCECO: 'Kostenplaats',
    userCodeCECO: 'Code',
    userCECOPrimary: 'Default',
    userCECOCodeTitle: 'Kostenplaatscode',
    password: 'Wachtwoord',
    passwordchange: 'Wachtwoord wijzigen',
    oldpassword: 'Huidig wachtwoord',
    newpassword: 'Nieuw wachtwoord',
    updatePassword: 'Wachtwoord actualiseren',
    repeatpassword: 'Wachtwoord herhalen',
    passwordrequirements: 'Het wachtwoord moet ten minste 8 tekens hebben en minimaal aan 3 van de aanvullende regels voldoen:',
    passwordMinOneLowercase: 'Minimaal 1 kleine letter.',
    passwordMinOneUppercase: 'Minimaal 1 hoofdletter.',
    passwordMinOneNumber: 'Minimaal 1 cijfer.',
    passwordMinOneSpecialChar: 'Minimaal 1 speciaal teken.',
    passwordMinEightChars: 'Minimaal 8 tekens.',
    passwordCompares: 'Vergelijking met de wachtwoordeisen',
    passwordFieldError: 'Fout in het veld, raadpleeg de regels.',
    passwordrequirementsMoreThanEightChars: 'Het wachtwoord moet minstens 8 tekens hebben',
    notequalpasswords: 'De wachtwoorden komen niet overeen',
    samepasswordthanolder: 'Het nieuwe wachtwoord is hetzelfde als het vorige',
    emptyOldPassword: 'U heeft het vorige wachtwoord niet ingevoerd',
    role: 'Rol',
    lastConnection: 'Laatste verbinding',
    languageSelector: 'Kies een taal',
    logout: 'Sessie sluiten',
    others: 'Overige',
    continue: 'Verder',
    continueWSend: 'Verder met verzending',
    sendRecoveryForm: 'Aanvraag verzenden',
    restartFilters: 'Filters opnieuw instellen',
    back: 'Terug',
    backToMethods: 'Methoden voor herstel',
    moreInfo: 'Meer informatie',
    activateuser: 'Gebruiker activeren',
    exit: 'Uitloggen',
    platformVersion: 'Platformversie',
    termsAndConditionsCompliance: 'Algemene Voorwaarden',
    privacyPoliciesCompliance: 'Gegevensbeschermingsbeleid',
    cookiesPoliciesCompliance: 'Cookiebeleid',
    cookiesPoliciesCertySign: 'Cookies op CertySign',
    alertSizeFiles: 'Bestanden mogen in totaal maximaal 8MB zijn',
    login: 'Inloggen',
    noSubaccountsMessage: 'Geen subaccounts toegewezen',
    telephone: 'Telefoon',
    name: 'Voornaam',
    lastName: 'Achternaam',
    email: 'E-mailadres',
    omit: 'Weglaten',
    id: 'Klantreferentie',
    clear: 'Verwijderen',
    add: 'Toevoegen',
    recipients: 'Geadresseerden',
    shipmentData: 'Gegevens van de (ver)zending',
    prevAndSend: 'Voorvertoning en verzending',
    previewFileSize: 'Het bestand mag maximaal 15MB zijn',
    rulesFilesSize: 'Alle bestanden samen mogen maximaal 15MB zijn',
    sectionDataFieldsDescription: 'Onderaan staan de verschillende velden die nodig zijn om de transactie naar de geadresseerden te sturen. De contactvelden worden automatisch ingevuld aan de hand van de gegevens van de geadresseerde',
    sectionDataFieldsNotAditionalFields: 'Dit proces heeft geen extra velden, voltooi de verzending van uw transactie vanuit Voorvertoning.',
    sectionPreviewSubtitle: 'Onderaan kunt u navigeren tussen de geadresseerden om elke zending afzonderlijk te controleren',
    manual_version: 'De meest recente versie van de gebruikershandleiding wordt getoond.',
    view_login: 'Inloggen',
    view_transactions: 'Transacties',
    view_transaction: 'Detailgegevens van de transactie',
    view_process: 'Proces starten',
    view_processes: 'Processen',
    view_statistics: 'Statistieken',
    view_support: 'Support en Help',
    view_administration: 'Beheer',
    view_groups: 'Groepen',
    view_group: 'Groepsgegevens',
    view_users: 'Gebruikers',
    view_account: 'Mijn account',
    view_remember_password: 'Wachtwoord herinneren',
    view_change_password: 'Wachtwoord wijzigen',
    view_activate_user: 'Gebruiker activeren',
    view_new_password: 'Nieuw wachtwoord',
    view_contacts: 'Contacten',
    view_user: 'Gebruikersgegevens',
    view_withoutsubaccounts: '',
    view_forbidden: '',
    view_root: '',
    view_error500: 'Fout',
    searchAgain: 'Opnieuw zoeken',
    recipient: 'Geadresseerde',
    sender: 'Afzender',
    namesender: 'Naam van de afzender',
    subject: 'Onderwerp',
    emailbody: 'Bodytekst van de e-mail',
    resourcespreview: 'Voorvertoning resources',
    attach: 'Bijvoegen',
    actions: 'Acties',
    changePassword: 'Wachtwoord actualiseren',
    sessionClosed: 'Sessie correct gesloten',
    noPermissionMessage: 'U heeft geen rechten voor toegang tot deze rubriek',
    download: 'Downloaden',
    viewDocument: 'Document bekijken',
    accept: 'Accepteren',
    reject: 'Weigeren',
    change: 'Wijzigen',
    addVariables: 'Variabele toevoegen',
    resources: 'Resources',
    launchDataKo: 'De aangeleverde gegevens zijn niet geldig om de verzending te realiseren',
    launchDataOk: 'De gegevens zijn correct, u kunt verder gaan en verzenden',
    addNewRecipient: 'Nieuwe geadresseerde toevoegen',
    transactionRecipients: 'Geadresseerden van de transactie',
    noRecipientsSelected: 'Geen geadresseerde geselecteerd',
    recipientsSelected: 'Meerdere geselecteerd',
    recipientSelected: 'Eén geselecteerd',
    preview: 'Voorvertoning (preview)',
    summary: 'Samenvatting',
    transaction_summary: 'Samenvatting van de zending',
    PDFpreview: 'Voorvertoning PDF',
    previewing: 'Voorvertoning:',
    notAvailable: 'Niet beschikbaar',
    hide: 'Verbergen',
    send: 'Verzenden',
    showFile: 'Bestand bekijken',
    error500Message: 'Er is een fout opgetreden. Als het probleem blijft bestaan, neem dan contact op met support via',
    thisForm: 'dit formulier',
    error401Message: 'De sessie is verlopen',
    withoutData: 'Zonder gegevens',
    notInformed: 'Niet geïnformeerd',
    loginTitleText: 'Inloggen',
    changePasswordTitleText: 'Wachtwoordwijziging',
    activateUserTitleText: 'Activering van gebruiker',
    homeWelcomeMessageTitle: 'Welkom bij CertySign',
    homeWelcomeMessageSubtitle: 'Omgeving voor transactiebeheer en gecertificeerde afleverflows',
    changePasswordUserTitleText: 'Nieuw wachtwoord instellen',
    activateUserSubtitleText: 'Voor de activatie van de gebruiker is het nodig een nieuw wachtwoord in te stellen',
    changePasswordUserSubtitleText: 'Vul de velden in om een nieuw wachtwoord in te stellen',
    activateUserTitleTextWithoutHash: 'URL niet geldig',
    activateUserSubtitleTextWithoutHash: 'De URL die u gebruikt is niet geldig U kunt desgewenst een nieuwe URL aanvragen met de optie \'Ik ben mijn wachtwoord vergeten\' op het loginscherm.',
    goToLogin: 'Inloggen',
    activeFilters: 'Actieve filters',
    rememberPassword: 'Wachtwoord vergeten?',
    rememberPasswordTitleText: 'Wachtwoord herstellen',
    supportContact: 'Contact met support',
    rememberPasswordSubTitleText: 'Kies een methode voor wachtwoordherstel',
    rememberPasswordEmailResponse: 'Als de aangegeven gebruiker geregistreerd is, ontvangt u een e-mail met instructies voor wachtwoordwijziging.',
    rememberPasswordWebResponse: 'Als de ingevoerde gegevens correct zijn, zal ons supportteam per e-mail contact met u opnemen en u instructies geven over hoe u uw wachtwoord kunt wijzigen.',
    rememberPasswordPhoneResponse: 'Als de ingevoerde gegevens correct zijn, zal ons supportteam telefonisch contact met u opnemen en u instructies geven over hoe u uw wachtwoord kunt wijzigen.',
    expiredPasswordResponse: 'Uw wachtwoord is verlopen, u ontvangt zo meteen een e-mail met instructies hoe u een nieuw wachtwoord kunt instellen.',
    rememberPasswordSupportEmail: 'E-mail wachtwoordherstel',
    rememberPasswordSupportEmailSubtitle: 'Wij sturen u een e-mail waarmee u uw wachtwoord kunt herstellen.',
    rememberPasswordSupportEmailText: 'Vul het formulier in met uw op CertySign geregistreerde e-mailadres',
    rememberPasswordSupportWeb: 'Support via website',
    rememberPasswordSupportWebSubtitle: 'Stuur een e-mail naar het technisch supportteam van CertySign',
    rememberPasswordSupportWebText: 'Vul de gegevens in. Het technisch supportteam geeft u zo snel mogelijk antwoord.',
    emailSupportServiceHours: 'Openingstijden:',
    rememberPasswordSupportPhone: 'Telefonische support',
    rememberPasswordSupportPhoneSubtitle: 'Telefoontje naar het technisch supportteam van CertySign',
    rememberPasswordSupportPhoneText: 'Vul de gegevens in. Het technisch supportteam belt u zo snel mogelijk.',
    phoneSupportServiceHours: 'Bereikbaarheid telefonisch support:',
    rememberPasswordNotSupportBySchedule: 'Optie uitgeschakeld wegens buiten bereikbaarheidsuren',
    rememberPasswordMondayToFriday: 'Maandag t/m vrijdag:',
    rememberPasswordSaturdaySundayAndOthers: 'Zaterdag, zondag en feestdagen:',
    rememberPasswordNotIncludedDays: '(feestdagen niet inbegrepen)',
    supportFinalSendMessage: 'Uw aanvraag is verstuurd aan onze supportafdeling',
    goBackToMethods: 'Andere methode selecteren',
    rememberPasswordContactName: 'Voornaam en achternaam',
    goToC2C: 'Telefoongesprek aanvragen',
    messageDescription: 'Bericht',
    company: 'Bedrijf',
    legalCheckboxText: 'Ik accepteer de Gebruiksvoorwaarden van de website en het gegevensbeschermingsbeleid',
    selectHelps: 'Hoe kunnen we u helpen?',
    selectHelpsTooltip: 'Support en Help',
    helpManual: 'Handleiding bekijken',
    helpGuide: 'Snelgids bekijken',
    helpSupport: 'Contact met support opnemen',
    helpSupportPhonePreffered: 'Contact opnemen, bij voorkeur telefonisch',
    helpSupportPhoneSchedule: 'Openingstijden telefonische service',
    requiredEmail: 'E-mailadres is verplicht',
    requiredPhone: 'Telefoonnummer is verplicht',
    requiredName: 'Naam is verplicht',
    requiredEmailFormat: 'Incorrect format e-mailadres',
    requiredPhoneFormat: 'Incorrect format telefoonnummer',
    requiredTextarea: 'Bericht is verplicht',
    tag_signature: 'Elektronische handtekening',
    tag_email: 'E-mailadres',
    tag_sms: 'Sms',
    tag_cert_email: 'Gecertificeerde e-mail',
    tag_landing: 'Webpagina',
    tag_biometric_signature: 'Biometrische handtekening',
    tag_postal: 'Per post',
    processesViewListTitle: 'Processen',
    processesViewListDescription: 'Lijst met alle beschikbare processen op CertySign',
    tags: 'Labels',
    searchs: 'Zoeken',
    processInfo: 'Informatie over het proces',
    processesCategoriesFilter: 'Filter per categorie',
    processesTagFilter: 'Filter op label',
    processesTagOthers: 'overige',
    processesSearch: 'Processen zoeken',
    searchTransactions: 'Transacties raadplegen',
    searchTransaction: 'Transactie raadplegen',
    deleteFilters: 'Filters verwijderen',
    withoutResults: 'Geen resultaat',
    withoutProcessResults: 'U heeft geen processen om op te starten volgens uw rechten of de ingestelde zoekcriteria.',
    allProcesses: 'Alle processen',
    emailNotValid: 'E-mailadres is niet geldig',
    phoneNotValid: 'Telefoonnummer is niet geldig',
    phoneAtLeastNineChars: 'Telefoonnummer moet minstens 9 cijfers hebben',
    doesntExist: 'Bestaat niet',
    dontExist: 'Bestaan niet',
    requiredField: 'Verplicht veld',
    errorField: 'Onjuist veld',
    numberField: 'Numeriek veld',
    fiveCharsField: 'Veld moet 5 tekens hebben',
    CPvalidField: 'Postcode komt niet overeen met provincie',
    delete: 'Verwijderen',
    save: 'Opslaan',
    file: 'Bestand',
    loading: 'Aan het laden',
    countrySelector: 'Kies land',
    provinceSelector: 'Kies provincie',
    andNumberMore: 'en {count} meer',
    selectFile: 'Kies bestand',
    selectFileHtml: 'Kies html-bestand',
    selectFiles: 'Kies bestanden',
    allTransactions: 'Alle transacties',
    transactionsPerPage: 'Transacties per pagina',
    transactionsSend: 'Verzending van',
    transactionslower: 'transacties',
    transactionWithoutId: 'ID-toewijzing in afwachting',
    withoutCertificated: 'Certificaat niet beschikbaar',
    filter: 'Filteren',
    transactionsProcessFilter: 'Filteren op proces',
    processDataPreviewText: 'Hieronder vindt u de samenvatting van de nieuwe transactie, met inbegrip van het aantal benodigde zendingen en de bijbehorende kostenplaats',
    processDataPreviewTextWithoutCC: 'Hieronder vindt u de samenvatting van de nieuwe transactie, waarin het noodzakelijke aantal zendingen is aangegeven.',
    transactionsLinkedGroupsFilter: 'Filter op groep',
    transactionsDateFilter: 'Filter op datum',
    numberOfTransactions: 'Aantal zendingen',
    associatedCECO: 'Bijbehorende kostenplaats',
    transactionsCategoryFilter: 'Filter op categorie',
    transactionsStatusFilter: 'Filter op status',
    transactionState: 'Status van de transactie',
    transactionSummary: 'Samenvatting van de transactie',
    transactionGeneralData: 'Informatie van de transactie',
    transactionData: 'Transactiegegevens',
    files: 'Bestanden',
    transactionContact: 'Contactgegevens',
    transaction: 'Transactie',
    transactionEvents: 'Certificeerbare transactiegebeurtenissen',
    transactionWithoutEvents: 'Er zijn geen gebeurtenissen geregistreerd voor de transactie',
    transactionProgress: 'Voortgang van de transactie',
    transactionSubtitle: 'Informatie over de status van de transactie',
    transactionDetails: 'Transactiegegevens',
    transactionEventInfo: 'Informatie over de gebeurtenis',
    eventMeaningPrepend: 'Wat betekent de gebeurtenis',
    eventMeaningAppend: '?',
    eventMeaning: 'Wat betekent deze gebeurtenis?',
    transactionLaunchedDate: 'Creatiedatum',
    transactionLastEvent: 'Laatste ontvangen gebeurtenis',
    transactionLastEventDate: 'Datum laatste gebeurtenis',
    transactionContactName: 'Voornaam en achternaam',
    transactionContactMobilephone: 'Mobiel telefoonnummer',
    transactionContactEmail: 'E-mailadres',
    transactionContactID: 'ID',
    advancedFilters: 'Geavanceerde filters',
    group: 'Groep',
    date: 'Datum',
    hideFilters: 'Filters verbergen',
    processName: 'Procesnaam',
    usernameTransactionDetail: 'Gebruikersnaam afzender',
    recipientsNumber: 'Aantal ontvangers',
    invalidTransaction: 'Ongeldige gegevens',
    dataTableShowRecipients: 'Weergave van verzenden <span class=\'font-weight-medium\'>:page:</span> van <span class=\'font-weight-medium\'>{total}</span>',
    transactionRedirectToProcesses: 'Naar processen',
    transactionRedirectToTransactions: 'Naar transacties',
    transactionRedirectToNewSend: 'Nieuwe verzending',
    transactionProcessed: 'Transactie succesvol verwerkt',
    transactionsProcessed: 'Transacties succesvol verwerkt',
    transactionError: 'Transactie verwerkt met ongeldige',
    transactionsError: 'Transacties verwerkt met ongeldige',
    transactionProcessing: 'Transactie wordt verwerkt ...',
    transactionLaunchedMessage: 'De transactie is succesvol verwerkt, naarmate (ver)zendingen worden verwerkt, zullen ze zichtbaar worden bij',
    transactionsLaunchedMessage: 'Transacties zijn succesvol verwerkt, naarmate (ver)zendingen worden verwerkt, zullen deze zichtbaar worden in de',
    transactionDownloadFile: 'Certificaat bekijken',
    transactionProcessingMessage: 'Het systeem is de transactie aan het verwerken, enkele ogenblikken wachten a.u.b.',
    transactionsProcessingMessage: 'Het systeem is de transacties aan het verwerken, enkele ogenblikken wachten a.u.b.',
    transactionsErrorMessage: 'Tijdens de verwerking van de transacties van de verschillende geadresseerden zijn bij ten minste één transactie ongeldige gegevens ontdekt. De volledige lijst van transacties voor de zending is hieronder beschikbaar',
    transactionErrorMessage: 'Tijdens het proces van de transactie zijn ongeldige gegevens ontdekt',
    recoverProcessQuestion: 'Als u een nieuw proces start, verliest u de voortgang',
    recoverProcessQuestionAppend: 'Wilt u een nieuw proces opstarten?',
    statisticsTransactionsMultipleTitle: 'Transacties gerealiseerd tussen de dagen <span class="font-weight-bold">{start_day} van {start_month}</span> en <span class="font-weight-bold">{end_day} van {end_month}</span>',
    statisticsTransactionsOneTitle: 'Transacties gerealiseerd op dag {start_day} van {start_month}',
    statisticsTotalTransactionsTitle: 'Totalen per type transactie',
    statisticsNoResults: 'Er zijn geen resultaten bij de aangegeven filters',
    statisticsProcessTitle: 'Proces:',
    total: 'Totaal',
    totalTransactions: 'Totalen transacties',
    totalTransactionsByProcesses: 'Verdeling van transacties per proces',
    accountDescription: 'Configuratie van account',
    accountTitle: 'Account',
    contactsDescription: 'Contactenlijst en -beheer',
    contactsTitle: 'Contacten',
    transactionsDescription: 'Transactieoverzicht en details',
    transactionsTitle: 'Transacties',
    statisticsDescription: 'Grafische analyse van uw transacties',
    statisticsTitle: 'Statistieken',
    supportDescription: 'In deze sectie vindt u hulp via technische support en de gebruikershandleiding.',
    supportManualDescription: 'In deze sectie kunt u de documentatie over gebruik van het platform raadplegen',
    supportManualTitle: 'Handleiding en gids raadplegen',
    supportManualTitleOnlyManual: 'Handleiding raadplegen',
    supportManualTitleOnlyGuide: 'Gids raadplegen',
    administrationDescription: 'Beheer van accounts van het platform',
    administrationTitle: 'Beheer',
    userActive: 'Actieve gebruiker',
    userInactive: 'Gebruiker gedeactiveerd',
    userStateTitle: 'Status van de gebruiker',
    groupsTitle: 'Rechten-groepen',
    groupsDescription: 'Beheer van de groepen van het account. Instellingen van parameters en rechten van iedere groep.',
    groupsAssignation: 'Groep toegewezen',
    groupInheritFrom: 'Rechten erven van',
    groupDataTitle: 'Informatie van de groep',
    groupUsersPermissionsTitle: 'Rechten, gebruikers en processen',
    groupPermissionsTabsTitle: 'Rechten',
    groupProcessesAndSubaccounts: 'Subaccounts en Processen',
    groupLinked: 'Gekoppelde groepen',
    groupPermissionsOwnerText: 'Deze groep is de eigenaar van het account en heeft alle rechten die nodig zijn',
    groupPermissionsOwnerSubaccountProcessesText: 'Deze groep is de eigenaar van het account, heeft alle subaccounts en alle processen',
    groupAssociatedProcesses: 'Bijbehorende processen',
    groupAssociatedSubaccounts: 'Bijbehorende subaccounts',
    newGroup: 'Nieuwe groep',
    groupName: 'Naam van de groep',
    editGroup: 'Groep bewerken',
    groupDescription: 'Beschrijving',
    groupMinDaysToChange: 'Minimum-aantal dagen geldigheid wachtwoord',
    groupTabUsersNameTitle: 'Voornaam en achternaam',
    groupTabPermissionSectionTitle: 'Rubrieken',
    groupTabPermissionGroupTitle: 'Groepen',
    groupTabPermissionUsersTitle: 'Gebruikers',
    usersSearch: 'Gebruikers zoeken',
    subaccountWithoutProcesses: 'Subaccount zonder bijbehorende processen',
    selectCostCenter: 'Kies een kostenplaats',
    adminGroupInfoDescription: 'Hier kunt u beschrijving, sessiebeleid en wachtwoorden van het platform wijzigen.',
    adminGroupInfoUsers: 'Hier kunt u de gebruikers beheren die bij deze groep horen.',
    adminGroupInfoPerms: 'Hier kunt u de rechten beheren voor de functies die de gebruiker kan uitvoeren op het platform.',
    adminGroupInfoProcesses: 'Hier kunt u de toegang van de gebruiker tot processen en subaccounts beheren. Deze toegang verleent toestemming om de toegewezen processen op te starten, transacties en statistieken ervan te bekijken.',
    adminGroupInfoLinked: 'Hier kunt u de supervisie over andere groepen beheren. De ingeschakelde groepen delen de informatie van de opgestarte transacties alsmede de statistieken met de huidige groep.',
    passwordsQuantity: 'Aantal opgeslagen wachtwoorden',
    passwordExpiration: 'Dagen tot de verloopdatum van het wachtwoord',
    loginRetriesQuantity: 'Aantal loginpogingen',
    loginRetriesTime: 'Aantal minuten voor nieuwe login-poging',
    sessionExpiration: 'Aantal minuten voordat de sessie verloopt',
    processAlertExpirationQuestion: 'Wilt u doorgaan?',
    processAlertExpirationPhrase: 'Om veiligheidsredenen verloopt de sessie over enkele momenten',
    processAlertExpiration: 'Verloop-waarschuwing',
    continueSession: 'Verder',
    usersTitle: 'Gebruikers',
    deactivate: 'Gedeactiveerd',
    usersPerPage: 'Gebruikers per pagina',
    usersDescription: 'Beheer van de gebruikers van het account',
    userInfoTitle: 'Gebruikersinformatie',
    userPermissionTitle: 'Type toegekend recht',
    newUser: 'Nieuwe gebruiker',
    editUser: 'Gebruiker wordt bewerkt',
    groupTitle: 'Naam van de groep',
    administrator: 'Administrator',
    groupLinkedHeader: 'Gekoppeld',
    firmaElectronica: 'Elektronische handtekening',
    comunicacionCertificada: 'Gecertificeerde communicatie',
    publicacionWeb: 'Webpublicatie',
    groupVisibility: 'Zichtbaarheid groep',
    passwordPolicy: 'Wachtwoordbeleid',
    frontalPolicy: 'Sessiebeleid',
    groupData: 'Gegevens van de groep',
    groupsPerPage: 'Groepen per pagina',
    groupsFound: 'Er zijn gevonden',
    cancel: 'Annuleren',
    description: 'Beschrijving',
    subaccountChanged: 'Subaccount gewijzigd',
    processContinueTitle: 'U heeft een proces in afwachting van verzending',
    processContinueSubtitle: 'Wilt u het proces herstellen of een nieuw opstarten?',
    newProcess: 'Nieuw proces',
    recover: 'Herstellen',
    newShipping: 'Nieuwe verzending',
    toContinueFillFields: 'Vul de velden correct in om verder te kunnen gaan',
    passwordRequired: 'Wachtwoord vereist',
    userRequired: 'Gebruikersnaam vereist',
    fieldAlert: 'Het veld %%fields%% wordt automatisch gevuld vanuit de geadresseerden',
    fieldAlertPlural: 'De velden %%fields%% worden automatisch gevuld vanuit de geadresseerden',
    fieldNumberPhone: 'telefoon',
    fieldCountryCode: 'landcode',
    fieldEmail: 'e-mailadres',
    fieldName: 'voornaam',
    fieldSurname: 'achternaam',
    and: 'en',
    certificate: 'Certificaat',
    country: 'Land',
    continueEditing: 'De gegevens van de zending bewerken',
    second: 'seconde',
    seconds: 'seconden',
    nextRefreshAvailableAt: 'Volgende actualisering beschikbaar over',
    fastSearch: 'Snelzoeken',
    update: 'Actualiseren',
    process: 'Proces',
    transactionId: 'ID van de transactie',
    transactionClientId: 'Klantreferentie',
    createdDate: 'Gecreëerd',
    updatedAt: 'Geactualiseerd',
    state: 'Status',
    noGroupAssigned: 'Zonder geselecteerde groep',
    contact_country_code: 'Landcode',
    contact_email: 'E-mailadres',
    contact_id: 'Klantreferentie',
    contact_name: 'Voornaam',
    contact_phone: 'Telefoon',
    contact_surname: 'Achternaam',
    contact_complete_name: 'Complete naam',
    certificateValidDownload: 'Certificaat correct gedownload',
    certificateFailDownload: 'Het certificaat kon niet gedownload worden',
    graphicBarValidDownload: 'Grafiek correct gedownload',
    graphicBarFailDownload: 'De grafiek kon niet gedownload worden',
    queued: 'De transactie is in afwachting van verwerking.',
    //events
    new_communication: 'De transactie is correct gegenereerd.',
    orchestrator_new_communications_communication_received: 'De transactie is correct verwerkt.',
    commstracker_generate_url_generated: 'De link met de verkorte URL is gegenereerd.',
    commstracker_generate_url_click: 'De link van de transactie is geopend.',
    communication_send_sms_sent: 'Het sms-bericht is gedeponeerd bij het verzend-servicecentrum van de provider. De provider zorgt op zijn beurt voor de levering aan de eindontvanger.',
    communication_send_sms_not_sent: 'Het sms-bericht kon niet worden gedeponeerd bij het verzend-servicecentrum van de provider.',
    communication_send_sms_delivered: 'Het sms-bericht is afgeleverd bij de geadresseerde.',
    communication_send_sms_not_delivered: 'Het sms-bericht kon niet worden afgeleverd bij de geadresseerde.',
    generate_otp_code_otp_generated: 'Er is een eenmalig wachtwoord (OTP) aangemaakt voor gebruik.',
    generate_certificate_generated: 'Het certificaat van voltooiing van de transactie is gegenereerd. Het bevat de inhoud van de communicatie en het bewijsmateriaal in verband met de gebeurtenissen die in het proces hebben plaatsgevonden.',
    communication_send_email_delivered: 'Het bericht is afgeleverd bij de e-mail service provider (ESP) van de geadresseerde.',
    communication_send_email_complaint: 'De geadresseerde van het bericht heeft de ontvangen e-mail aangemerkt als ongewenst.',
    communication_send_email_queued: 'Het e-mailbericht is in de queue geplaatst.',
    communication_send_email_deferred: 'Het e-mailbericht is in de queue geplaatst en wordt afgeleverd zodra dat mogelijk is.',
    communication_send_email_bounce: 'Het e-mailbericht is gebouncet.',
    communication_send_email_sent: 'Het e-mailbericht is gedeponeerd bij het verzend-servicecentrum van de provider. De provider zorgt op zijn beurt voor de levering aan de eindontvanger.',
    communication_send_email_sender_not_allowed: 'Het e-mailbericht kon niet verzonden worden want de geadresseerde is niet geautoriseerd.',
    communication_send_email_open: 'Het e-mailbericht is geopend door de geadresseerde.',
    communication_send_email_click: 'De geadresseerde van het e-mailbericht heeft op een link geklikt.',
    communication_send_email_block: 'Het e-mailadres van de geadresseerde staat op de uitzonderingenlijst, het bericht is niet verzonden.',
    communication_send_email_unsubscribe: 'De geadresseerde van het e-mailbericht wil zich uitschrijven voor de distributielijst.',
    communication_send_email_spam: 'Het e-mailbericht is geïdentificeerd als ongewenst.',
    communication_send_email_hard_blacklisted: 'Het e-mailadres van de geadresseerde staat op de uitzonderingenlijst, het bericht is niet verzonden.',
    communication_send_email_complaint_blacklisted: 'Het e-mailadres van de geadresseerde staat op de klachtenlijst, het bericht is niet verzonden.',
    document_upload_s3_uploaded: 'Het certificaat van voltooiing van de transactie is veilig opgeslagen. Het bevat de inhoud van de communicatie en het bewijsmateriaal in verband met de gebeurtenissen die in het proces hebben plaatsgevonden.',
    communication_send_telegram_delivered: 'Het sms-bericht is afgeleverd bij de geadresseerde.',
    communication_send_telegram_not_delivered: 'Het sms-bericht kon niet afgeleverd worden ',
    web_framework_generated: 'De grafische webinterface is gegenereerd, zodat interactie mogelijk is voor de gebruiker.',
    web_framework_solicitud_otp: 'Er is een eenmalig wachtwoord (OTP) aangevraagd.',
    web_framework_signable_document_loaded: 'In de webinterface is een document geladen om ondertekend te worden.',
    web_framework_otp_simple_signature_ok: 'De documentatie is ondertekend met de sleutel voor eenmalig gebruik.',
    web_framework_commited: 'Het proces is voltooid in de webinterface.',
    web_framework_document_signed: 'De documentatie is ondertekend.',
    web_framework_form_based_identification: 'De authenticatiestap via het formulier is voltooid.',
    web_framework_certificate_based_identification: 'Toegang met een persoonlijk certificaat is naar tevredenheid uitgevoerd.',
    web_framework_document_based_identification: 'Het formulier is correct geïdentificeerd.',
    web_framework_certificate_based_identification_failed: 'Het identificatieproces met een certificaat is mislukt.',
    web_framework_document_based_identification_failed: 'Het identificatieproces met een document is mislukt.',
    web_framework_form_based_identification_failed: 'Het authenticatieproces met formulier is mislukt.',
    web_framework_web_framework_expired: 'De transactie is verlopen.',
    web_framework_documentation_downloaded: 'De documentatie is gedownload door de geadresseerde.',
    web_framework_electronic_notificacion_rejected: 'De elektronische notificatie is geweigerd door de geadresseerde.',
    web_framework_gdpr_formulary_committed: 'Het toestemmingsformulier is voltooid door de geadresseerde.',
    web_framework_sepa_formulary_committed: 'Het SEPA-toestemmingsformulier is voltooid door de geadresseerde.',
    web_framework_requested_otp_code: 'Er is een eenmalig wachtwoord (OTP) aangevraagd.',
    web_framework_entered_wrong_otp_code: 'Het door de geadresseerde ingevulde wachtwoord voor eenmalig gebruik (OTP) is niet correct.',
    web_framework_entered_timedout_otp_code: 'Het wachtwoord voor eenmalig gebruik is verlopen.',
    web_framework_entered_correct_otp_code: 'Het door de geadresseerde ingevulde wachtwoord voor eenmalig gebruik (OTP) is correct.',
    web_framework_electronic_notificacion_accepted: 'De elektronische notificatie is geaccepteerd door de geadresseerde.',
    web_framework_signature_rejected: 'Het verzoek om elektronische handtekening is geweigerd door de geadresseerde.',
    web_framework_request_otp_code_too_early: 'Het wachtwoord voor eenmalig gebruik (OTP) is aangevraagd door de geadresseerde vóór de vastgestelde tijd om het te kunnen regenereren',
    web_framework_capture_data_commited_no_response: 'Er is geprobeerd het formulier te verzenden zonder gegevens in de antwoorden.',
    web_framework_capture_data_commited: 'De gegevens zijn correct verzonden',
    web_framework_document_previewed: 'De documentatie is door de geadresseerde in voorvertoning bekeken.',
    web_framework_document_simply_signed: 'De documentatie is elektronisch ondertekend.',
    document_upload_alastria_uploaded: 'De hash van het voltooiingscertificaat is geüpload naar de node van CertySign in het Blockchain-netwerk van Alastria.',
    publish_downloadable_file_generated: 'De documentatie is gegenereerd voor publicatie in de webinterface.',
    publish_downloadable_file_open: 'De documentatie is geopend voor publicatie in de webinterface.',
    document_sign_signed: 'Het certificaat is ondertekend',
    rpc_callback_published: 'De PPC-callback is gepubliceerd.',
    loop_add_added: 'De transactie is toegevoegd aan de herhalingslus van pogingen',
    loop_add_tick: 'Er is nog een poging gedaan',
    loop_add_timeout: 'Limiet herhaalde pogingen bereikt',
    loop_cancel_canceled: 'De herhalingslus is geannuleerd',
    identification_create_identification_generated: 'Het proces waarmee de gebruiker zich bij de transactie zal identificeren, is gecreëerd.',
    identification_create_identification_ok: 'Het formulier is correct geïdentificeerd',
    test_load_processed: 'De laadtest is afgelopen',
    instance_delete_deleted: 'Het exemplaar dat overeenkomt met de transactie is verwijderd.',
    unzip_file_extracted: 'Het bestand is uitgepakt.',
    communicalia_consent_accepted: 'De geadresseerde van de communicatie heeft de toestemming geaccepteerd.',
    communicalia_consent_declined: 'De geadresseerde van de communicatie heeft toestemming geweigerd.',
    communicalia_consent_no_data: 'De geadresseerde van de communicatie heeft niet geantwoord.',
    robinson_list_accepted: 'De geadresseerde staat niet op de Robinsonlijst',
    robinson_list_declined: 'De geadresseerde is geregistreerd op de Robinsonlijst',
    robinson_list_no_data: 'De geadresseerde is geregistreerd op de Robinsonlijst',
    publish_embeddable_file_generated: 'Het embedded bestand voor de webinterface is gegenereerd.',
    document_upload_sftp_uploaded: 'De documentatie is geladen met SFTP.',
    file_delete_deleted: 'Het bestand is verwijderd.',
    disable_framework_web_framework_web_disabled: 'De webinterface die hoort bij de transactie is gedeactiveerd.',
    generate_onespan_transaction_transaction_create: 'De transactie elektronische handtekening is gecreëerd met OneSpan',
    generate_onespan_transaction_transaction_create_error: 'Er heeft zich een fout voorgedaan bij het creëren van de transactie elektronische handtekening met OneSpan.',
    generate_onespan_transaction_transaction_complete: 'De transactie elektronische handtekening is voltooid in OneSpan.',
    generate_onespan_transaction_transaction_expire: 'De transactie elektronische handtekening in OneSpan is verlopen.',
    generate_onespan_transaction_transaction_decline: 'De transactie elektronische handtekening in OneSpan is geweigerd.',
    generate_onespan_transaction_email_bounce: 'Het e-mailbericht dat hoort bij de transactie elektronische handtekening in OneSpan, is gebouncet.',
    generate_onespan_transaction_transaction_opt_out: 'De geadresseerde heeft ervoor gekozen om de transactie niet te ondertekenen.',
    generate_onespan_transaction_transaction_delete: 'De transactie elektronische handtekening in OneSpan is verwijderd.',
    generate_onespan_transaction_transation_desactivate: 'De transactie elektronische handtekening in OneSpan is gedeactiveerd.',
    generate_onespan_transaction_kba_failure: 'De transactie is geblokkeerd vanwege veiligheidsredenen bij de toegang of het proces.',
    generate_onespan_transaction_transaction_sent: 'De transactie elektronische handtekening van OneSpan is verzonden.',
    generate_onespan_transaction_transaction_sent_error: 'De verzending van de transactie elektronische handtekening van OneSpan is mislukt.',
    generate_onespan_transaction_package_trash: 'De transactie is verplaatst naar de prullenbak van OneSpan',
    get_onespan_files_files_onespan_generated: 'De bestanden die nodig zijn voor OneSpan zijn gegenereerd',
    communication_send_postal_generated: 'De zending van de communicatie per post is gegenereerd.',
    communication_send_postal_sent_to_factory: 'De zending is geüpload op het postcommunicatieplatform.',
    communication_send_postal_printed: 'De documentatie behorende bij de postzending is afgedrukt.',
    communication_send_postal_pre_admission_requested: 'Er vindt telematische preregistratie plaats van de zending van de communicatie op de website van het postbedrijf.',
    communication_send_postal_pre_admitted: 'De zending van de communicatie is door het postbedrijf telematisch geregistreerd, vóór de deponering.',
    communication_send_postal_sent_to_logistic_operator: 'De zending van de communicatie wordt gedeponeerd bij het postbedrijf.',
    communication_send_postal_on_the_way_to_logistic_operator: 'De zending is onderweg naar het postkantoor dat het dichtst in de buurt is van de geadresseerde van de communicatie.',
    communication_send_postal_received_by_logistic_operator: 'De zending is op het postkantoor dat het dichtst in de buurt is van de geadresseerde van de communicatie.',
    communication_send_postal_not_received_by_logistic_operator: 'De zending met de communicatie is niet aangekomen op het postkantoor.',
    communication_send_postal_on_delivery: 'De zending met de postcommunicatie is onderweg.',
    communication_send_postal_delivered_to_recipient: 'De zending met de postcommunicatie is afgeleverd aan de geadresseerde.',
    communication_send_postal_returned_to_postal_office: 'De zending is op het postkantoor en de geadresseerde van de communicatie heeft de kennisgeving in zijn brievenbus',
    communication_send_postal_delivered_into_postal_office: 'De zending met de postcommunicatie is afgeleverd aan de geadresseerde op het postkantoor.',
    communication_send_postal_generic_delivered: 'De zending met de postcommunicatie is afgeleverd aan de geadresseerde.',
    communication_send_postal_returned: 'Het was niet mogelijk om de zending af te leveren en de postcommunicatie is teruggestuurd naar waar deze vandaan kwam.',
    communication_send_postal_canceled: 'De zending van de postcommunicatie is geannuleerd.',
    communication_send_postal_lost: 'De zending met de postcommunicatie is kwijtgeraakt.',
    communication_send_postal_expired: 'De zending met de postcommunicatie is geretourneerd naar waar deze vandaan kwam, omdat de zending niet door de geadresseerde is afgehaald binnen 15 werkdagen nadat de kennisgeving in de brievenbus was achtergelaten.',
    communication_send_postal_rejected: 'De zending van de postcommunicatie is geweigerd door de geadresseerde.',
    communication_send_postal_stolen: 'De zending van de postcommunicatie is onttrokken of gestolen.',
    communication_send_postal_outside_sicer: 'De zending van de communicatie is niet geregistreerd bij het postbedrijf.',
    communication_send_postal_bad_postal_address: 'Het postadres van de zending is niet correct.',
    communication_send_postal_passed_away: 'De geadresseerde van de zending met de postcommunicatie is overleden.',
    communication_send_postal_other: 'Overige.',
    communication_send_postal_no_information: 'Er zijn geen gegevens over de zending met de communicatie bij het postbedrijf.',
    communication_send_postal_received_pee: 'De ePOD (elektronisch bewijs van aflevering) van de zending is ontvangen.',
    communication_send_postal_certificate_returned: 'Het retourcertificaat van het postbedrijf met bewijs van onmogelijkheid van aflevering is gegenereerd.',
    communication_send_postal_unknown: 'De geadresseerde van de zending met de communicatie is niet bekend op het aangegeven postadres.',
    zip_files_path_zipped: 'Een of meerdere documenten zijn gecomprimeerd.',
    intromission_api_retries_muted: 'De herhaalde pogingen van de transactie zijn gedeactiveerd',
    intromission_api_retries_unmuted: 'De herhaalde pogingen van de transactie zijn geactiveerd',
    intromission_api_instance_canceled: 'De transactie is geannuleerd',
    antivirus_filtered: 'De antivirus heeft de transactie uitgefilterd omdat er potentieel gevaarlijke resources geüpload zijn.',
    //API messages
    user_operation_error_creating_user: 'Er heeft zich een fout voorgedaan bij de creatie van de gebruiker',
    user_operation_user_already_exists: 'Deze gebruiker bestaat al',
    user_operation_user_created: 'Gebruiker succesvol gecreëerd',
    user_operation_user_updated: 'Gebruiker succesvol geactualiseerd',
    user_operation_no_exists: 'Deze gebruiker bestaat niet',
    user_operation_password_changed: 'Wachtwoord is geactualiseerd',
    user_operation_pass_incorrect: 'De combinatie van gebruikersnaam en wachtwoord is niet correct',
    user_operation_password_expired: 'Het wachtwoord is verlopen',
    user_operation_logout: 'Sessie correct gesloten',
    user_operation_blocked_user: 'De gebruiker is geblokkeerd',
    user_operation_wrong_subdomain: 'Gebruikersnaam of wachtwoord is niet correct',
    user_operation_max_pass_tries: 'Gebruiker geblokkeerd, maximaal aantal pogingen overschreden',
    user_operation_deleted: 'Gebruiker correct verwijderd',
    user_operation_error_deleted: 'Fout bij verwijderen van gebruiker',
    user_has_been_disabled: 'Gebruiker gedeactiveerd',
    user_operation_error_user_not_found: 'Als het aangegeven e-mailadres bestaat, ontvangt u zo meteen een e-mail met de aanwijzingen om uw wachtwoord te wijzigen.',
    user_operation_remember_password_email: 'Als het aangegeven e-mailadres bestaat, ontvangt u zo meteen een e-mail met de aanwijzingen om uw wachtwoord te wijzigen.',
    email_sent_to_support: 'Aanvraag correct verzonden',
    user_operation_error_change_password_min_days: 'Het is niet mogelijk het wachtwoord te wijzigen, omdat het vereiste minimum-aantal dagen nog niet verstreken is',
    user_operation_error_passwords_mismatch: 'De wachtwoorden komen niet overeen.',
    user_operation_error_password_has_been_used_before: 'Het wachtwoord is al eens eerder gebruikt, bedenk een nieuw',
    user_or_group_no_exists: 'Gebruiker of groep bestaat niet',
    user_error_already_active: 'Gebruiker is al actief',
    activation_email_resend: 'De activatie-e-mail is verstuurd',
    group_operation_error_creating_group: 'Er heeft zich een fout voorgedaan bij de creatie van de groep',
    group_operation_group_already_exists: 'Deze groep bestaat al',
    group_operation_group_created: 'Groep succesvol gecreëerd',
    group_operation_group_updated: 'Groep :group_name: succesvol geactualiseerd',
    //Terms and conditions
    termsConditionsDPOTitle: 'Functionaris gegevensbescherming (DPO)',
    termsConditionsTitular: 'Naam bedrijf',
    termsConditionsNIF: 'N.I.F.',
    termsConditionsDomicilioSocial: 'Geregistreerd adres',
    termsConditionsDatosRegistrales: 'Gegevens Handelsregister',
    termsConditionsContacto: 'Contact',
    termsConditionsDPOEmailTitle: 'E-mailadres Customer Comms',
    termsConditionsDPOEmail: 'E-mailadres van de functionaris gegevensbescherming (DPO)',
    termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (hierna CCOMMS) in zijn hoedanigheid van eigenaar en verantwoordelijke voor deze App, in overeenstemming met de bepalingen van artikel 10 van de Spaanse Wet 34/2002 van 11 juli betreffende diensten van de informatiemaatschappij en elektronische handel, biedt gebruikers de volgende informatie uit deze App aan:',
    termsConditionsObjetoTitulo: 'DOEL',
    termsConditionsObjetoParrafo1: 'Deze Algemene Gebruiksvoorwaarden (hierna de \'AGV\') regelen het gebruik van de Applicatie Certificaatvalidatie (hierna \'de App\') die CCOMMS ter beschikking stelt aan gebruikers die zich toegang verschaffen tot de App om de ingediende aanvragen te kunnen afhandelen.',
    termsConditionsObjetoParrafo2: 'Daarom zijn deze AGV zowel van toepassing op de promotieactiviteiten en de informatieverstrekking via de App als op de levering van de daarin aangeboden diensten, zodat deze voorwaarden te allen tijde van toepassing zullen zijn op zowel simpelweg browsen in de App als op het contracteren van diensten in het kader van de App. De laatstgenoemde activiteiten zijn echter onderworpen aan zowel de hieronder beschreven AGV als aan de van toepassing zijnde Algemene Contractvoorwaarden en de eventuele Specifieke Voorwaarden.',
    termsConditionsUsuariosTitulo: 'GEBRUIKERS',
    termsConditionsUsuariosParrafo1: 'De toegang tot en/of het gebruik van de App verleent aan diegenen die toegang hebben tot en/of gebruik maken van de App de status van gebruiker, waarbij ze vanaf dat moment, volledig en zonder enig voorbehoud, de huidige AGV accepteren, evenals de Specifieke Voorwaarden die, waar van toepassing, de AGV aanvullen, wijzigen of vervangen met betrekking tot bepaalde diensten en inhoud van de App, en waarbij ze zich verplichten om te allen tijde de door CCOMMS op enig moment vastgelegde beperkingen op het gebruik van de genoemde pagina te respecteren.',
    termsConditionsAccesoAPPTitulo: 'TOEGANG TOT DE APP',
    termsConditionsAccesoAPPParrafo1: 'De toegang tot en het gebruik van de App heeft een beperkt karakter en om toegang te krijgen tot de App is voorafgaande registratie van de gebruiker nodig.',
    termsConditionsUsoAPPTitulo: 'GEBRUIK VAN DE APP',
    termsConditionsUsoAPPParrafo1: 'De gebruiker verbindt zich ertoe de App, de diensten en de inhoud ervan te gebruiken zonder in strijd te zijn met de geldende wetgeving, de goede trouw, algemeen aanvaarde gebruiken en de openbare orde. Evenzo is het verboden de App te gebruiken voor ongeoorloofde of schadelijke doeleinden tegen CCOMMS of een derde partij, of doeleinden die op enigerlei wijze schade kunnen toebrengen aan of de normale werking van de App kunnen belemmeren.',
    termsConditionsResposabilidadTitulo: 'AANSPRAKELIJKHEID VAN DE GEBRUIKER VOOR SCHADE',
    termsConditionsResposabilidadParrafo1: 'Het gebruik van de App geschiedt op eigen en exclusieve aansprakelijkheid van de gebruiker. Deze aansprakelijkheid strekt zich uit tot het gebruik, door de gebruiker of een derde, van alle wachtwoorden en dergelijke die zijn toegewezen voor toegang tot de App of diensten ervan.',
    termsConditionsResposabilidadParrafo2: 'Onverminderd het voorgaande behoudt CCOMMS zich het recht voor om te allen tijde en zonder voorafgaande kennisgeving de toegang tot de App of beperkte toegang te ontzeggen of onmogelijk te maken aan gebruikers die deze AGV of de door CCOMMS of zijn medewerkers opgestelde regels schenden of de goede werking, het imago, de geloofwaardigheid en/of het prestige van CCOMMS of zijn medewerkers kunnen verstoren.',
    termsConditionsResposabilidadParrafo3: 'In deze zin behoudt CCOMMS zich het recht voor om de toegang tot de App te annuleren of te beperken voor bepaalde Gebruikers, in het geval dat gedrag opgemerkt wordt dat naar het oordeel van CCOMMS in strijd is met deze Gebruiksvoorwaarden, de Wet, de door CCOMMS of zijn medewerkers opgestelde regels of dat de goede werking, het imago, de geloofwaardigheid en/of het prestige van CCOMMS of zijn medewerkers kan verstoren. Evenzo kan CCOMMS Gebruikers aansprakelijk stellen voor schade die voortvloeit uit misbruik van de App.',
    termsConditionsResposabilidadParrafo4: 'CCOMMS garandeert niet de wettigheid, betrouwbaarheid, bruikbaarheid, waarheidsgetrouwheid of nauwkeurigheid van de inhoud die gebruikers overdragen tijdens het gebruik van de App.',
    termsConditionsResposabilidadParrafo5: 'CCOMMS oefent geen controle uit op het gebruik dat gebruikers van de App maken, en garandeert evenmin dat gebruikers dit doen in overeenstemming met deze Algemene Voorwaarden.',
    termsConditionsResposabilidadParrafo6: 'CCOMMS controleert of garandeert niet de afwezigheid van computervirussen in de diensten die door derden via de App worden aangeboden en die wijzigingen kunnen veroorzaken in uw computersysteem (software en hardware) of in de elektronische documenten en bestanden die in uw computersysteem zijn opgeslagen.',
    termsConditionsPropiedadIntelectualTitulo: 'INTELLECTUELE EIGENDOM',
    termsConditionsPropiedadIntelectualParrafo1: 'Iedere vorm van gebruik of wijziging van het Materiaal of de Inhoud voor enig ander doel dan zoals toegestaan in de Voorwaarden zal worden beschouwd als een schending van de internationale copyright-wetten die de rechten van de auteur(s) beschermen.',
    termsConditionsPropiedadIntelectualParrafo2: 'Met betrekking tot de Inhoud is het volgende verboden:',
    termsConditionsPropiedadIntelectualListaELemento1: 'Reproduceren, kopiëren, distribueren, wijzigen of manipuleren ervan, in welke vorm en voor welk doel dan ook, tenzij vooraf schriftelijke toestemming is verkregen van de rechtmatige eigenaars of het wettelijk is toegestaan.',
    termsConditionsPropiedadIntelectualListaELemento2: 'Elke inbreuk op de rechten van CCOMMS of de rechtmatige eigenaars van deze rechten.',
    termsConditionsPropiedadIntelectualListaELemento3: 'Het gebruik ervan voor andere dan de strikt toegestane commerciële of reclamedoeleinden.',
    termsConditionsPropiedadIntelectualParrafo3: 'CCOMMS garandeert dat de inhoud, met inbegrip van die met intellectuele eigendom, niet van illegale aard is en niet in strijd is met de geldende wetgeving. Daarom mag de inhoud van de App niet xenofoob, pornografisch, discriminerend of racistisch zijn, of op enigerlei wijze geweld bevorderen.',
    termsConditionsPropiedadIntelectualParrafo4: 'Bovendien zal CCOMMS de wettelijke maatregelen nemen die het bedrijf geschikt acht om elk soort gedrag te verhinderen dat in strijd is met de wet of immoreel is.',
    termsConditionsModificacionUnilateralTitulo: 'EENZIJDIGE WIJZIGING',
    termsConditionsModificacionUnilateralParrafo1: 'CCOMMS kan de structuur en het ontwerp van de App eenzijdig en zonder voorafgaande kennisgeving wijzigen, evenals de diensten, inhoud en voorwaarden voor toegang en/of gebruik van de App wijzigen of opheffen, indien het bedrijf dat nodig acht.',
    termsConditionsExclusionGarantiasTitulo: 'UITSLUITING VAN GARANTIES EN AANSPRAKELIJKHEID',
    termsConditionsExclusionGarantiasParrafo1: 'CCOMMS geeft geen garantie en is in geen geval aansprakelijk voor schade van welke aard dan ook die veroorzaakt kan zijn door:',
    termsConditionsExclusionGarantiasListaELemento1: 'Het niet naleven van de Wet, de goede zeden en gewoonten, evenals de openbare orde, of het gebruik van de aangeboden producten en/of diensten op een onzorgvuldige of onjuiste manier, of voor ongeoorloofde doeleinden of effecten of in strijd met de bepalingen van deze AGV of de toepasselijke voorwaarden die van toepassing kunnen zijn.',
    termsConditionsExclusionGarantiasListaELemento2: 'Voor het verwerven of contracteren van diensten, goederen of producten die van derden verworven of gecontracteerd kunnen worden via toegang tot de App van CCOMMS, met name in die gevallen waarin het aankoop- of contracteerproces rechtstreeks in de app van de derde wordt uitgevoerd, ook al zien ze er anders uit of vormen ze een \'frame\' met de grafische elementen van de app (Branding).',
    termsConditionsExclusionGarantiasListaELemento3: 'Gebrek aan beschikbaarheid, onderhoud en effectief functioneren van de App en/of de diensten of inhoud ervan.',
    termsConditionsExclusionGarantiasListaELemento4: 'Gebrek aan nut, geschiktheid of geldigheid van de App en/of zijn diensten of inhoud om aan de behoeften, activiteiten of concrete resultaten of verwachtingen van de gebruikers te voldoen.',
    termsConditionsExclusionGarantiasListaELemento5: 'Het bestaan van virussen, kwaadaardige of schadelijke programma\'s in de inhoud.',
    termsConditionsExclusionGarantiasListaELemento6: 'Het ontvangen, verkrijgen, opslaan, verspreiden of doorgeven van de inhoud door gebruikers.',
    termsConditionsExclusionGarantiasListaELemento7: 'Onwettigheid of gebrek aan waarheidsgetrouwheid, nauwkeurigheid, betrouwbaarheid, relevantie, tijdigheid en volledigheid van de inhoud en informatie die aan gebruikers wordt doorgegeven of beschikbaar wordt gesteld, met inbegrip van informatie en diensten die door derden of door gebruikers via de App worden verstrekt.',
    termsConditionsExclusionGarantiasListaELemento8: 'Niet-naleving door derden van hun verplichtingen of verbintenissen in verband met de diensten die via de App aan de gebruikers worden verleend.',
    termsConditionsExclusionGarantiasListaELemento9: 'Inhoud, bestanden, informatie, reclame, meningen, concepten en beelden die niet van de App afhankelijk zijn, noch door CCOMMS beheerd worden. Het bedrijf is evenmin verantwoordelijk voor misbruik van de inhoud van de App, die uitsluitend de verantwoordelijkheid is van de persoon die er toegang toe heeft of er gebruik van maakt.',
    termsConditionsExclusionGarantiasListaELemento10: 'Oneigenlijk gebruik van de toegangscodes van de gebruikers om toegang te krijgen tot de inhoud van de App-dienst waarvoor deze nodig zijn en de gevolgen van welke aard dan ook die voortvloeien uit misbruik door gebruikers, verlies of vergeten ervan, en oneigenlijk gebruik door onbevoegde derden.',
    termsConditionsExclusionGarantiasListaELemento11: 'Het onvermogen van een gebruiker of het zich voordoen als een derde door de gebruiker.',
    termsConditionsExclusionGarantiasListaELemento12: 'De inhoud die door de gebruikers wordt verstrekt.',
    termsConditionsProteccionDatosTitulo: 'GEGEVENSBESCHERMING',
    termsConditionsProteccionDatosParrafo1: 'U kunt gedetailleerde informatie over Gegevensbescherming vinden op onze pagina',
    termsConditionsLeyJurisdiccionTitulo: 'VAN TOEPASSING ZIJNDE WET EN JURISDICTIE',
    termsConditionsLeyJurisdiccionParrafo1: 'Alle Algemene en Specifieke Voorwaarden die in deze App zijn opgenomen, vallen onder de Spaanse wetgeving. Alle geschillen, disputen of situaties die voortvloeien uit de Algemene en Specifieke Voorwaarden worden voorgelegd aan de rechtbanken en gerechtshoven van de stad Madrid, en de partijen zien uitdrukkelijk af van overige eigen jurisdictie.',
    termsConditionsProteccionDatosTitle: 'Informatie over Gegevensbescherming',
    termsConditionsProteccionDatos: 'Gegevensbescherming',
    //Privacy Policies
    privacyPoliciesConditionsTitle: 'Gegevensbescherming van MailTecK & Customer Communications Tecknalia',
    privacyPoliciesConditionsParrafo1: 'In overeenstemming met de bepalingen van Verordening (EU) 2016/679 van het Europees Parlement en de Raad van 27 april 2016 betreffende de bescherming van natuurlijke personen in verband met de verwerking van persoonsgegevens en betreffende het vrije verkeer van die gegevens (AVG), alsook met de Spaanse Organieke Wet 3/2018 van 5 december inzake de bescherming van persoonsgegevens en de waarborging van digitale rechten (LOPD-GDD) en andere geldende regelgeving inzake gegevensbescherming, worden gebruikers van de App, die vrijwillig op vrije, duidelijke en ondubbelzinnige manier hun toestemming hebben gegeven voor de verwerking van persoonsgegevens door het vakje bij \'Gegevensbeschermingsbeleid\' aan te vinken en dit dus te aanvaarden, hierbij geïnformeerd dat hun gegevens zullen worden verwerkt, afhankelijk van het bedrijf waarbij ze zich hebben geregistreerd, door het bedrijf MAILTECK, S.A. of door het bedrijf CUSTOMER COMMUNICATIONS TECKNALIA, S.L., samen MAILCOMMS GROUP genoemd.',
    privacyPoliciesConditionsResponsable1Title: 'Verantwoordelijke 1',
    privacyPoliciesConditionsResponsable2Title: 'Verantwoordelijke 2',
    privacyPoliciesConditionsFinalidadTitle: 'Doel',
    privacyPoliciesConditionsLegitimacionTitle: 'Legitimiteit',
    privacyPoliciesConditionsDestinatariosTitle: 'Geadresseerden',
    privacyPoliciesConditionsDerechosTitle: 'Rechten',
    privacyPoliciesConditionsInformacionAdicionalTitle: 'Aanvullende informatie',
    privacyPoliciesConditionsPoliticaInformacion1Title: 'Informatiebeleid 1',
    privacyPoliciesConditionsPoliticaInformacion2Title: 'Informatiebeleid 2',
    privacyPoliciesConditionsIdentidadTitle: 'Identiteit',
    privacyPoliciesConditionsNIFTitle: 'NIF (Spaans belastingidentificatienummer/btw-nummer)',
    privacyPoliciesConditionsDireccionTitle: 'Adres',
    privacyPoliciesConditionsTelefonoTitle: 'Telefoon',
    privacyPoliciesConditionsCorreoTitle: 'E-mail',
    privacyPoliciesConditionsDPDTitle: 'Contact functionaris gegevensbescherming (DPO)',
    privacyPoliciesConditionsDPDCustomerCommsTitle: 'Functionaris gegevensbescherming (DPO) van Customer Comms',
    privacyPoliciesConditionsDPDMailteckTitle: 'Functionaris gegevensbescherming (DPO) van MailTecK',
    privacyPoliciesConditionsFinalidadIntro: 'Beheer en verwerking van gecontracteerde diensten',
    privacyPoliciesConditionsFinalidadParrafo1: 'MAILCOMMS GROUP zal de verstrekte gegevens verwerken in het kader van de uitvoering van een door u of uw bedrijf ondertekend contract, met het oog op het beheer en de verwerking van de daarin overeengekomen diensten, alsmede voor support van de applicatie Certysign.',
    privacyPoliciesConditionsFinalidadParrafo2: 'MAILCOMMS GROUP zal de verstrekte gegevens alleen verwerken voor de hierboven beschreven doeleinden en ze zullen niet verder worden verwerkt op een wijze die onverenigbaar is met die doeleinden. De verstrekte persoonsgegevens worden bewaard voor de duur van de contractuele relatie.',
    privacyPoliciesConditionsLegitimacionIntro: 'Uitvoering van het contract',
    privacyPoliciesConditionsLegitimacionParrafo1: 'De gegevens zijn door u of uw bedrijf verstrekt via de kanalen die zijn opgezet om u toegang te geven tot de applicatie Certysign, als gevolg van het ondertekende dienstverleningscontract.',
    privacyPoliciesConditionsDestinatariosIntro: 'Ze worden niet doorgegeven aan derden',
    privacyPoliciesConditionsDestinatariosParrafo1: 'Vanwege een wettelijke verplichting wanneer een voorschrift dit vereist.',
    privacyPoliciesConditionsDerechosIntro: 'U kunt uw rechten uitoefenen op de wijze en binnen de termijnen waarin de wet voorziet.',
    privacyPoliciesConditionsDerechosElem1ListaTitulo: 'Algemene bevestiging:',
    privacyPoliciesConditionsDerechosElem1ListaDescripcion: 'Alle personen hebben het recht te vernemen of MAILCOMMS GROUP persoonsgegevens verwerkt die hun aangaan.',
    privacyPoliciesConditionsDerechosElem2ListaTitulo: 'Recht van toegang, rectificatie, verwijdering en verzet:',
    privacyPoliciesConditionsDerechosElem2ListaDescripcion: 'Belanghebbenden hebben recht op toegang tot hun persoonsgegevens, op het verzoeken om rectificatie van onjuiste gegevens of, in voorkomend geval, op verwijdering van de gegevens indien deze bijvoorbeeld niet langer noodzakelijk zijn voor de verwezenlijking van de doeleinden waarvoor zij werden verzameld.',
    privacyPoliciesConditionsDerechosElem3ListaTitulo: 'Beperking van verwerking:',
    privacyPoliciesConditionsDerechosElem3ListaDescripcion: 'In bepaalde omstandigheden kunnen betrokkenen ons verzoeken de verwerking van hun gegevens te beperken; in dat geval zullen wij de gegevens alleen bewaren met het oog op de afhandeling van of verdediging tegen klachten/vorderingen.',
    privacyPoliciesConditionsDerechosElem4ListaTitulo: 'Verzet tegen verwerking:',
    privacyPoliciesConditionsDerechosElem4ListaDescripcion: 'In bepaalde omstandigheden en op gronden die verband houden met hun bijzondere situatie, kunnen betrokkenen bezwaar maken tegen de verwerking van hun gegevens. Daarom zal MAILCOMMS GROUP de verwerking van de gegevens stopzetten, behalve als het gaat om dwingende legitieme redenen, of de uitoefening of verdediging van eventuele klachten/vorderingen.',
    privacyPoliciesConditionsDerechosElem5ListaTitulo: 'Overdraagbaarheid van de gegevens:',
    privacyPoliciesConditionsDerechosElem5ListaDescripcion: 'In bepaalde omstandigheden en om redenen die verband houden met hun specifieke situatie kunnen betrokkenen verzoeken om de in het contactformulier verstrekte persoonsgegevens te ontvangen in een gestructureerd machineleesbaar formaat voor algemeen gebruik en om deze gegevens zonder belemmering door te geven aan een andere voor de verwerking verantwoordelijke.',
    privacyPoliciesConditionsDerechosElem6ListaTitulo: 'Klacht bij een overheidsinstantie:',
    privacyPoliciesConditionsDerechosElem6ListaDescripcion: 'Indien u van mening bent dat uw recht op bescherming van persoonsgegevens is geschonden, kunt u een klacht indienen bij het Spaanse agentschap voor gegevensbescherming (Agencia Española de Protección de Datos)',
    privacyPoliciesConditionsDerechosAEPDTitle: 'AEPD',
    privacyPoliciesConditionsDerechosElem7ListaTitulo: 'Kanaal voor de uitoefening van uw rechten:',
    privacyPoliciesConditionsDerechosElem7ListaDescripcion: 'Betrokkenen kunnen de in de verordeningen erkende rechten uitoefenen ten opzichte van en in verband met elk van de verantwoordelijken voor de verwerking van de gegevens, via de adressen die als DPO (functionaris gegevensbescherming) zijn vermeld onder \'Verantwoordelijke\'.',
    privacyPoliciesConditionsInformacionAdicionalIntro: 'Meer informatie over Gegevensbescherming.',
    privacyPoliciesConditionsInformacionAdicionalLista1: 'De persoonsgegevens zijn verkregen via de kanalen die bedrijven van MAILCOMMS GROUP opgezet hebben voor het beheer van de door u of uw bedrijf aangegane diensten.',
    privacyPoliciesConditionsInformacionAdicionalLista2: 'De categorieën gegevens die zullen worden verwerkt, zijn de gegevens die worden verstrekt voor de ontwikkeling van elke gecontracteerde dienst.',
    privacyPoliciesConditionsInformacionAdicionalLista3: 'MAILCOMMS GROUP verwerkt geen speciaal beschermde gegevens.',
    privacyPoliciesConditionsPoliticaInformacionIntro: 'MailTecK, S.A. Beleid informatiebeveiliging en -privacy gebaseerd op norm ISO 27001 -> Meer lezen.',
    privacyPoliciesConditionsPoliticaInformacio2nIntro: 'Customer Communication Tecknalia, S.L. Beleid informatiebeveiliging en -privacy gebaseerd op norm ISO 27001 -> Meer lezen.',
    privacyPoliciesConditionsPoliticaInformacionTituloH: 'BIJLAGE I: BELEID INZAKE HET MANAGEMENTSYSTEEM VOOR INFORMATIEBEVEILIGING EN -PRIVACY',
    privacyPoliciesConditionsPoliticaInformacionP1: 'Dit Beleid Informatiebeveiliging en -privacy toont hoe de Directie zich inzet door de organisatie te voorzien van middelen, verantwoordelijkheden en procedures, om de naleving van de geldende regelgeving te waarborgen, alsmede de integriteit, vertrouwelijkheid en beschikbaarheid van informatie en systemen, hetgeen van cruciaal belang is voor de veiligheid, privacy en continuïteit van onze organisatie, alsmede die van onze klanten.',
    privacyPoliciesConditionsPoliticaInformacionP2: 'MailTecK Group is zich er goed van bewust dat informatiebeveiliging en -privacy voor onze klanten een kostbaar goed is en heeft daarom een managementsysteem voor informatiebeveiliging en -privacy opgezet in overeenstemming met de eisen van de normen ISO/IEC 27001:2013 en ISO/IEC 27701:2019, om de vereiste veiligheidsniveaus, continuïteit van de geleverde diensten en naleving van de wet bij de verwerking van persoonsgegevens te waarborgen, en zo de risico\'s die voortvloeien uit dit beheer tot een minimum te beperken.',
    privacyPoliciesConditionsPoliticaInformacionCCTP2: 'Customer Comms is zich er goed van bewust dat informatiebeveiliging en -privacy voor onze klanten een kostbaar goed is en heeft daarom een managementsysteem voor informatiebeveiliging en -privacy opgezet in overeenstemming met de eisen van de normen ISO/IEC 27001:2013 en ISO/IEC 27701:2019, om de vereiste veiligheidsniveaus, continuïteit van de geleverde diensten en naleving van de wet bij de verwerking van persoonsgegevens te waarborgen, en zo de risico\'s die voortvloeien uit dit beheer tot een minimum te beperken.',
    privacyPoliciesConditionsPoliticaInformacionP3: 'Het doel van het Informatiebeveiligings- en privacybeleid is het noodzakelijke actiekader vast te stellen om informatiebronnen te beschermen tegen bedreigingen, intern of extern, opzettelijk of per ongeluk, teneinde de naleving van de vertrouwelijkheid, integriteit en beschikbaarheid van informatie te waarborgen.',
    privacyPoliciesConditionsPoliticaInformacionP4: 'De doeltreffendheid en de uitvoering van het managementsysteem voor informatiebeveiliging en -privacy vallen onder de directe verantwoordelijkheid van de Commissie voor informatiebeveiliging en privacy, die verantwoordelijk is voor de goedkeuring, verspreiding en handhaving van dit beveiligings- en privacybeleid. In naam en voor rekening van het bedrijf is een manager voor het informatiebeveiligings- en privacymanagementsysteem aangesteld, die voldoende gezag heeft om een actieve rol te spelen in het informatiebeveiligings- en privacymanagementsysteem en toezicht te houden op de uitvoering, de ontwikkeling en het onderhoud ervan.',
    privacyPoliciesConditionsPoliticaInformacionP5: 'Bovendien is, om te voldoen aan de huidige regelgeving inzake gegevensbescherming, een functionaris voor gegevensbescherming (Data Protection Officer - DPO) aangesteld om de veiligheid en privacy van persoonsgegevens te waarborgen, met name rekening houdend met de activiteit van de onderneming.',
    privacyPoliciesConditionsPoliticaInformacionP6: 'Het Comité voor informatiebeveiliging en privacy ontwikkelt de risicoanalysemethode die in het informatiebeveiligings- en privacybeheersysteem wordt gebruikt, en keurt deze goed.',
    privacyPoliciesConditionsPoliticaInformacionP7: 'Eenieder op wiens activiteit direct of indirect de voorschriften van het informatiebeveiligings- en privacymanagementsysteem van toepassing kunnen zijn, is verplicht het beveiligings- en privacybeleid strikt na te leven.',
    privacyPoliciesConditionsPoliticaInformacionP8: 'Bij MailTecK Group worden alle nodige maatregelen getroffen om te voldoen aan de toepasselijke veiligheids- en privacyvoorschriften in het algemeen en aan de voorschriften inzake IT-beveiliging met betrekking tot het IT-beleid, de beveiliging van gebouwen en faciliteiten, en het gedrag van werknemers en derden die bij MailTeck Group betrokken zijn bij het gebruik van IT-systemen en de verwerking van persoonsgegevens. De maatregelen die nodig zijn om de informatiebeveiliging en -privacy te waarborgen door de toepassing van regels, procedures en controles, moeten het mogelijk maken om de vertrouwelijkheid, de integriteit en de beschikbaarheid van informatie te waarborgen die van essentieel belang zijn voor:',
    privacyPoliciesConditionsPoliticaInformacionCCTP8: 'Bij Customer Comms worden alle nodige maatregelen getroffen om te voldoen aan de toepasselijke veiligheids- en privacyvoorschriften in het algemeen en aan de voorschriften inzake IT-beveiliging met betrekking tot het IT-beleid, de beveiliging van gebouwen en faciliteiten, en het gedrag van werknemers en derden die bij Customer Comms betrokken zijn bij het gebruik van IT-systemen en de verwerking van persoonsgegevens. De maatregelen die nodig zijn om de informatiebeveiliging en -privacy te waarborgen door de toepassing van regels, procedures en controles, moeten het mogelijk maken om de vertrouwelijkheid, de integriteit en de beschikbaarheid van informatie te waarborgen die van essentieel belang zijn voor:',
    privacyPoliciesConditionsPoliticaInformacionLista1: 'Voldoen aan de huidige wetgeving, voorschriften en normen inzake privacy en informatiesystemen en aan alle contractuele eisen inzake gegevensbescherming, alsmede aan de eisen die het bedrijf passend acht om uit te voeren met het oog op de continue verbetering van de organisatie.',
    privacyPoliciesConditionsPoliticaInformacionLista2: 'De vertrouwelijkheid te waarborgen van de gegevens die worden beheerd door Mailteck Group.',
    privacyPoliciesConditionsPoliticaInformacionCCTLista2: 'De vertrouwelijkheid te waarborgen van de gegevens die worden beheerd door Customer Comms.',
    privacyPoliciesConditionsPoliticaInformacionLista3: 'Zorgen voor de beschikbaarheid van informatiesystemen, zowel bij de aan de klanten aangeboden diensten als bij het interne beheer.',
    privacyPoliciesConditionsPoliticaInformacionLista4: 'Zorgen voor het vermogen om te kunnen reageren in noodsituaties, door het functioneren van kritieke diensten zo spoedig mogelijk te herstellen.',
    privacyPoliciesConditionsPoliticaInformacionLista5: 'Om onnodige wijzigingen in informatie te voorkomen.',
    privacyPoliciesConditionsPoliticaInformacionLista6: 'Bevordering van bewustmaking en opleiding inzake informatiebeveiliging en -privacy.',
    privacyPoliciesConditionsPoliticaInformacionLista7: 'Bevorderen van en deelnemen aan de verwezenlijking van voortdurende verbeteringen van het managementsysteem voor informatiebeveiliging en -privacy.',
    cookiesPoliciesIntroText: 'Deze website gebruikt cookies om uw ervaring te verbeteren tijdens het navigeren door de website. U kunt de cookie-instellingen op ieder moment wijzigen. Cookies die als noodzakelijk zijn geclassificeerd, worden in uw browser opgeslagen en zijn essentieel voor de werking van de basisfunctionaliteiten van de website. Cookies van derden worden alleen met uw toestemming in uw browser opgeslagen en helpen ons te analyseren en te begrijpen hoe deze website wordt gebruikt. U heeft de optie om de ontvangst van deze cookies uit te schakelen. Maar als u sommige van deze cookies uitschakelt, kan dit echter ertoe leiden dat sommige functies niet langer beschikbaar zijn. Voor informatie over het verwijderen van cookies kunt u de helpfunctie van uw browser raadplegen.',
    cookiesPoliciesNecessaryCookies: 'Noodzakelijke cookies',
    cookiesPoliciesNecessaryCookiesText: 'Dit soort cookies is absoluut noodzakelijk voor de goede werking van de website. Deze categorie omvat alleen cookies die zorgen voor basisfunctionaliteit en beveiligingsfuncties van de website. Deze cookies verzamelen geen persoonlijke informatie over websitegebruikers en slaan deze niet op.',
    cookiesPoliciesUnnecessaryCookies: 'Niet-noodzakelijke cookies',
    cookiesPoliciesUnnecessaryCookiesText: 'Cookies die niet specifiek noodzakelijk zijn voor het functioneren van de website en die specifiek worden gebruikt om persoonlijke gebruikersgegevens te verzamelen via analyses, advertenties en andere ingebedde inhoud, worden \'niet-noodzakelijke cookies\' genoemd. Het is verplicht de toestemming van de gebruiker te verkrijgen voordat deze cookies op uw website gebruikt mogen worden.',
    cookiesPoliciesGoogleAnalyticsCookies: 'Cookies van Google Analytics',
    cookiesPoliciesGoogleAnalyticsCookiesText: 'Voor meer informatie over dit type cookies kunt u het document \'Gebruik van Google Analytics-cookies op websites\' raadplegen',
    cookiesPoliciesGoogleAnalyticsCheckboxText: 'Cookies actief houden',
    cookiesNotificationBarText: 'Wij gebruiken cookies, zowel onze eigen cookies als die van derden, om het bezoek te meten, de functionaliteit van de website te garanderen en de navigatie op de website te analyseren. Hieronder kunt u cookies accepteren of het gebruik van cookies instellen/afwijzen. Meer informatie over de eigenaar van de website, de verantwoordelijke voor de behandeling van cookies en hun contactgegevens kunt u vinden op onze',
    cookiesNotificationBarConfig: 'Instellen',
    cookiesPoliciesModalIntro: 'Inleiding',
    cookiesPoliciesModalIntroP1: 'Deze website gebruikt cookies en/of vergelijkbare technologieën die informatie opslaan en ophalen bij uw gebruik van de website, om de gebruikerservaring te verbeteren en te optimaliseren.',
    cookiesPoliciesModalIntroP2: 'In het algemeen kunnen deze technologieën verschillende doelen dienen, zoals bijvoorbeeld het herkennen van u als gebruiker, het verkrijgen van informatie over uw navigatiegedrag of het personaliseren van de manier waarop inhoud wordt weergegeven.',
    cookiesPoliciesModalIntroP3: 'In overeenstemming met Spaans Koninklijk Besluit Wet 13/2012 en de uitwerking daarvan op artikel 22.2 van de Spaanse Wet op de diensten van de informatiemaatschappij en de elektronische handel, informeert dit Beleid u over de verschillende cookies die CUSTOMER COMMUNICATIONS TECKNALIA, S.L. verzamelt via de verschillende communicatiemiddelen of -kanalen, eigen of van derden, al dan niet vrijgesteld van de informatieplicht, en de verschillende manieren waarop u deze kunt weigeren, toestaan of blokkeren.',
    cookiesPoliciesModalIntroP4: 'Hieronder vindt u gedetailleerde informatie over: wat Cookies zijn, welk type deze website gebruikt, hoe ze kunnen worden gedeactiveerd in een browser en hoe u specifiek de installatie van Cookies van derden kunt blokkeren.',
    cookiesPoliciesModalResponsibility: 'Wie is de verantwoordelijke voor gebruik van cookies op deze website?',
    cookiesPoliciesModalResponsibilityP1: 'De verantwoordelijke voor gebruik van cookies is CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (hierna CCOMMS), met N.I.F.-nummer (belastingidentificatienummer/btw-nummer) B-86414000 en met geregistreerd adres in Leganés (Madrid), Avenida de la Recomba 14, volgens de regels geregistreerd in het Handelsregister van Madrid, Volume 29.801, Folio 98, Sectie 8ª, Blad nummer M-536210.',
    cookiesPoliciesModalResponsibilityP2: 'E-mailadres van de functionaris gegevensbescherming (DPO).',
    cookiesPoliciesModalWhatIsCookie: 'Wat is een cookie?',
    cookiesPoliciesModalWhatIsCookieP1: 'Een cookie is een gegeven, bestand of gegevensdrager dat/die door een webbrowser automatisch opgeslagen wordt op het apparaat (smartphone, tablet of aangesloten tv) van een gebruiker bij het openen van webpagina\'s, accounts van sociale netwerken of bij het ontvangen van e-mail.',
    cookiesPoliciesModalWhatIsCookieP2: 'Cookies maken automatisch opslaan en opvragen mogelijk van gegevens over het gemaakte gebruik van voornoemde digitale media en de computer of het apparaat waarmee u de gegevens benadert.',
    cookiesPoliciesModalWhatIsCookieP3: 'Een cookie is slechts data, gegevens, die worden opgeslagen op de computer van de gebruiker. Het is dus geen software of stukjes code, het zijn gewoon gegevens. Daarom kunnen cookies in principe geen virussen overdragen en uitvoeren of malware zoals Trojaanse paarden of spyware installeren.',
    cookiesPoliciesModalCookieTypes: 'Wat voor soort cookies staan er op de website en met welk doel?',
    cookiesPoliciesModalCookiesInstall: 'Welke cookies kunnen worden geplaatst op de website van CertySign?',
    cookiesPoliciesModalCookiesInstallP1: 'Toegang tot de website van CertySign houdt in dat er cookies, eigen en/of van derden, kunnen worden geïnstalleerd. Meer informatie over deze cookies vindt u in de volgende tabel:',
    cookiesPoliciesModalBrowserConfigCookies: 'Hoe kunt u cookies in uw browser instellen?',
    cookiesPoliciesModalBrowserConfigCookiesP1: 'U heeft de optie om geen cookies te ontvangen of niet te worden geïnformeerd over de instelling ervan door uw browser daartoe te configureren. Alle cookies, behalve Flash-cookies, kunnen met uw browser worden beheerd, gewoonlijk onder \'Opties\' of \'Voorkeuren\'.',
    cookiesPoliciesModalBrowserConfigCookiesP2: 'U kunt ze volledig uitschakelen, in welk geval uw browser niet zal toestaan dat er ook maar één cookie wordt geïnstalleerd (waardoor u niet van alle functionaliteiten van de website gebruik kunt maken), of alleen die van bepaalde websites, waardoor u wel kunt genieten van de geavanceerde functionaliteiten van de websites die de privacy van uw gegevens respecteren, en de websites kunt uitsluiten die dat niet doen.',
    cookiesPoliciesModalBrowserConfigCookiesP3: 'De volgende links geven toegang tot de belangrijkste pagina\'s die hierbij behulpzaam zijn:',
    cookiesPoliciesModalBrowserConfigCookiesP4: 'Ook kan de Gebruiker zich verzetten tegen het gebruik van bepaalde cookies door middel van de volgende diensten:',
    cookiesPoliciesModalBrowserConfigCookiesLI1: 'Cookies blokkeren in Chrome',
    cookiesPoliciesModalBrowserConfigCookiesLI2: 'Cookies blokkeren in Safari',
    cookiesPoliciesModalBrowserConfigCookiesLI3: 'Cookies blokkeren in Firefox',
    cookiesPoliciesModalBrowserConfigCookiesLI4: 'Cookies blokkeren in Internet Explorer',
    cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
    cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
    cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
    cookiesPoliciesModalUnavailableCookies: 'Wat gebeurt er als u de cookies uitschakelt?',
    cookiesPoliciesModalUnavailableCookiesP1: 'Als u cookies weigert, kunt u op onze Website blijven navigeren, hoewel het gebruik van sommige diensten beperkt kan zijn en uw ervaring dus minder goed kan zijn.',
    cookiesPoliciesModalUnavailableCookiesP2: 'Weigeren, blokkeren of niet toestaan van de cookies die in dit beleid worden beschreven, kan van invloed zijn op het correct navigeren door de inhoud die op de website wordt weergegeven.',
    cookiesPoliciesModalAcceptCookies: 'Accepteren van het cookiebeleid',
    cookiesPoliciesModalAcceptCookiesP1: 'Als u drukt op de knop \'COOKIES ACCEPTEREN\' wordt aangenomen dat u het gebruik van cookies accepteert.',
    cookiesPoliciesModalAcceptCookiesP2: 'Echter, iedere keer dat u een sessie start zal het Cookiebeleid onderaan elke pagina van de site worden weergegeven, zodat u ervan op de hoogte bent.',
    cookiesPoliciesModalUpgradeCookies: 'Actualiseren wij ons Cookiebeleid?',
    cookiesPoliciesModalUpgradeCookiesP1: 'Het is mogelijk dat de informatie die in dit Cookiebeleid wordt verstrekt, wijzigingen ondergaat als gevolg van updates of wijzigingen in het beleid inzake de behandeling van cookies; evenzo kan het Spaanse Agentschap voor gegevensbescherming instructies en/of aanbevelingen uitvaardigen die tot wijziging van dit beleid kunnen leiden.',
    cookiesPoliciesModalUpgradeCookiesP2: 'Wij raden u daarom aan dit beleid elke keer dat u onze website bezoekt door te nemen, zodat u goed geïnformeerd bent over hoe en waarom wij cookies gebruiken.',
    cookiesPoliciesModalRecipientCookies: 'Wie zijn de ontvangers van de informatie en hoe beïnvloedt het gebruik van cookies de bescherming van persoonsgegevens?',
    cookiesPoliciesModalRecipientCookiesP1: 'Meer informatie over de verwerking van uw gegevens vindt u in het',
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Gegevensbeschermingsbeleid',
    cookiesPoliciesModalContactInfoCookies: 'Contactinformatie',
    cookiesPoliciesModalContactInfoCookiesP1: 'In geval van vragen, onduidelijkheden en/of suggesties met betrekking tot cookies kunt u contact met ons opnemen per e-mail',
    cookiesPoliciesModalDatatableCookieType: 'Type cookie',
    cookiesPoliciesModalDatatableTreatmentTerm: 'Verwerkingsperiode',
    cookiesPoliciesModalDatatableTypeAnalytics: 'Analytische cookies',
    cookiesPoliciesModalDatatableTypeSelf: 'Eigen cookies',
    cookiesPoliciesModalDatatableTypeAnalyticsD: 'Telkens wanneer een Gebruiker een Dienst bezoekt, genereert een tool van een externe provider een analytische Cookie op de computer van de Gebruiker. Deze Cookie, die alleen tijdens het bezoek wordt gegenereerd, zal bij toekomstige bezoeken aan CCOMMS gebruikt worden om de bezoeker anoniem te identificeren.',
    cookiesPoliciesModalDatatableTypeAnalyticsF: 'Anonieme identificatie van navigerende Gebruikers mogelijk te maken via de Cookie (identificeert browsers en apparaten, geen personen) en dus een schatting bij te houden van het aantal bezoekers en de ontwikkeling in de tijd daarvan. De meest bezochte en aantrekkelijkste inhoud voor gebruikers te bepalen. Om te weten of de Gebruiker die de site bezoekt een nieuwe of een terugkerende bezoeker is. Behalve als de gebruiker besluit zich op CCOMMS te registreren, zal de Cookie nooit in verband worden gebracht met persoonlijke gegevens die rechtstreekse identificatie van de gebruiker mogelijk maken. Deze cookies worden alleen gebruikt voor statistische doeleinden die helpen om de ervaring van de Gebruiker op de site te optimaliseren en te verbeteren.',
    cookiesPoliciesModalDatatableTypeAnalyticsT: 'Persistente cookies',
    cookiesPoliciesModalDatatableTypeSelfD: 'Dit type Cookies maakt het mogelijk om de informatie uit te breiden die getoond wordt aan elke anonieme Gebruiker in de Diensten van COMMS.',
    cookiesPoliciesModalDatatableTypeSelfF: 'Zo worden onder meer de duur of de frequentie van weergave van de verschillende rubrieken, de interactie daarmee, of de navigatiepatronen en/of het navigatiegedrag van de Gebruiker opgeslagen.',
    cookiesPoliciesModalDatatableTypeSelfT: 'Persistente cookies',
    eventInfo: 'Informatie over de gebeurtenis',
    manual: 'Handleiding',
    guia: 'Gids',
    supportLoggedPhonePrefferedMessage: '(Support ingelogde gebruiker - voorkeurstelefoonnummer)',
    supportLoggedNotPhoneMessage: 'Geen telefoon',
    supportLoggedUserMessage: '(Support ingelogde gebruiker)',
    supportNotLoggedUserWebMessage: '(Login - Support via website) De gebruiker vraagt hulp bij wachtwoordherstel',
    supportNotLoggedUserNotAvailableMessage: 'Niet beschikbaar',
    supportNotLoggedUserC2CMessage: '(Login - Support via C2C) De gebruiker vraagt telefonische hulp bij wachtwoordherstel',
    processA4NotSupported: 'De documenten moeten A4 zijn, mogen niet meer dan 389 pagina\'s bevatten en niet groter zijn dan 15 MB',
    cookiesGAMessage: 'Interne meting met Google Analytics. Deze cookies slaan een unieke identificatiecode op om de gebruiker bij toekomstige bezoeken aan de website te herkennen.',
    cookiesGAFGenericMessage: 'Interne meting met Google Analytics.',
    cookiesCookieMessage: 'Cookie',
    cookiesNecessaryCookieDescription: 'Deze cookie is noodzakelijk en wordt gebruikt om voorkeuren van de gebruiker met betrekking tot het cookiebeleid vast te leggen.',
    documentHtmlRule: 'Type geaccepteerd bestand - HTML',
    rulesFilesSizePostal: 'Het bestand mag maximaal 15MB zijn',
    rulesFilesSizeEmail: 'Het bestand mag maximaal 14 MB zijn',
    rulesFilesSizeMultiple: 'De totale maximale grootte van de bestanden is 14 MB',
    rulesFilesSizeHtml: 'Het bestand mag maximaal 1 MB zijn',
    rulesFilesExtensionHtml: 'Het bestand mag een HTML zijn',
    group_cannot_be_empty: 'De groep :group_name: mag niet leeg zijn',
    language: 'Taal',
    defaultLanguage: 'Default taal',
    transactionDownloadPee: 'ePOD bekijken',
    transactionTracking: 'Volgen van de zending',
    documentOrder: 'Volgorde van documenten bepalen',
    yes: 'Ja',
    no: 'Nee',
    notifications: 'Meldingen',
    activateNotifications: 'Inschakelen',
    deactivateNotifications: 'Uitschakelen',
    selectCategoryNotifications: 'Actie selecteren voor meldingen',
    activateCategoryNotifications: 'Alle inschakelen',
    deactivateCategoryNotifications: 'Alle uitschakelen',
    customCategoryNotifications: 'Aanpassen',
    enabledNotifications: 'Meldingen ingeschakeld',
    disabledNotifications: 'Meldingen uitgeschakeld',
    customNotifications: 'Gepersonaliseerde meldingen',
    selectNotifications: 'Meldingsinstellingen selecteren',
    enabledNotificationsDescriptionText: 'Elke gebeurtenis zal een melding genereren. U zult kunnen kiezen tussen ieder keer een e-mail ontvangen, of een dagelijks overzicht met de meldingen gegroepeerd.',
    disabledNotificationsDescriptionText: 'U ontvangt geen meldingen over de status van gedane zendingen.',
    customNotificationsDescriptionText: 'U kunt processen selecteren waarvan u meldingen over hun status wilt ontvangen en processen waarvan u dat niet wilt.',
    enabledNotificationsConfigurationText: 'Instelling',
    enabledNotificationsConfigurationRealTime: 'Real-time meldingen',
    enabledNotificationsConfigurationGrouped: 'Gegroepeerde meldingen',
    selectEnabledNotifications: 'Meldingstypes selecteren',
    enabledNotificationsRealTimeDescriptionText: 'Ontvang onmiddellijk een e-mail voor elke gebeurtenis. Als u een specifiek tijdstip kiest, ontvangt u ook een e-mail met een overzicht van alle gebeurtenissen die buiten die uren plaatsvinden.',
    enabledNotificationsGroupedDescriptionText: 'U ontvangt op het gekozen tijdstip een e-mail met het overzicht van alle gebeurtenissen van de vorige dag.',
    enabledNotificationsScheduleRangeDescriptionText: 'Melden tijdens de volgende uren.',
    enabledNotificationsScheduleDescriptionText: 'Alleen melden tijdens de volgende uren.',
    chooseNotificationsRange: 'Kies hoe en wanneer u meldingen wilt ontvangen.',
    enabledNotificationsCustomText: 'Personalisering van meldingen',
    realTimeNotificationsDescription: 'Meldingen worden in real-time verzonden. Voor elke gebeurtenis die plaatsvindt, wordt een e-mail verstuurd om u op de hoogte te stellen van de status, op het gekozen tijdstip.',
    languageAndTimezone: 'Taal en tijdzone',
    defaultTimeZone: 'Standaard tijdzone',
    user_process_notifications_updated: 'Bijgewerkte meldingen',
    changeSectionConfirmation: 'Wijzigingen die nog moeten worden opgeslagen',
    changeSectionConfirmationText: 'U hebt wijzigingen aangebracht die nog moeten worden opgeslagen. Als u ze nu wilt opslaan, klik dan op',
    groupedNotificationsDescription: 'Bij de verzonden meldingen is een verslag gevoegd met een lijst van alle transacties en de laatst geregistreerde gebeurtenis voor elk ervan, op het gekozen tijdstip.',
    groupedNotificationsReportTime: 'Op welk tijdstip wilt u het verslag ontvangen',
    discard: 'Negeren',
    cost_center_updated: 'Kostenplaats bijgewerkt',
    loginInfo: 'Informatie over aanmelding',
    lastLoginSuccess: 'Laatste aanmelding',
    lastLoginError: 'Laatste mislukte toegangspoging',
    noRecord: 'Zonder registratie',
    charLimitLength: 'Veld moet {limit} tekens of minder bevatten',
    requiredLastName: 'Achternaam is verplicht',
    optional: 'Optioneel',
    availableVariables: 'Beschikbare variabelen',
    twoFactorText: 'De gebruiker heeft twee-factor-authenticatie nodig. Om in te loggen voert u de code in die wordt weergegeven in uw toepassing voor multifactor-toegangsbeheer.',
    mfa_configuration_required: 'De gebruiker heeft twee-factor-authenticatie nodig. Om deze te configureren, scant u de QR van uw toepassing voor multifactor-toegangsbeheer. Voer vervolgens de code in het onderstaande vakje in.',
    code: 'Code',
    codeRequired: 'Code vereist om te kunnen inloggen',
    mfa_code_invalid: 'Code is niet correct',
    communicationContent: 'Inhoud van de communicatie',
    uploadFiles: 'Documenten bijvoegen',
    modifyFiles: 'Documenten wijzigen',
    fileResume: '{nfiles} bestand(en) ({size}) MB in totaal',
    moreThan1City: 'De postcode komt overeen met meer dan één plaats, zie het keuzemenu of bewerk het veld handmatig.',
    shipmentDataSectionDescription: 'Hier kunt u de inhoud van de communicatie toevoegen. De documenten moeten type PDF zijn en formaat A4',
    zipCodeFormat: 'Voer een geldige postcode in',
    deleteRecipient: 'Deze geadresseerde verwijderen',
    mobileAddRecipientDescription: 'Meer dan één geadresseerde?',
    errorFilesTotalSizeExceeded: 'De totale bestandsgrootte mag niet groter zijn dan 15MB',
    fileExtensionNotAccepted: 'De extensie van het bestand is niet geldig. (Toegestane bestandsextensies zijn= {acceptedExtensions})',
    nameAndSurnameLength: 'De voor- en achternaam mogen niet langer zijn dan 99 tekens',
    batchModeTitle: 'Batchverzending',
    batchModeCSVStep: 'Ontvangers',
    batchModeAttachmentsStep: 'Vereiste bestanden',
    batchModeDownloadSampleCSV: 'Voorbeeldbestand downloaden',
    batchModeExtraCSVStepIntroduction: 'Eerst dien je een <strong>CSV-</strong> of <strong>XLS-</strong>bestand te uploaden met de gegevens van de verzendingen. Vervolgens kun je een voorbeeldbestand downloaden met de benodigde velden.',
    batchModeExtraCSVStepIntroduction2: 'Zodra je het bestand hebt klaargemaakt, kun je het toevoegen door erop te klikken of te slepen naar de daarvoor bestemde plek. Vergeet niet dat het bestand <strong>geen lege regels of fouten</strong> in de ontvangers mag bevatten.',
    batchModeExtraCSVStepIntroduction3: 'Zodra de structuur van het bestand is gecontroleerd, wordt de knop <strong>Doorgaan</strong> geactiveerd en kun je doorgaan met het proces.',
    batchModeExtraFilesStepIntroduction: 'In deze stap worden de nodige middelen toegevoegd om het bericht naar elke ontvanger te kunnen sturen.<br><br>Er zijn in totaal <b>{count}</b> bestanden gevonden. Je kunt ze toevoegen door erop te klikken of ze te slepen naar de daarvoor bestemde plek.<br><br>Vergeet niet dat het totaal aantal bestanden van elke verzending niet groter mag zijn dan <b>{fileSizeLimit} MB</b> en dat de verzending een maximale grootte heeft van <b>{totalSizeLimit} GB</b>.',
    extraFilesTableHeaderFileName: 'Bestandsnaam',
    extraFilesTableHeaderStatus: 'Status',
    extraFilesTableStatusNotFound: 'Wachten om te uploaden',
    extraFilesTableStatusProcessing: 'Aan het verwerken',
    extraFilesTableStatusUploaded: 'OK',
    extraFilesTableStatusUploadedCorrectly: 'Verwerkt',
    extraFilesTableHeaderActions: 'Acties',
    batchModePreviewStep: 'Voorbeeld en verzenden',
    batchModeStep1RemoveFile: 'Bestand verwijderen',
    batchModeCSVTableTitle: 'Het bestand <strong>{filename}</strong> bevat <strong>{nOfRecipients}</strong> ontvangers.',
    batchModeCSVTableSubtitle: 'Hieronder zie je een voorbeeld van de eerste ontvangers van het bestand. Klik op ‘Meer bekijken’ voor meer informatie.',
    batchModeCSVTableSubtitleNoData: 'Het geselecteerde bestand bevat geen ontvangers.',
    batchModeNoDataTitle: 'Geen bestand geselecteerd. Eenmaal toegevoegd beschik je over de eerste ontvangers van het bestand.',
    dropZoneText: 'Klik of sleep je bestanden hierheen',
    dropZoneTextSingle: 'Klik of sleep je bestand hierheen',
    batchModeDialogTitle: 'Ontvangers toevoegen',
    batchModeDialogManualColTitle: 'Handmatig',
    batchModeDialogManualColDescription: 'Als je een of meer verzendingen apart wil verwerken, kies je voor deze optie.',
    batchModeDialogBatchColTitle: 'Batchverzending',
    batchModeDialogBatchColDescription: 'Als je eenvoudig een csv- of xls-bestand met meerdere ontvangers en documenten wil verzenden, kies dan deze optie.',
    batchModeDialogBatchBtnManual: 'Apart verzenden',
    batchModeDialogBatchBtnBatch: 'Batchverzending',
    batchModeResourceTableNOfFilesFound: '<b>{nOfRequiredFiles}</b> wachtende bestanden om toegevoegd te worden',
    batchModeResourceTableAllFilesAdded: 'Alle noodzakelijke bestanden zijn toegevoegd',
    // batchModeResourceTableStatus: 'Actuele status= <b>$currentFiles$</b>/$nOfRequiredFiles$',
    batchModeResourceTableExpandText: 'Klik voor meer details',
    batchModeResourceTableFilenameText: 'Bestandsnaam',
    batchModeResourceTableFoundOnNRows: 'betrokken ontvangers',
    batchModeResourceTableErrorFilenameInvalid: 'Een of meer van de bestanden die geprobeerd zijn toe te voegen komen niet overeen met de verwachte bestanden.',
    batchModeResourceTableErrorFileSizeLimit: 'De bestandsgrootte is groter dan {size}MB',
    batchModeResourceTableErrorTotalFileSizeLimit: 'Het totaal van de geüploade bestanden mag niet groter zijn dan {size}GB',
    batchModeResourceTableInfoFileReplaced: 'Het bestand is vervangen {filename}.',
    seeMore: 'Meer bekijken',
    seeLess: 'Minder bekijken',
    pending: 'Lopend',
    batchModePreviewText: 'Na verwerking kun je de transacties bekijken in de betreffende weergave en ontvang je een e-mail met de informatie van elke zending, evenals de transactie-identificatiecode die aan elke ontvanger is gekoppeld.',
    batchModePreviewText1: 'Als een van de zendingen niet kan worden uitgevoerd, wordt in de e-mail vermeld welke zendingen niet zijn verwerkt en wat de reden van de fout is.',
    csvError: 'Het bestand kon niet worden verwerkt',
    csvErrorDetailStructure: 'De structuur van het bestand bevat een fout.',
    csvErrorDetailIncorrectFields: 'De velden komen niet overeen met de vereiste procesgegevens',
    csvErrorDetailIncorrectExtensions: 'De volgende rijen bevatten ongeldige bestandsextensies:',
    batchModeStep2SpaceLeft: 'Gebruikte ruimte',
    batchModeStep2PendingFiles: 'Lopende bestanden',
    launchDataKoBatchMode: 'De verstrekte gegevens zijn ongeldig',
    launchDataKoBatchModeStep1: 'Er is geen bestand geselecteerd',
    launchDataOkBatchModeStep1: 'De bestandsstructuur is correct, je kunt doorgaan met het proces',
    launchDataKoBatchModeStep2: 'De noodzakelijke bestanden toevoegen',
    launchDataOkBatchModeStep2: 'De benodigde bestanden zijn toegevoegd, je kunt doorgaan met het proces',
    launchDataOkBatchMode: 'De gegevens zijn correct, je kunt doorgaan met het proces',
    batchModeProcessLaunchedStep1: 'Het registratieproces starten',
    batchModeProcessLaunchedStep2: 'Uploaden van middelen die nodig zijn voor verzendingen',
    batchModeProcessLaunchedStep3: 'Bestand van ontvangers uploaden',
    batchModeProcessLaunchedStep4: 'Registratieproces voltooid',
    transactionsProcessedBatchMode: 'Batchverzending succesvol geregistreerd',
    transactionProcessingBatchMode: 'Verwerking van batchverzending...',
    transactionProcessingBatchModeError: 'Fout in verwerking batchverzending',
    transactionProcessingBatchModeErrorDescription: 'Er is een fout opgetreden in de batchverwerking, neem contact op met support.',
    csvErrorDialogDescription: 'Vergeet niet dat je een <b>CSV-</b> of <b>XLS-</b> bestand dient de uploaden en dat het bestand bij geen enkele ontvanger <strong>geen lege regels of fouten mag bevatten</strong>.',
    extraFileError: 'Het bestand kon niet worden toegevoegd',
    extraFileErrorDialogDescription: 'De maximale grootte per transactie is <b>{fileSizeLimit} MB</b> en de maximale grootte van de gehele batchverzending dient maximaal <b>{totalSizeLimit} GB</b> te zijn.',
    extraFileErrorTransactionSizeLimit: 'De maximale grootte voor verzending is overschreden in de volgende records= {rows}',
    htmlEditorInfoParagraph1: 'In deze stap pas je de inhoud aan van de e-mail die naar iedere ontvanger wordt verzonden.',
    htmlEditorInfoParagraph3: 'Je kunt een bewerkingsmodus selecteren:',
    htmlEditorInfoBullet1: '<span>Selecteren van sjabloon</span>. Selecteer een sjabloon en maak de nodige aanpassingen vanuit de editor.',
    htmlEditorInfoBullet2: '<span>Gepersonaliseerde HTML</span>. Upload een aangepast HTML-bestand en maak de nodige aanpassingen vanuit de editor.',
    htmlEditorButtonUploadFile: 'HTML UPLOADEN',
    htmlEditorDragAndDrop: 'Klik hier of sleep de bijlagen naar de e-mail.',
    batchModeExtraCSVStepIntroductionVariables: 'De volgende variabelen zijn beschikbaar voor gebruik in de transactievelden:',
    batchModeContactId: 'Referentie van de ontvanger',
    batchModeContactName: 'Voornaam van de ontvanger',
    batchModeContactSurname: 'Achternaam van de ontvanger',
    htmlEditorTemplateSelected: 'Gekozen sjabloon',
    htmlSubjectError: 'Het onderwerp bevat meer dan <span>{subjectLimit}</span> tekens',
    htmlEditorInfoParagraph4: 'Onthoud dat de maximale totale bestandsgrootte <b>{totalSizeLimit} Mb</b> is.',
    backToProcess: 'Terug naar processen',
    infoAndHelp: 'Informatie en hulp',
    modelEmailHtmlTemplateSelector: 'Sjabloon selecteren',
    modelEmailEmailBodyNotHtml: "Het geselecteerde bestand is geen html",
    templateSelectorDefaultTemplate: 'Standaard',
    templateSelectorCustomTemplate: 'Aangepast',
    templateSelectorNoLogoTemplate: 'Zonder logo',
    filesExcedingSizeLimit: 'Bestanden die de maximale grootte overschrijden',
    emailAttachmentsSectionTitle: 'E-mailbijlagen',
    emailContent: 'Inhoud e-mail',
    emailSubject: 'Onderwerp e-mail',
    multipleFileExtensionError: 'Hay uno o más ficheros con extensiones inválidas. (Las extensiones aceptadas son: {acceptedExtensions})' ,
    filePasswordProtectedOrDamage: " Ongeldig bestand. Controleer of het bestand niet beschadigd is of met een wachtwoord is beveiligd en probeer het opnieuw",
    backLostChangesHTMLText: "Als u teruggaat, verliest u de wijzigingen in de e-mailsjabloon evenals de bijgevoegde bestanden. <br> Bent u zeker dat u terug wilt naar de vorige stap?",
    backLostChangesHTMLConfirmBack: "Terug naar de vorige stap",
    backLostChangesHTMLConfirmContinue: "Doorgaan met verzenden"
}
