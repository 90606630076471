<template>
  <v-row class="py-0">
    <v-col cols="10" xl="12" lg="12" sm="12" md="12" class="px-0 py-0 mx-auto">
      <v-img
          alt="Logo"
          class="shrink mx-auto"
          style="cursor: pointer"
          contain
          @click="goRoot()"
          src="https://r.mailcomms.io/b2b/logos/light.png"
          width="125"
      />
    </v-col>
  </v-row>
</template>

<script>

  import {mapGetters} from "vuex";
  import { redirect } from "@/util/router_utils"
  import { ROOT } from "@/constants/router/routes"

  export default {
    name: "CertySignLogo",
    computed: {
      ...mapGetters({
        appearance: 'appearance/getAppearance'
      }),
    },
    data() {
      return {
        theme: ""
      }
    },
    created() {
      this.theme = this.appearance.theme
    },
    methods:{
      goRoot(){
        redirect(ROOT)
      }
    }
  }
</script>

<style scoped>

</style>
