<template>
  <v-container class="cts-main-view col-12">
    <v-row
      v-if="processData"
      class="ma-0 pa-0"
      dense
      justify="center"
    >
      <v-col
        class="mx-auto"
        cols="11"
        lg="10"
      >
        <v-row
          class="ma-0 cts-view-header"
          dense
        >
          <v-col class="pa-0">
            <v-row
              id="process-view-breadcrumbs"
              class="cts-breadcrumbs ma-0 mt-1 pa-0"
              dense
            >
              <a
                id="processview-breadcrumbs-back_button"
                :title="$vuetify.lang.t('$vuetify.allProcesses')"
                @click.prevent="backToMenu()"
              >
                <v-icon
                  color="var(--primary)"
                >
                  {{ const_icons.BACK_CIRCLE }}
                </v-icon>
                {{ $vuetify.lang.t('$vuetify.backToProcess') }}
              </a>
            </v-row>
            <v-row dense class="pa-0 ma-0 ml-2">
              <v-col>
                <view-title
                  :icon="processData[const_global.PROCESS_IMAGE]"
                  :image="true"
                  :subtitle="processData[const_global.PROCESS_DESCRIPTION]"
                  :title="processData[const_global.PROCESS_NAME]"
                  idName="processes"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <ProcessModelContainer
          :process-data="processData"
          :batchMode="batchMode"
        />

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ViewTitle from '@/components/structures/viewTitle'
import { GETTERS as PROCESSES_GETTERS, NAMESPACE as PROCESSES_NAMESPACE, ACTIONS as PROCESSES_ACTIONS } from '@/constants/vuex/processes'
import const_global from '@/constants/global'
import const_icons from '@/constants/icons'
import { redirect } from '@/util/router_utils'
import { PROCESSES } from '@/constants/router/routes'
import { nullOrEmpty } from "@/util/utils"
import ProcessModelContainer from "@/components/sections/processLaunch/processModelContainer"


export default {
  name: 'processLaunch',
  components: { ViewTitle, ProcessModelContainer },
  data() {
    return {
      const_global,
      const_icons,
      processData: null,
      recipients: [],
      batchMode: null
    }
  },

  created() {
    this.processData = this.$store.getters[`${PROCESSES_NAMESPACE}/${PROCESSES_GETTERS.G_PROCESS_TRANSACTION}`]
    this.setCurrentTransactionProcessCode()
    this.setPendingTransaction()
    this.batchMode = this.processData.batchMode

    if (nullOrEmpty(this.currentRecipients)) {
      this.currentRecipients = [{
        [const_global.CONTACT_ID]: null,
        [const_global.CONTACT_NAME]: null,
        [const_global.CONTACT_SURNAME]: null,
        [const_global.CONTACT_EMAIL]: null,
        [const_global.CONTACT_COUNTRY_CODE]: null,
        [const_global.CONTACT_PHONE]: null,
      }]
    }

  },
  methods: {
    backToMenu() {
      redirect(PROCESSES)
    },

    setCurrentTransactionProcessCode() {
      this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_CURRENT_TRANSACTION_PROCESS_CODE}`, this.processData.process_code)
    },

    setPendingTransaction() {
      this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_PENDING_TRANSACTION}`, true)
    }
  }
}
</script>

<style lang="scss" scoped>
#processview-breadcrumbs-back_button i {
  font-size: 17px;
  margin-bottom: 2px;
}
</style>
