var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0 cts-row-processes-filters py-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","xl":"3","lg":"3","md":"3","sm":"3"}},[(_vm.permissionNewUser())?_c('v-btn',{staticClass:"cts-button-standard",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.showUserDetails(null)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.newUser'))+" "),_c('v-icon',{staticClass:"float-right",attrs:{"aria-hidden":"false","size":"17px !important"}},[_vm._v(_vm._s(_vm.const_icons.NEW_USER))])],1):_vm._e()],1),_c('v-col',{staticClass:"py-0 px-4 pr-0 pr-sm-4 offset-sm-5",attrs:{"cols":"6","xl":"4","lg":"4","md":"4","sm":"4"}},[_c('v-row',{staticClass:"ma-0 justify-end",attrs:{"dense":""}},[_c('div',{staticClass:"px-0 col-lg-12"},[_c('v-text-field',{staticClass:"ma-0 cts-text-box",attrs:{"id":"administration-users-searchBox","multiple":"","clearable":"","single-line":"","outlined":"","dense":"","placeholder":_vm.$vuetify.lang.t('$vuetify.usersSearch'),"append-icon":_vm.const_icons.SEARCH,"aria-label":_vm.$vuetify.lang.t('$vuetify.search')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('div',{staticClass:"mr-1"},[_c('v-chip',{staticClass:"py-1",attrs:{"x-small":"","label":""}},[_c('span',[_vm._v(_vm._s(item))])])],1):_vm._e(),(index >= 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.selectedTags.length - 2)+" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.others'))+") ")]):_vm._e()]}}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1)],1),_c('v-data-table',{ref:"users_table",staticClass:"elevation-0 col-12 px-0 px-md-2",attrs:{"dense":"","headers":_vm.usersHeaders,"items":_vm.users,"search":_vm.search,"options":_vm.options,"sort-by":"name","calculate-widths":"","footer-props":{
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'keyboard_arrow_left',
      nextIcon: 'keyboard_arrow_right',
      itemsPerPageText: _vm.$vuetify.lang.t('$vuetify.usersPerPage'),
      pageText: ((_vm.$vuetify.lang.t('$vuetify.groupsFound')) + " " + _vm.filtered_users + " " + (_vm.$vuetify.lang.t('$vuetify.usersTitle')))
    }},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.filterUsers},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',{staticClass:"hidden-xs-only"},[_c('td',{staticClass:"text-start"},[_c('h4',{staticClass:"cts-color-tertiary"},[_vm._v(_vm._s(item.name))])]),_c('td',{staticClass:"text-start"},[(item.username.length > 25)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({staticClass:"cts-color-tertiary cts-break-word"},'h4',attrs,false),on),[_vm._v(_vm._s(item.username))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.username))])]):_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"cts-color-tertiary"},[_vm._v(_vm._s(item.username))])])],1)],1),_c('td',{staticClass:"text-start"},[(item.active == '1')?_c('h4',{staticClass:"cts-color-tertiary text-center"},[_c('v-icon',[_vm._v(_vm._s(_vm.const_icons.CHECK))])],1):_vm._e()]),(item.group_name)?_c('td',{staticClass:"text-start cts-group-name-column"},[(item.group_name.length > 25)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('h4',_vm._g(_vm._b({staticClass:"cts-color-tertiary cts-break-word"},'h4',attrs,false),on),[_vm._v(_vm._s(item.group_name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.group_name))])]):_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"cts-color-tertiary"},[_vm._v(_vm._s(item.group_name))])])],1)],1):_vm._e(),_c('td',{staticClass:"text-start"},[(_vm.permissionEditUser())?_c('v-btn',{staticClass:"elevation-0 cts-bgcolor-primary cts-btn-datatable ml-3",attrs:{"fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.showUserDetails(item)}}},[_c('v-icon',{attrs:{"aria-hidden":"false","size":"20"}},[_vm._v(_vm._s(_vm.const_icons.EDIT))])],1):_vm._e()],1)]),(_vm.permissionEditUser())?_c('v-card',{staticClass:"mx-auto my-2 cts-card-transaction hidden-sm-and-up",attrs:{"outlined":""},on:{"click":function($event){return _vm.showUserDetails(item)}}},[_c('v-list-item',{staticClass:"cts-list-item-card pr-5 pl-1"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(item.active == 0)?_c('v-icon',{staticClass:"font-weight-bold red--text",staticStyle:{"position":"absolute","right":"5px","top":"5px"},attrs:{"aria-hidden":"false","small":""}},[_vm._v(" person_outline ")]):_vm._e(),_c('v-row',{staticClass:"ma-0 justify-space-between",attrs:{"dense":""}},[_c('v-col',{staticClass:"hidden-sm-and-up pa-0 ma-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"cts-font-size-5 cts-white-space pa-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))])]),_c('h4',{staticClass:"cts-font-size-5 cts-white-space pa-1"},[_c('span',{},[_vm._v(_vm._s(item.username))])])])],1)],1)],1),_c('v-list-item-subtitle')],1)],1)],1):_vm._e()]}}])}),(_vm.userDialog)?_c('UserDetailsDialog',{attrs:{"user":_vm.userSelected,"group-mode":_vm.groupMode,"group":_vm.group}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }