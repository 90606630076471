<template>
    <v-container class="pa-0">
        <v-row class="ma-0" dense>
            <v-col cols="12" lg="8" md="12" sm="12">
                <v-row class="ma-0" dense>
                    <section-title
                      :text="$vuetify.lang.t('$vuetify.preview')"
                      icon="visibility"
                      idName="data_preview"
                    />
                </v-row>
                <v-row class="cts-step-box ma-0" dense>
                    <v-col class="pb-2" cols="12" lg="11">
            <span
              id="data_preview-description"
              class="cts-font-size-4"
            >
              {{$vuetify.lang.t('$vuetify.sectionPreviewSubtitle')}}
            </span>
                    </v-col>

                    <v-col v-if="processRecipients && processRecipients.length !== 0" cols="12" lg="11" sm="8">
                        <v-data-table
                          id="data_preview-_detail_table"
                          :headers="tableHeaders"
                          :items="formattedRecipients"
                          :items-per-page="1"
                          :no-data-text="$vuetify.lang.t('$vuetify.noRecipientsSelected')"
                          :no-results-text="$vuetify.lang.t('$vuetify.noRecipientsSelected')"
                          class="cts-data-table-preview"
                          dense
                          hide-default-footer
                          hide-default-header
                          item-key="id"
                          @pagination="handleCurrentPageNumber"
                        >
                            <template v-slot:top="{ pagination, options, updateOptions }">
                                <v-row class="ma-0 justify-space-between cts-recipient-preview-title" dense>
                                    <div class="my-auto">
                                        <v-icon class="mr-4" left>
                                            person
                                        </v-icon>
                                        <span id="data_preview-detail_table_recipient_id"
                                              class="font-weight-medium cts-font-size-4 cts-fields-preview">
                      {{`${processRecipients[pagination.page - 1].contact_id} - `}}
                    </span>
                                        <span id="data_preview-detail_table_recipient_name"
                                              class="cts-fields-preview font-weight-regular cts-font-size-4 ml-1">
                      {{
                                                `${processRecipients[pagination.page - 1].contact_name} ${processRecipients[pagination.page - 1].contact_surname}`
                                            }}
                    </span>
                                    </div>
                                    <v-data-footer
                                      :options="options"
                                      :pagination="pagination"
                                      class="cts-data-table-preview-footer my-auto"
                                      @update:options="updateOptions"
                                    >
                                        <template v-slot:page-text="{}">
                      <span id="data_preview-detail_table-current_selection_text" class="cts-font-size-5"
                            v-html="$vuetify.lang.t('$vuetify.dataTableShowRecipients').replace(':page:', pagination.page).replace('{total}', pagination.itemsLength)">
                      </span>
                                        </template>
                                    </v-data-footer>
                                </v-row>
                            </template>

                            <template v-slot:item="item">
                                <v-row class="ma-0 pa-0" dense>
                                    <v-col class="cts-recipient-preview elevation-0" cols="12">
                                        <v-card-text class="pa-1">
                                            <v-row class="ma-0 pt-2" dense>
                                                <v-col cols="12" sm="7">
                                                    <v-row class="ma-0 pb-2" dense>
                                                        <h5
                                                          id="data_preview-detail_table-body-transaction_data_text"
                                                          class="px-0 mt-0 font-weight-medium text-uppercase cts-color-tertiary"
                                                        >
                                                            {{$vuetify.lang.t('$vuetify.transactionData')}}
                                                        </h5>
                                                    </v-row>

                                                    <v-row
                                                      v-for="header in tableHeaders"
                                                      :key="header.id"
                                                      class="ma-0 pl-1"
                                                      dense
                                                    >
                                                        <v-col v-if="(typeof item.item[header.id]) != 'object'"
                                                               class="pa-0 py-1"
                                                               cols="12">
                                                            <v-row class="ma-0 justify-start" dense>
                                <span
                                  :id="`data_preview-detail_table-body-transaction_data-${header.text.toLowerCase().replaceAll(' ', '_')}_text`"
                                  class="font-weight-bold cts-font-size-4"
                                >
                                  {{header.text}}:
                                </span>
                                                                <span
                                                                  :id="`data_preview-detail_table-body-transaction_data-${header.text.toLowerCase().replaceAll(' ', '_')}_value`"
                                                                  class="font-weight-regular cts-fields-preview cts-font-size-4 ml-2"
                                                                  v-html="item.item[header.id]"
                                                                />

                                                            </v-row>
                                                        </v-col>

                                                    </v-row>
                                                </v-col>

                                                <v-col v-if="hasFiles" class="pa-0" cols="12" sm="5">
                                                    <v-row class="ma-0 pb-2" dense>
                                                        <h5 id="data_preview-detail_table-body-transaction_files_text"
                                                            class="px-0 mt-0 font-weight-medium text-uppercase cts-color-tertiary">
                                                            {{$vuetify.lang.t('$vuetify.files')}}
                                                        </h5>
                                                    </v-row>
                                                    <v-row v-for="(header, index) in tableHeaders" :key="header.id"
                                                           class="ma-0 px-1" dense>
                                                        <v-col
                                                          v-if="typeof(item.item[header.id]) == 'object' && item.item[header.id].length !== 0"
                                                          class="px-0 py-1"
                                                          cols="12"
                                                          md="12"
                                                        >
                                                            <h5
                                                              :id="`data_preview-detail_table-body-${index}-header_text`"
                                                              class="font-weight-bold cts-font-size-4"
                                                            >
                                                                {{header.text}}:
                                                            </h5>

                                                            <DraggableList
                                                              :array="fileArrayList(item, header)"
                                                              :index="index"
                                                              @emailAttachmentOrder="handleAttachmentOrder"
                                                              @openHTMLpreview="handleOpenHTMLpreview"
                                                              @openPDFPrev="handleOpenPDFpreview"
                                                            />
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

                <v-dialog v-if="previsualization" id="data_preview-dialog" v-model="previsualization" max-width="1200">
                    <v-card class="pa-2" tile>
                        <v-card-title class="col-lg-12 pa-0">
                            <dialog-title
                              :close="true"
                              :icon="const_icons.WATCH_FILE"
                              :text="$vuetify.lang.t('$vuetify.previewing') + selectFileContent.fileName"
                              event="closePDFpreview"
                              @close="previsualization=false"
                            />
                        </v-card-title>
                        <v-card-text id="data_preview-dialog-document_content" class="pb-0 mt-4">
                            <documentsViewer
                              :documentType='isPdf(selectFileContent.fileName)? "pdf" : "html"'
                              :fileContent="isPdf(selectFileContent.fileName) ? `data:application/pdf;base64,${selectFileContent.fileContent}` : `data:application/text;base64,${selectFileContent.fileContent}`"
                              :fileName='selectFileContent.fileName'
                            />
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>

            <v-col cols="12" lg="4">
                <SectionDataPreviewResume
                  :nOfRecipients="formattedRecipients.length"
                  :showLanguageSelector="true"
                />

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import const_global from "@/constants/global"
import const_icons from "@/constants/icons"
import const_permissions from "@/constants/permissions"
import SectionTitle from "./sectionTitle"
import {
    NAMESPACE as PROCESSES_NAMESPACE,
    STATE as PROCESSES_STATE,
    ACTIONS as PROCESSES_ACTIONS
} from "@/constants/vuex/processes"
import DialogTitle from "@/components/structures/dialogTitle";
import {ServerBus} from "@/main";
import {NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE} from "@/constants/vuex/authentication"
import {refreshSession} from "@/services/userServices"
import {checkPermission} from "@/util/store_utils"
import DocumentsViewer from "@/components/documentsViewer"
import DraggableList from "@/components/structures/DraggableList"
import {documentIsGivenExtension, convertFileObjectToBackendObject} from "@/util/utils"
import {PDFDocument} from 'pdf-lib'
import {launchTransaction} from "@/services/processServices"
import SectionDataPreviewResume from "@/components/sections/processes/processView/sectionDataPreviewResume.vue"

export default {
    name: "sectionDataPreview",
    components: {
        DocumentsViewer,
        SectionTitle,
        DialogTitle,
        DraggableList,
        SectionDataPreviewResume
    },
    props: {
        processRecipients: Array,
        processFields: Object,
        processPayload: Object,
        processData: Object
    },
    data() {
        return {
            const_icons,
            const_global,
            checkPermission,
            const_permissions,
            hasFiles: false,
            fieldsTableHeaders: [],
            items: [],
            extension: '',
            previsualization: false,
            selectFileContent: null,
            selectFileName: null,
            process_fields: {},
            changeCostCenterDialog: false,
            changeLanguageDialog: false,
            currentPage: 1,
            active_language: null,
            process_languages: null,
            locations: require('@/constants/locations').default,
            launched: false
        }
    },
    created() {
        let process_recipients = Object.assign({}, this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_RECIPIENTS])
        this.process_fields = Object.assign({}, this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_FIELDS])
        this.process_languages = []

        if (this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_METADATA]) {
            Object.assign({}, this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_METADATA]).languages.forEach(pl => {
                pl.name = this.locations.find(loc => loc.iso === pl.code).name
                this.process_languages.push(pl)
            })
            this.active_language = this.process_languages.find(pl => pl.is_default === true)
        }

        Object.entries(this.process_fields).forEach(([key, value]) => {

            let cols = ""
            if (key === const_global.FIELD_MOBILE_PHONE) {
                cols = "3"
            }
            if (key !== const_global.FIELD_COUNTRY_CODE) {
                this.fieldsTableHeaders.push({
                    id: key,
                    text: value['pretty_print'],
                    cols: cols
                })
            }
        })
        this.items = process_recipients

        ServerBus.$on("closePDFpreview", () => {
            this.closeVisualizationAndContinue()
        })

        ServerBus.$on("launchTransaction", () => {
            if (!this.launched) {
                this.launched = true
                this.sendProcess()
            }
        })

        this.configureBillingUnit()
    },

    computed: {
        tableHeaders() {
            return Object.entries(this.processFields).map(([k, v]) => ({
                id: k,
                text: v['pretty_print'],
                hidden: v['hidden'],
                cols: 3
            })).filter(x => !x.hidden)
        },

        currentRecipient() {
            return this.processRecipients[this.currentPage - 1]
        },

        availableBillingUnits() {
            return this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_AVAILABLE_COST_CENTERS]
        },

        selectedBillingUnit: {
            get() {
                return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_COST_CENTER]
            },
            set(val) {
                this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_COST_CENTER}`, val)
                refreshSession()
            }
        },

        formattedRecipients() {
            if (this.processRecipients) {
                return this.processRecipients.map(x => this.formatRecipient(x))
            }
            return null
        }


    },
    methods: {
        fileArrayList(item, header) {
            if (Object.prototype.hasOwnProperty.call(item.item[header.id], "value")) {
                return item.item[header.id]["value"]
            }
            return Array.isArray(item.item[header.id]) ? item.item[header.id] : [item.item[header.id]]
        },

        formatRecipient(recipient) {
            const resultObj = {}
            const recipientDataKeyMapping = {
                email_address: const_global.CONTACT_EMAIL,
                name: const_global.CONTACT_NAME,
                surname: const_global.CONTACT_SURNAME,
                mobile_phone: const_global.CONTACT_PHONE
            }
            resultObj.recipient = recipient
            Object.entries(this.processFields).forEach(([fieldName, fieldData]) => {

                if (!["file", "array_file"].includes(fieldData[const_global.FIELD_TYPE])) {
                    // const valueToFormat = recipientDataKeyMapping[fieldName] ?  this.currentRecipient[recipientDataKeyMapping[fieldName]] : this.processPayload[fieldName][const_global.FIELD_VALUE]
                    const valueToFormat = recipientDataKeyMapping[fieldName] ? this.currentRecipient[recipientDataKeyMapping[fieldName]] : this.processPayload[fieldName]
                    resultObj[fieldName] = this.replaceVariables(valueToFormat, recipient)


                } else {
                    this.hasFiles = true

                    resultObj[fieldName] = this.processPayload[fieldName]//[const_global.FIELD_VALUE]
                }
            })

            return resultObj
        },
        changeLanguage() {
            let newLanguages = this.process_languages
            newLanguages.forEach(nl => {
                nl.is_default = nl.code === this.active_language.code
            })

            this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_PROCESS_METADATA_VALUE}`, {languages: newLanguages})
            this.changeLanguageDialog = false

        },
        handleCurrentPageNumber(event) {
            this.currentPage = event.page
        },
        handleAttachmentOrder(array) {
            this.$emit("emailAttachmentOrder", array)
        },

        openPDFpreview(doc) {
            this.selectFileContent = doc
            this.previsualization = true
        },

        handleOpenPDFpreview(doc) {
            this.openPDFpreview(doc)
        },

        openHTMLpreview(doc) {
            this.previsualization = true
            let documentCopy = Object.assign({}, doc)
            documentCopy.fileContent = this.htmlReplaceFields(doc, this.currentRecipient)

            this.selectFileContent = documentCopy
        },

        handleOpenHTMLpreview(doc) {
            this.selectFileContent = doc
            this.openHTMLpreview(doc)
        },
        // setHasFiles(hasFiles) {
        //   this.hasFiles = hasFiles
        // },
        formatTag(tag) {
            return `<strong class='cts-color-primary'>${tag}</strong>`
        },
        closeVisualizationAndContinue() {
            this.previsualization = false
        },

        htmlReplaceFields(html, recipient) {
            let decodedHTML = atob(html.fileContent)
            let editedHTML = decodedHTML

            const fieldsToReplace = [...new Set(decodedHTML.match(/%%\w+%%/ig))]

            if (fieldsToReplace.length > 0) {
                fieldsToReplace.forEach(x => {
                    let replacement = recipient[`contact_${x.replace(/%%/g, "")}`]

                    if ([const_global.FIELD_NAME, const_global.FIELD_SURNAME].includes(x.replace(/%%/g, ""))) {
                        replacement = recipient[`contact_${x.replace(/%%/g, "")}`].charAt(0).toUpperCase() + recipient[`contact_${x.replace(/%%/g, "")}`].slice(1)
                    }
                    editedHTML = editedHTML.replace(new RegExp(x, "g"), replacement)
                })

                return btoa(editedHTML)
            }
            return html.fileContent
        },

        isPdf(value) {
            return documentIsGivenExtension(value, "pdf")
        },

        configureBillingUnit() {
            let has_default = false
            this.availableBillingUnits.forEach(cc => {
                if (cc["is_default"]) {
                    has_default = true
                    this.selectedBillingUnit = cc.code
                }
            })
            if (!has_default && this.availableBillingUnits.length > 0) {
                has_default = true
                this.selectedBillingUnit = this.availableBillingUnits[0].code
            }

            if (!has_default) {
                this.selectedBillingUnit = null
            }
        },

        async sendProcess() {
            this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_TRANSACTION_RESULT}`, null)
            const billingUnit = this.selectedBillingUnit != null ? this.selectedBillingUnit.code : ""
            const fields = {
                "master_account_code": this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_MASTER_ACCOUNT_CODE],
                "process_code": this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_CODE],
                "cost_center": billingUnit
            }

            const recipients = JSON.parse(JSON.stringify(this.processRecipients))
            recipients.forEach(r => {
                delete r["__valid"]
            })

            for await (const [key, field] of Object.entries(this.process_fields)) {
                if (field["type"] === "file" && this.processData[key].length > 1 && field["field_configuration"] && field["field_configuration"]["merge_selected_files"]) {
                    const mergedPdfContent = await this.mergePdfs(this.processData[key])
                    fields[key] = {
                        file_name: `${field["pretty_print"].toLowerCase().replaceAll(" ", "_").replaceAll("-", "_")}.pdf`,
                        file_content: mergedPdfContent
                    }
                } else if (field["type"] === "file") {
                    const convertedFile = await convertFileObjectToBackendObject(this.processData[key])

                    fields[key] = {}
                    fields[key].file_name = convertedFile.fileName
                    fields[key].file_content = convertedFile.fileContent

                } else if (field["type"] === "array_file") {
                    let res = await convertFileObjectToBackendObject(this.processData[key])
                    res = Array.isArray(res) ? res : [res]

                    fields[key] = res.map(x => ({file_content: x.fileContent, file_name: x.fileName}))
                } else {
                    fields[key] = this.processData[key] ? this.processData[key].replace(/\s/g, ' ') : ""
                }
            }

            fields['language'] = this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_METADATA].languages.find(l => l.is_default === true).code

            const postData = {
                "recipients": recipients,
                "parameters": fields
            }

            this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_TRANSACTION_STATUS}`, const_global.TRANSACTION_PROCESSING)

            launchTransaction(postData)

        },

        async mergePdfs(documents) {
            let fileArray
            if (!this.orderChanged) {
                fileArray = documents.map(async f => await f.arrayBuffer())
                fileArray = await Promise.all(fileArray)

            } else {
                fileArray = documents.map(f => f.fileContent)
            }

            const mergedPdf = await PDFDocument.create()

            for (const pdfBytes of fileArray) {
                const pdf = await PDFDocument.load(pdfBytes)
                const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())

                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page);
                })
            }

            return await mergedPdf.saveAsBase64()
        },

        replaceVariables(str, recipient) {
            const variableNames = {
                "name": const_global.CONTACT_NAME,
                [const_global.CONTACT_NAME]: const_global.CONTACT_NAME,
                "surname": const_global.CONTACT_SURNAME,
                [const_global.CONTACT_SURNAME]: const_global.CONTACT_SURNAME,
                "id": const_global.CONTACT_ID,
                [const_global.CONTACT_ID]: const_global.CONTACT_ID,
                "mobile_phone": const_global.CONTACT_PHONE,
                [const_global.CONTACT_PHONE]: const_global.CONTACT_PHONE,
                "country_code": const_global.CONTACT_COUNTRY_CODE,
                [const_global.CONTACT_COUNTRY_CODE]: const_global.CONTACT_COUNTRY_CODE,
            }

            let result = str
            Object.keys(variableNames).forEach(key => {
                if (result && Object.prototype.hasOwnProperty.call(result, "value")) {
                    result = result ? result.value.replace(`${const_global.FIELD_SEPARATOR}${key}${const_global.FIELD_SEPARATOR}`, this.formatTag(recipient[variableNames[key]])) : ""
                } else {
                    result = result ? result.replace(`${const_global.FIELD_SEPARATOR}${key}${const_global.FIELD_SEPARATOR}`, this.formatTag(recipient[variableNames[key]])) : ""

                }
            })


            return result
        }


    },
    beforeDestroy() {
        ServerBus.$off("closePDFpreview")
        ServerBus.$off("launchTransaction")
    }
}
</script>
