import Axios from "@/plugins/axios"
import store from "@/store"
import { TRANSACTION_ROUTES }  from "@/constants/backOfficeRoutes"
import { serialize } from '@/plugins/objectToFormdata'
import vuetify from "@/plugins/vuetify"
import { NAMESPACE as TRANSACTIONS_NAMESPACE, STATE as TRANSACTIONS_STATE } from "@/constants/vuex/transactions"
import { generatePdfDownload, showToast } from "@/util/utils"

export const getTransactions = filters => {
  let filters_data = {};
  filters_data = serialize({"filters": filters})

  return Axios.post(TRANSACTION_ROUTES.ROUTE_TRANSACTIONS, filters_data)

}

export const getTransactionsSearchFilters = () => {
  return Axios.get(TRANSACTION_ROUTES.ROUTE_TRANSACTIONS_FILTERS)
}

export const getTransactionCertification = (certificate_id) => {
  Axios.get(TRANSACTION_ROUTES.ROUTE_TRANSACTION_CERTIFICATE.replace("$certificate_id$", certificate_id)).then((response) => {
    generatePdfDownload(response.data.content, `CERT_${response.data.name}`)

    if (response.status === 200) {
      showToast(false, vuetify.framework.lang.t('$vuetify.certificateValidDownload'))
    } else {
      showToast(false, vuetify.framework.lang.t('$vuetify.certificateFailDownload'))
    }
  })
}

export const getTransactionPEE = postamail_id => {
  let transaction_id = store.state[TRANSACTIONS_NAMESPACE][TRANSACTIONS_STATE.S_TRANSACTION_SELECTED]
  Axios.get(TRANSACTION_ROUTES.ROUTE_TRANSACTIONS_PEE.replace("$transaction_id$", transaction_id).replace("$postamail_id$", postamail_id)).then((response) => {
    generatePdfDownload(response.data.content, `PEE_${response.data.name}`)

    if (response.status === 200) {
      showToast(false, vuetify.framework.lang.t('$vuetify.certificateValidDownload'))
    } else {
      showToast(false, vuetify.framework.lang.t('$vuetify.certificateFailDownload'))
    }
  })
}

export const getTransactionInfo = () => {
  let transaction_id = store.state[TRANSACTIONS_NAMESPACE][TRANSACTIONS_STATE.S_TRANSACTION_SELECTED]
  return Axios.get(TRANSACTION_ROUTES.ROUTE_TRANSACTION.replace("$transaction_id$", transaction_id))
}
