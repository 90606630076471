<template>
  <v-expand-transition>
    <v-dialog v-model="open" max-width="600px">
      <v-card tile>
        <v-card-title class="col-lg-12 pa-2">
          <dialog-title
            v-if="newUser"
            class="col-lg-12"
            :icon="const_icons.NEW_USER"
            :text="$vuetify.lang.t('$vuetify.newUser')"
            closeEvent="closeModalUserDetailUpdate"
          />
          <dialog-title
            v-else class="col-lg-12"
            :icon="const_icons.NEW_USER"
            :text="$vuetify.lang.t('$vuetify.editUser')"
            closeEvent="closeModalUserDetailUpdate"
          />
        </v-card-title>
        <v-card-text class="pb-0">
          <form>
            <v-row dense class="ma-0">
              <v-col cols="12" class="cts-field-medium-block">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.transactionContactName')}}</span>
                    <v-text-field
                      required
                      outlined
                      autocomplete="off"
                      v-model="user_info.name"
                      :maxlength="max"
                      :aria-label="$vuetify.lang.t('$vuetify.transactionContactName')"
                      :placeholder="$vuetify.lang.t('$vuetify.transactionContactName')"
                      @input="handleChangeEvent"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense class="ma-0">

              <v-col cols="12" class="cts-field-medium-block">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.transactionContactEmail')}}</span>
                    <v-text-field
                      required
                      outlined
                      autocomplete="off"
                      v-model="user_info.username"
                      :disabled="!newUser"
                      :aria-label="$vuetify.lang.t('$vuetify.transactionContactEmail')"
                      :placeholder="$vuetify.lang.t('$vuetify.transactionContactEmail')"
                      @input="handleChangeEvent"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense class="ma-0">
              <v-col cols="6" class="cts-field-medium-block">
                <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.groupsAssignation')}}</span>

                <v-autocomplete
                  v-model="group_selected"
                  :items="groups"
                  item-text="name"
                  item-value="id"
                  required
                  dense
                  return-object
                  outlined
                  @change="changeGroup($event)"
                  single-line
                  :aria-label="$vuetify.lang.t('$vuetify.noGroupAssigned')"
                  :placeholder="$vuetify.lang.t('$vuetify.noGroupAssigned')"
                  :disabled="groupMode"
                />
              </v-col>

              <v-col cols="6" class="cts-field-medium-block">
                <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.languageSelector')}}</span>

                <v-autocomplete
                  v-model="user_info.language"
                  :items="filteredLocations"
                  item-text="name"
                  item-value="iso"
                  required
                  dense
                  outlined
                  single-line
                  @change="handleChangeEvent"
                />
              </v-col>
            </v-row>
            <v-row dense class="ma-0">
              <v-col cols="6" md="6" class="cts-field-medium-block" v-if="!newUser">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <span class="cts-form-labeled-input">{{$vuetify.lang.t('$vuetify.userStateTitle')}}</span>
                    <h3 v-if="user_info.active == 0" class="mt-1 cts-color-error">
                      <v-icon aria-hidden="false" class="mr-2 cts-color-error cts-align-text-bottom" size="20">
                        {{const_icons.ERROR}}
                      </v-icon>
                      {{$vuetify.lang.t('$vuetify.userInactive')}}
                    </h3>
                    <v-row v-else dense class="ma-0 pa-0">
                      <v-checkbox
                        class="cts-tabs-checkboxes cts-modal-checkbox pa-0 ma-0"
                        v-model="user_info.active"
                        color="var(--primary)"
                        aria-label="checkbox-active-user"
                        @click="handleChangeEvent"
                      />
                      <h3 class="mt-0 cts-color-primary px-0 pt-1 cts-padding-small">
                        {{$vuetify.lang.t('$vuetify.userActive')}}</h3>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-row dense class="ma-0 mx-3 pt-4">
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-btn class="cts-button-tertiary my-2 float-left" small @click="closeDialog" dark>
                {{$vuetify.lang.t('$vuetify.cancel')}}
                <v-icon aria-hidden="false" small class="ml-2 float-left">{{const_icons.CANCEL}}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <v-btn
                :disabled="!hasChanged && validForm"
                class="cts-button-standard my-2 float-right"
                small
                @click="save"
              >
                {{$vuetify.lang.t('$vuetify.save')}}
                <v-icon aria-hidden="false" small class="ml-2">{{const_icons.SAVE}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expand-transition>
</template>

<script>
  import DialogTitle from "../../../structures/dialogTitle"
  import const_icons from "@/constants/icons"
  import { NAMESPACE as ADMINISTRATION_NAMESPACE, STATE as ADMINISTRATION_STATE, ACTIONS as ADMINISTRATION_ACTIONS } from "@/constants/vuex/administration"
  import { NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE } from "@/constants/vuex/authentication"
  import { createUser, updateUser, getUsers } from "@/services/userServices";
  import { getGroups, getGroupDetails } from "@/services/groupServices"
  import { changeSiteLang } from "@/util/store_utils"
  import locations from "@/constants/locations"
  import { mapGetters } from "vuex"
  import { NAMESPACE as GLOBAL_NAMESPACE, GETTERS as GLOBAL_GETTERS } from "@/constants/vuex/global"
  import { showToast } from "@/util/utils"

  export default {
    name: "userDetailsDialog",
    components: {DialogTitle},
    props: {
      group: Object,
      user: Object,
      groupMode: Boolean
    },
    data() {
      return {
        const_icons,
        newUser: true,
        group_selected: {},
        current_group: null,
        user_info: {},
        max: 40,
        locations,
        hasChanged: false
      }
    },
    created() {
      let group_id = ""
      let group_name = ""

      if (this.user) {
        this.newUser = false
      }
      if (this.groupMode) {
        group_name = this.group.group_name
        group_id = this.group.group_id

      } else {
        if (this.user) {
          group_id = this.user.group
          group_name = this.user.group_name

        }
      }

      getGroups().then((response) => {
        this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_GROUPS}`, response.data)
      })
      this.group_selected = {}
      this.group_selected['id'] = group_id
      this.group_selected['name'] = group_name

      this.current_group = group_id

      Object.assign(this.user_info, this.user)

    },
    computed: {
      ...mapGetters({
        subdomainConfig: `${GLOBAL_NAMESPACE}/${GLOBAL_GETTERS.GET_SUBDOMAIN_CONFIG}`
      }),

      filteredLocations() {
        if (Array.isArray(this.subdomainConfig.available_languages)) {
          return this.locations.filter(x => this.subdomainConfig.available_languages.includes(x.code))
        }

        return []
      },
      groups() {
        return this.$store.state[ADMINISTRATION_NAMESPACE][ADMINISTRATION_STATE.S_GROUPS]
      },
      open: {
        get() {
          return this.$store.state[ADMINISTRATION_NAMESPACE][ADMINISTRATION_STATE.S_USER_DETAILS_DIALOG]
        },
        set(value) {
          this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_USER_DETAILS_DIALOG}`, value)
        }
      },
      validForm() {
        return ((this.group_selected != null && this.group_selected.id != "") && !(!this.user_info.username && !this.user_info.name))
      }
    },
    methods: {
      handleChangeEvent() {
        this.hasChanged = true
      },

      async fetchUsers() {
        const response = await getUsers()
        this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_USERS}`, response.data)
      },

      async fetchGroupDetails() {
        const response = await getGroupDetails()
        this.$store.dispatch(`${ADMINISTRATION_NAMESPACE}/${ADMINISTRATION_ACTIONS.A_SET_GROUP_DETAILS}`, response.data)
      },

      changeGroup(event) {
        this.handleChangeEvent()
        this.user_info.group = event
      },

      closeDialog() {
        this.open = false
      },

      async save() {
        this.user_info["group_id"] = this.group_selected.id

        if (this.newUser) {
          const response = await createUser(this.user_info)
          if (this.groupMode) {
            this.fetchGroupDetails()
          } else {
            this.fetchUsers()
          }

          showToast(response.data.error != 0, this.$vuetify.lang.t(`$vuetify.${response.data.message}`))

        } else {
          const updateResult = await updateUser(this.user_info)

          if (this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_USER_ID] == this.user.id) {
            this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_ID] = this.group_selected.id
            this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_GROUP_NAME] = this.group_selected.name

            if (this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_LANGUAGE] != this.user_info.language) {
              this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_LANGUAGE] = this.user_info.language
              changeSiteLang(this.user_info.language)
              location.reload()

            }
          }
          
          const error = updateResult.data.error != 0
          if (this.groupMode) {
            await this.fetchGroupDetails()
          } else {
            await this.fetchUsers()
          }

          let text = this.$vuetify.lang.t(`$vuetify.${updateResult.data.message}`)
          if (this.user_info.group_name != null) {
            text = text.replace(':group_name:', this.user_info.group_name)
          }
          showToast(error, text)

        }

        this.closeDialog()
      }
    }
  }
</script>
